import {useQuery} from '@apollo/react-hooks'
import {Box, Button, Link as MuiLink, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import {FC, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useTranslate} from 'react-admin'
import {Link, Redirect, useLocation, useParams} from 'react-router-dom'

import {Chat, ChatContext, ChatList, ChatPage} from '../../components/chat'
import ChatFilterTabs from '../../components/chat/ChatFilterTabs'
import ChatHeader from '../../components/chat/ChatHeader'
import {SettingsIcon} from '../../components/icons'
import {SandboxContext} from '../../contexts/SandboxContext'
import useChatsQueryAndSubscription from '../../hooks/chat/useChatsQueryAndSubscription'
import useHasUnreadChatMessages from '../../hooks/chat/useHasUnreadChatMessages'
import useIsShowingTranslations from '../../hooks/chat/useIsShowingTranslations'
import useIsDesktop from '../../hooks/useIsDesktop'
import useMostRecentInboundChatMessageElapsedIntervals
  from '../../hooks/useMostRecentInboundChatMessageElapsedIntervals'
import useSessionMerchantUser from '../../hooks/useSessionMerchantUser'
import {useUserDisplayName} from '../../hooks/useUserDisplayName'
import theme from '../../theme'
import {
  Chats,
  ChatTypesEnum,
  CustomerUsers,
  QueryRoot,
  Users,
} from '../../types/graphqlSchema'
import {REPLY_EXPIRATION_INTERVAL} from '../../utils/consts'
import formatPhoneNumber from '../../utils/phoneNumber'
import {isNativeMobileApp} from '../../utils/platform'
import {EventCategory, trackEvent} from '../../utils/tracking'
import ChannelSettingsDialog from './ChannelSettingsDialog'
import ChatAssignment from './ChatAssignment'
import ChatContextMenuButton from './ChatContextMenuButton'
import StartNewChatButton from './StartNewChatButton'

const ExternalChatPage = () => {
  const styles = useStyles(theme)
  const translate = useTranslate()
  const {channelId, chatId} = useParams<{channelId: string, chatId: string}>()
  const isDesktop = useIsDesktop()
  const {merchantUser: {id: sessionMerchantUserId} = {}} = useSessionMerchantUser()
  const {data: {channels_by_pk: channel} = {}} = useQuery<QueryRoot['channels_by_pk']>(
    CHANNEL_QUERY, {skip: !channelId, variables: {id: channelId}}
  )
  const {
    data: {channels: [firstChannel]} = {channels: []},
  } = useQuery<QueryRoot['channels']>(
    FIRST_CHANNEL_QUERY,
    {
      skip: !!channelId || !sessionMerchantUserId,
      variables: {merchantUserId: sessionMerchantUserId},
    }
  )
  const [{[chatId as string]: mostRecentInboundChatMessageElapsedInterval = null}] =
    useMostRecentInboundChatMessageElapsedIntervals({chatId})
  const isReplyWindowExpired = useMemo(
    () => (mostRecentInboundChatMessageElapsedInterval === null) ||
      (mostRecentInboundChatMessageElapsedInterval > REPLY_EXPIRATION_INTERVAL),
    [mostRecentInboundChatMessageElapsedInterval]
  )
  const hashValue = useLocation().hash.replace('#', '')
  const isMultiMerchantUserChannel = channel &&
    ((channel.channelMerchantUsers_aggregate?.aggregate?.count ?? 0) > 1)
  const chatFilters = isMultiMerchantUserChannel ?
    MULTI_USER_CHAT_FILTERS : SINGLE_USER_CHAT_FILTERS
  const chatFilter = chatFilters.find(f => f === hashValue) ?? chatFilters[0]
  const {
    data: {
      // @ts-ignore: Property 'firstMineChat' does not exist on type 'QueryResultWrapper
      firstMineChat: [firstMineChat], firstUnassignedChat: [firstUnassignedChat],
    } = {firstMineChat: [], firstUnassignedChat: []},
  } = useQuery<{data: {firstMineChat: Chats[], firstUnassignedChat: Chats[]}}>(
    FIRST_MINE_AND_FIRST_UNASSIGNED_CHAT_QUERY,
    {
      skip:
        !!chatId || !channelId || !sessionMerchantUserId || !isMultiMerchantUserChannel,
      variables: {channelId, sessionMerchantUserId},
    }
  )
  const chatsBoolExp = useMemo(() => (
    {
      channelId: {_eq: channelId},
      isArchived: {_eq: false},
      isSpam: {_eq: false},
      ...({
        all: {},
        archived: {isArchived: {_eq: true}},
        mine: {assignedMerchantUserId: {_eq: sessionMerchantUserId}},
        unassigned: {assignedMerchantUserId: {_is_null: true}},
      }[chatFilter]),
    }
  ), [channelId, chatFilter, sessionMerchantUserId])
  const {
    chats,
    fetchEarlierChats,
    hasEarlierChats,
    isFetchingMore,
    loading,
    onResetFetchEarlierChatsOffset,
  } = useChatsQueryAndSubscription(
    chatsBoolExp,
    CHAT_FIELDS,
    chatFilter,
    {
      // We want to check for "isMultiMerchantUserChannel === undefined" to prevent
      // fetching "all" chats filter when the proper value of
      // isMultiMerchantUserChannel isn't known yet.
      skip: !channelId || !sessionMerchantUserId ||
        (isMultiMerchantUserChannel === undefined),
    },
  )
  useEffect(
    // When the user switch to another filter tab we reinitialize the chat load offset.
    // We also scroll back to the top of the chat list to fix the issue where the
    // "fetch more" function was immediately called on switch.
    onResetFetchEarlierChatsOffset, [chatFilter, onResetFetchEarlierChatsOffset]
  )
  const {data: {chats_by_pk: selectedChat} = {}} = useQuery<QueryRoot['chats_by_pk']>(
    CHAT_QUERY, {skip: !chatId, variables: {chatId}}
  )
  const [
    isChannelSettingsDialogOpen, setIsChannelSettingsDialogOpen,
  ] = useState(false)
  const [
    isShowingTranslations,
    setIsShowingTranslations,
  ] = useIsShowingTranslations(chatId)
  const {isSandbox, shouldOnboard} = useContext(SandboxContext)
  if (isSandbox && shouldOnboard) {
    return <Redirect to="/sandbox" />
  }
  if (!channelId && firstChannel) {
    return <Redirect to={`/inbox/${firstChannel.id}`} />
  }
  if (isDesktop && channelId && !chatId && (firstMineChat || firstUnassignedChat)) {
    // As no chat has been selected yet, we want to automatically redirect to the first
    // available chat.
    return <Redirect
      to={
        `/inbox/${channelId}/${(firstMineChat || firstUnassignedChat).id}#${
        firstMineChat ? 'mine' : 'unassigned'}`
      }
    />
  }
  if (
    isDesktop && channelId && !chatId && chats.length &&
    (isMultiMerchantUserChannel === false)
  ) {
    return <Redirect to={`/inbox/${channelId}/${chats[0].id}#all`} />
  }
  return (
    <ChatPage
      chatListHeader={
        <ChatListHeader
          channelId={channelId}
          chatFilter={chatFilter}
          chatFilters={chatFilters}
          isMultiMerchantUserChannel={!!isMultiMerchantUserChannel}
        />
      }
      startNewChatButton={
        <StartNewChatButton channelId={channelId} disabled={!channelId} />
      }
      title={
        <Box alignItems="center" display="flex" justifyContent="space-between">
          {translate('chat.external.title')}
          <Button
            className={styles.channelSettingsButton}
            color="primary"
            onClick={() => {
              setIsChannelSettingsDialogOpen(true)
              trackEvent('OPEN_CHANNEL_SETTINGS', EventCategory.CHANNEL)
            }}
            variant="outlined"
          >
            <SettingsIcon />
            {isDesktop && (
              <span>{translate('chat.external.channelSettingsButtonLabel')}</span>
            )}
          </Button>
          <ChannelSettingsDialog
            channelId={channelId}
            onClose={() => setIsChannelSettingsDialogOpen(false)}
            open={isChannelSettingsDialogOpen}
          />
        </Box>
      }
    >
      <ChatList
        chatTitle={ChatOwningUserDisplayName}
        chats={chats}
        fetchMoreChats={fetchEarlierChats}
        hasMessagePreview
        hasMoreChats={hasEarlierChats}
        isExternalMultiMerchantUserChat={!!isMultiMerchantUserChannel}
        isFetchingMore={isFetchingMore}
        loading={loading}
        rightComponent={ChatContextMenuButton}
        selectedChatId={chatId as string}
      />
      {!!chatId && (
        <ChatContext.Provider value={{channelId, chatId}}>
          <Chat
            header={
              <ChatHeader
                redirectTo={
                  !!selectedChat?.owningUser?.customerUser?.merchantCustomerUsers.length ?
                    `/merchant_customer_users/${
                      selectedChat.owningUser.customerUser.merchantCustomerUsers[0].id}` :
                    undefined
                }
                subtitle={<ChatSubtitle owningUser={selectedChat?.owningUser} />}
                title={<ChatTitle owningUser={selectedChat?.owningUser} />}
              >
                {(isMultiMerchantUserChannel && selectedChat) && (
                  <ChatAssignment chat={selectedChat} className={styles.chatAssignment} />
                )}
                {selectedChat && <ChatContextMenuButton chat={selectedChat}/>}
              </ChatHeader>
            }
            isReplyWindowExpired={isReplyWindowExpired}
            isShowingTranslations={isShowingTranslations}
            onChangeIsShowingTranslations={setIsShowingTranslations}
            type={ChatTypesEnum.External}
          />
        </ChatContext.Provider>
      )}
    </ChatPage>
  )
}

const ChatListHeader: FC<ChatListHeaderProps> = ({
  channelId,
  chatFilter,
  chatFilters,
  isMultiMerchantUserChannel,
}) => {
  const styles = useStyles(theme)
  const hasMineUnreadChatMessages = useHasUnreadChatMessages({
    channelId,
    chatType: ChatTypesEnum.External,
    isMineOnly: true,
    skip: !isMultiMerchantUserChannel,
  })
  const hasUnassignedUnreadChatMessages = useHasUnreadChatMessages({
    channelId,
    chatType: ChatTypesEnum.External,
    isUnassignedOnly: true,
    skip: !isMultiMerchantUserChannel,
  })
  const hasUnreadChatMessages = useHasUnreadChatMessages({
    channelId,
    chatType: ChatTypesEnum.External,
    skip: isMultiMerchantUserChannel,
  })
  return (<>
    <ChatFilterTabs
      chatType={ChatTypesEnum.External}
      className={styles.chatFilterTabs}
      filters={chatFilters.map(f => ({
        hasUnreadChatMessages: {
          'all': hasUnreadChatMessages,
          'mine': hasMineUnreadChatMessages,
          'unassigned': hasUnassignedUnreadChatMessages,
        }[f],
        name: f,
      }))}
      value={chatFilter}
    />
  </>)
}

const ChatOwningUserDisplayName: FC<ChatOwningUserDisplayNameProps> = ({chat}) => {
  const styles = useStyles(theme)
  return (
    <Typography
      className={`
        ${styles.chatOwningUserDisplayName}
        ${chat.unreadMessageCount ? styles.unreadMessageItem : ''}
      `}
      color="textPrimary"
      variant="body1"
    >
      <ChatTitle owningUser={chat?.owningUser} />
    </Typography>
  )
}

const ChatTitle: FC<ChatTitleProps> = ({owningUser}) => {
  const styles = useStyles()
  const userDisplayName = useUserDisplayName()
  const hasMerchantCustomerUser =
    !!owningUser?.customerUser?.merchantCustomerUsers?.length
  const displayName = userDisplayName(owningUser)?.trim()
  return (
    <span className={`${styles.chatTitle} notranslate`}>
      {(hasMerchantCustomerUser && displayName) ? displayName : (
        <>
          {formatPhoneNumber(owningUser?.customerUser?.whatsappPhoneNumber)}
          <small>~ {displayName}</small>
        </>
      )}
    </span>
  )
}

const ChatSubtitle = ({owningUser}) => {
  const hasMerchantCustomerUser = !!owningUser?.customerUser.merchantCustomerUsers.length
  return (
    hasMerchantCustomerUser ?
      <MerchantCustomerUserSubtitle value={owningUser?.customerUser} /> :
      <CustomerUserSubtitle value={owningUser?.customerUser} />
  )
}

const MerchantCustomerUserSubtitle: FC<MerchantCustomerUserSubtitleProps> = ({value}) => {
  const styles = useStyles()
  const translate = useTranslate()
  const Divider = useMemo(
    () => () => <span className={styles.merchantCustomerUserSubtitleDivider}></span>
    , [styles])
  const data = [
    value.merchantCustomerUsers[0].companyName?.trim(),
    value.merchantCustomerUsers[0].role?.trim(),
    value.merchantCustomerUsers[0].customerCode?.trim() &&
      `${
        translate('chat.external.header.merchantCustomerUserSubtitle.customerCodePrefix')
      } ${value.merchantCustomerUsers[0].customerCode}`,
    value.merchantCustomerUsers[0].companyCity?.trim(),
  ].filter(Boolean).filter((_, i) => i < 3)
  return (
    <div className={styles.merchantCustomerUserSubtitle}>
      {data.map((v, i) => (
        <>{i > 0 && <Divider />}<span>{v}</span></>
      ))}
    </div>
  )
}

const CustomerUserSubtitle = ({value}) => (
  <MuiLink
    className={useStyles().contactLink}
    component={Link}
    to={
      `/merchant_customer_users/create?whatsappPhoneNumber=${
        encodeURIComponent(value?.whatsappPhoneNumber ?? '')
      }&firstName=${encodeURIComponent(value?.whatsappDisplayName ?? '')}`
    }
  >
    {useTranslate()('chat.external.header.saveContact')}
  </MuiLink>
)

const EmbeddedExternalChatPage = ({channelId, chatId}) => {
  const translate = useTranslate()
  const styles = useStyles(theme)
  const [{[chatId]: mostRecentInboundChatMessageElapsedInterval}] =
    useMostRecentInboundChatMessageElapsedIntervals({chatId})
  const {
    data: {chats_by_pk: chat} = {}, loading: isLoadingChats,
  } = useQuery<QueryRoot['chats_by_pk']>(
    CHAT_QUERY, {variables: {chatId}}
  )
  const {data: {channels_by_pk: channel} = {}} = useQuery<QueryRoot['channels_by_pk']>(
    CHANNEL_QUERY, {variables: {id: channelId}}
  )
  const isMultiMerchantUserChannel = (
    channel?.channelMerchantUsers_aggregate?.aggregate?.count ?? 0
  ) > 1
  const isReplyWindowExpired = useMemo(
    () => !mostRecentInboundChatMessageElapsedInterval ||
      (mostRecentInboundChatMessageElapsedInterval > REPLY_EXPIRATION_INTERVAL),
    [mostRecentInboundChatMessageElapsedInterval]
  )
  // TODO: `useCallback` has been used assuming that it's needed to stabilize the
  // reference in `openChatInNewWindow` and prevent rerenderings of the `Chat` component.
  // Tests are needed to validate if `useCallback` is really needed in order to prevent
  // such rerenderings
  const openChatInNewWindow = useCallback(() => {
    window.open(`/inbox/${channelId}/${chatId}`, '_blank')
  }, [channelId, chatId])
  const [
    isShowingTranslations,
    setIsShowingTranslations,
  ] = useIsShowingTranslations(chatId)
  if (isLoadingChats || !chat) return null
  return (
    <>
      <Button
        className={styles.openChatInNewWindowButton}
        color="primary"
        onClick={openChatInNewWindow}
        variant="contained"
      >
        {translate('pages.startExternalChat.embeddedExternalChatPage.openInFlinkit')}
      </Button>
      <ChatContext.Provider value={{channelId, chatId}}>
        <Chat
          header={
            <ChatHeader
              redirectTo={
                !!chat?.owningUser?.customerUser?.merchantCustomerUsers.length ?
                `/merchant_customer_users/${
                  chat.owningUser.customerUser.merchantCustomerUsers[0].id}` :
                  undefined
              }
              subtitle={
                (
                  !!chat.owningUser?.customerUser?.merchantCustomerUsers.length
                ) && (<ChatSubtitle owningUser={chat.owningUser} />)
              }
              title={<ChatTitle owningUser={chat.owningUser} />}
            >
              {isMultiMerchantUserChannel && (
                <ChatAssignment chat={chat} className={styles.chatAssignment} />
              )}
            </ChatHeader>
          }
          isReplyWindowExpired={isReplyWindowExpired}
          isShowingTranslations={isShowingTranslations}
          onChangeIsShowingTranslations={setIsShowingTranslations}
          type={ChatTypesEnum.External}
        />
      </ChatContext.Provider>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  channelSettingsButton: {
    ...(isNativeMobileApp && {
      '&:hover': {
        '& .MuiSvgIcon-root': {
          color: theme.palette.info.main,
        },
      },
    }),
    padding: theme.remSpacing(1),
    [theme.breakpoints.up('lg')]: {
      '& .MuiButton-label': {
        '& > span': {
          lineHeight: 1.5,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        alignItems: 'start',
        justifyContent: 'start',
      },
      '& .MuiSvgIcon-root': {
        marginRight: theme.remSpacing(1),
      },
      marginLeft: theme.remSpacing(2),
      paddingLeft: theme.remSpacing(2),
      paddingRight: theme.remSpacing(2),
    },
  },
  chatAssignment: {
    width: theme.typography.pxToRem(168),
  },
  chatFilterTabs: {
    marginTop: theme.remSpacing(1),
  },
  chatHeaderSettings: {
    borderTop: '0.025rem solid #CDD5DF',
    // TODO: Use the right components+props to achieve desired layouting, don't 100%
    width: '100%',
  },
  chatOwningUserDisplayName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  chatTitle: {
    '& small': {
      color: theme.palette.text.secondary,
      marginLeft: theme.remSpacing(1),
      whiteSpace: 'nowrap',
      ...theme.typography.body2,
    },
    display: 'inline',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  contactLink: {
    display: 'inline-block',
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  logo: {
    '& svg': {
      height: theme.typography.pxToRem(32),
      width: theme.typography.pxToRem(32),
    },
    display: 'inline-block',
    transform: `translateY(${theme.remSpacing(-2)})`,
  },
  merchantCustomerUserSubtitle: {
    '& $merchantCustomerUserSubtitleDivider': {
      borderLeft: '1px solid #ccc',
      display: 'inline-block',
      height: '2.5ch',
      marginLeft: theme.remSpacing(1),
      marginRight: theme.remSpacing(1),
    },
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.remSpacing(0.5),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  merchantCustomerUserSubtitleDivider: {},
  openChatInNewWindowButton: {
    left: theme.remSpacing(3),
    position: 'absolute',
    top: theme.remSpacing(11),
    zIndex: 1004,
  },
  subtitleTooltip: {
    maxWidth: theme.typography.pxToRem(250),
    padding: theme.remSpacing(1.5),
  },
  unreadMessageItem: {
    fontWeight: theme.typography.subtitle2.fontWeight,
  },
}))

const MULTI_USER_CHAT_FILTERS: ChatFilter[] = ['mine', 'unassigned', 'all', 'archived']
const SINGLE_USER_CHAT_FILTERS: ChatFilter[] = ['all', 'archived']

const CHANNEL_QUERY = gql`query($id: uuid!){
  channels_by_pk(id: $id){channelMerchantUsers_aggregate{aggregate{count}} id}
}`

const FIRST_CHANNEL_QUERY = gql`query($merchantUserId: uuid!){
  channels(
    limit: 1,
    order_by: {name: asc}
    where: {channelMerchantUsers: {merchantUserId: {_eq: $merchantUserId}}}
  ){id}
}`

const CHAT_FIELDS = `
  assignedMerchantUser{
    firstName
    id
    lastName
    userColor
  }
  channelId
  chatMessages(
    limit: 1
    order_by: {timestamp: desc}
    where: {whatsappMessagesStatus: {_neq: CANCELED}}
  ){
    chatMessageTemplate{text}
    chatMessageTemplateValues{chatMessageTemplatePlaceholder{index type} value}
    id
    text
    timestamp
  }
  hasUnreadFailedChatMessage
  id
  insertionTimestamp
  isArchived
  isSpam
  lastActivityTimestamp
  owningUser{
    customerUser{
      id
      merchantCustomerUsers{
        companyName companyCity customerCode firstName id lastName role
      }
      whatsappDisplayName
      whatsappPhoneNumber
    }
    id
  }
  type
  unreadMessageCount
  userChats{chatId isFlagged}
`

const CHAT_QUERY = gql`query($chatId: uuid!){
  chats_by_pk(id: $chatId){${CHAT_FIELDS}}
}`

const FIRST_MINE_AND_FIRST_UNASSIGNED_CHAT_QUERY = gql`
  query($channelId: uuid!, $sessionMerchantUserId: uuid!){
    firstMineChat: chats(
      limit: 1
      order_by: {lastActivityTimestamp: desc},
      where: {
        assignedMerchantUserId: {_eq: $sessionMerchantUserId}
        channelId: {_eq: $channelId}
        chatMessages_aggregate: {count: {predicate: {_gt: 0}}}
        isArchived: {_eq: false}
        isSpam: {_eq: false}
      }
    ){id chatMessages{id}}
    firstUnassignedChat: chats(
      limit: 1
      order_by: {lastActivityTimestamp: desc},
      where: {
        assignedMerchantUserId: {_is_null: true}
        channelId: {_eq: $channelId}
        chatMessages_aggregate: {count: {predicate: {_gt: 0}}}
        isArchived: {_eq: false}
        isSpam: {_eq: false}
      }
    ){id}
  }
`

interface ChatListHeaderProps {
  channelId: string
  chatFilter: ChatFilter
  chatFilters: ChatFilter[]
  isMultiMerchantUserChannel: boolean
}

interface ChatOwningUserDisplayNameProps {
  chat: Chats
}

interface ChatTitleProps {
  owningUser?: Users
}

interface MerchantCustomerUserSubtitleProps {
  value: CustomerUsers
}

export {ExternalChatPage as default, EmbeddedExternalChatPage}
