import {useQuery} from '@apollo/react-hooks'
import gql from "graphql-tag"
import {useEffect, useMemo} from 'react'
import {usePageVisibility} from 'react-page-visibility'

import {QueryRoot} from '../types/graphqlSchema'

const useMostRecentInboundChatMessageElapsedIntervals:
  UseMostRecentInboundChatMessageElapsedIntervals = ({chatId} = {}) => {
    const isPageVisible = usePageVisibility() ?? true
    const {
      data: {chats = []} = {}, error, loading, refetch, startPolling, stopPolling,
    } = useQuery<QueryRoot['chats']>(
      CHAT_ELAPSED_INTERVAL_QUERY, {skip: !chatId, variables: {chatId}},
    )
    const chatIdToElapsedInterval = useMemo(
      () => chats.reduce<Record<string, number>>(
        (chat, item) => {
          chat[item.id as string] =
            item.mostRecentInboundChatMessageElapsedInterval as number
          return chat
        },
        {}
      ),
      [chats]
    )
    // Force-refetch when page becomes visible again to show up-to-date values immediately
    useEffect(
      () => {chatId && isPageVisible && refetch()},
      [chatId, isPageVisible, refetch]
    )
    useEffect(() => {
      if (!(chatId && isPageVisible)) return
      startPolling(5000)
      return stopPolling
    }, [chatId, isPageVisible, startPolling, stopPolling])
    return [chatIdToElapsedInterval, {error, loading}]
  }

const ELAPSED_INTERVAL_PARTIAL = `
  id
  mostRecentInboundChatMessageElapsedInterval
`
const CHAT_ELAPSED_INTERVAL_QUERY = gql`
  query ($chatId: uuid!) {
    chats(where: {id: {_eq: $chatId}}) {${ELAPSED_INTERVAL_PARTIAL}}
  }
`

interface UseMostRecentInboundChatMessageElapsedIntervals {
  (props: {chatId?: string}):
    [Record<string, number>, {error: any | undefined, loading: boolean}]
}

export default useMostRecentInboundChatMessageElapsedIntervals
