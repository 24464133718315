import {MutationResultWrapper, useMutation, useQuery} from '@apollo/react-hooks'
import {
  ClickAwayListener,
  DialogContent,
  FormControlLabel,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
  Typography,
} from '@material-ui/core'
import gql from 'graphql-tag'
import _ from 'lodash'
import {FC, useCallback, useRef, useState} from 'react'
import {useTranslate} from 'react-admin'

import DialogCloseButton from '../../components/DialogCloseButton'
import ExtendedDialog from '../../components/ExtendedDialog'
import {TranslationIcon} from '../../components/icons'
import useIsDesktop from '../../hooks/useIsDesktop'
import {MutationRoot} from '../../types/graphqlSchema'
import {EventCategory, trackEvent} from '../../utils/tracking'

const ChatAutoTranslationSettingButton: FC<ChatAutoTranslationSettingButtonProps> = ({
  chatId,
  disabled,
  isShowingTranslations,
  onChangeIsShowingTranslations,
}) => {
  const styles = useStyles()
  const isDesktop = useIsDesktop()
  const {
    data: {chats_by_pk: chat} = {},
  } = useQuery(CHAT_QUERY, {variables: {id: chatId}})
  const [updateChat] = useMutation<MutationRoot['update_chats_by_pk']>(
    UPDATE_CHAT_MUTATION
  )
  const setTranslationLanguage = useCallback(language => {
    updateChat({
      optimisticResponse: {
        update_chats_by_pk: {
          __typename: 'chats', autoTranslationLanguage: language, id: chatId,
        },
      } as MutationResultWrapper<MutationRoot['update_chats_by_pk']>,
      variables: {autoTranslationLanguage: language, id: chatId},
    })
  }, [chatId, updateChat])
  const anchorElementRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <IconButton
        aria-label="translation"
        disabled={disabled}
        onClick={() => setIsOpen(isOpen => !isOpen)}
      >
        <TranslationIcon
          color={
            (chat?.autoTranslationLanguage || isShowingTranslations) ? 'info' : 'primary'
          }
        />
      </IconButton>
      {isDesktop ?
        (
          // TODO: Get rid of this outer div; the button can be the anchor!
          <div onClick={() => setIsOpen(isOpen => !isOpen)} ref={anchorElementRef}>
            {/*
              TODO: Show arrow (nose), described in the docs:
                https://v4.mui.com/components/popper/
              with example code here:
                https://github.com/mui/material-ui/blob/4f2a07e140c954b478a6670c009c23a59ec3e2d4/docs/src/pages/components/popper/ScrollPlayground.js
            */}
            <Popper
              anchorEl={anchorElementRef.current}
              className={styles.popper}
              modifiers={{flip: {enabled: true}}}
              open={isOpen}
              placement="bottom"
            >
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <Paper
                  className={styles.popperPaper}
                  elevation={3}
                  onClick={e => e.stopPropagation()}
                >
                  <TranslationSettingsContent
                    autoTranslationLanguage={chat?.autoTranslationLanguage}
                    isShowingTranslations={isShowingTranslations}
                    onChangeIsShowingTranslations={onChangeIsShowingTranslations}
                    onSetTranslationLanguage={setTranslationLanguage}
                  />
                  <DialogCloseButton
                    className={styles.closeButton}
                    onClick={() => setIsOpen(false)}
                  />
                </Paper>
              </ClickAwayListener>
            </Popper>
          </div>
        ) :
        (
          <ExtendedDialog fullScreen onClose={() => setIsOpen(false)} open={isOpen}>
            <DialogContent>
              <TranslationSettingsContent
                autoTranslationLanguage={chat?.autoTranslationLanguage}
                isShowingTranslations={isShowingTranslations}
                onChangeIsShowingTranslations={onChangeIsShowingTranslations}
                onSetTranslationLanguage={setTranslationLanguage}
              />
            </DialogContent>
            <DialogCloseButton onClick={() => setIsOpen(false)} />
          </ExtendedDialog>
        )
      }
    </>
  )
}

const TranslationSettingsContent = ({
  autoTranslationLanguage,
  isShowingTranslations,
  onChangeIsShowingTranslations,
  onSetTranslationLanguage,
}) => {
  const styles = useStyles()
  const translate = useTranslate()
  return (
    <div>
      <Typography variant="h3">
        {translate('chat.autoTranslationSettingButton.title')}
      </Typography>
      <Typography color="textSecondary" variant="body1">
        {translate('chat.autoTranslationSettingButton.subtitle')}
      </Typography>
      <MenuList>
        {
          _.sortBy(
            [null, ...LANGUAGES].map(
              l => ({
                label: l ?
                  translate(`languages.${l}`) :
                  translate('chat.autoTranslationSettingButton.doNotTranslate'),
                language: l,
              })
            ),
            'label'
          ).map(({label, language}) => (
            <MenuItem
              className={`
              ${styles.translationsMenuListItem}
              ${(language === autoTranslationLanguage) ? styles.selected : ''}
            `}
              key={language ?? 'off'}
              onClick={() => {
                onSetTranslationLanguage(language)
                trackEvent('SELECT_TRANSLATION_LANGUAGE', EventCategory.CHAT)
              }}
            >
              {label}
              {(language === autoTranslationLanguage) && " ✓"}
            </MenuItem>
          ))
        }
      </MenuList>
      <hr className={styles.popperSettingsSeparator} />
      <h4>{translate('chat.autoTranslationSettingButton.moreSettings')}</h4>
      <FormControlLabel
        className={styles.isShowingTranslationsSwitch}
        control={<Switch
          checked={isShowingTranslations}
          onChange={(_, isShowingTranslations) => {
            onChangeIsShowingTranslations(isShowingTranslations)
          }}
        />}
        label={translate('chat.autoTranslationSettingButton.autoDetection')}
        labelPlacement="start"
      />
    </div>
  )
}

interface ChatAutoTranslationSettingButtonProps {
  chatId: string
  disabled?: boolean
  isShowingTranslations?: boolean
  onChangeIsShowingTranslations?: (isShowingTranslations: boolean) => void
}

const useStyles = makeStyles(theme => ({
  closeButton: {
    paddingTop: theme.remSpacing(2),
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  isShowingTranslationsSwitch: {
    fontSize: theme.typography.body1.fontSize,
    marginLeft: 0,
    marginRight: 0,
  },
  popper: {
    maxWidth: '340px',
    zIndex: 1004,
  },
  popperPaper: {
    '& .MuiTypography-root': {
      marginBottom: theme.remSpacing(1),
    },
    boxSizing: 'border-box',
    maxHeight: 'calc(100vh - 5px)',
    overflowY: 'auto',
    padding: theme.remSpacing(2),
  },
  popperSettingsSeparator: {
    border: '1px solid #CDD5DF',
  },
  selected: {
    fontWeight: 'bold',
  },
  translationsMenuListItem: {
    fontSize: theme.typography.body1.fontSize,
  },
}))

const CHAT_QUERY = gql`
  query($id: uuid!){chats_by_pk(id: $id){autoTranslationLanguage id}}
`

const UPDATE_CHAT_MUTATION = gql`
  mutation($id: uuid!, $autoTranslationLanguage: String) {
    update_chats_by_pk(
      pk_columns: {id: $id}
      _set: {autoTranslationLanguage: $autoTranslationLanguage}
    ){autoTranslationLanguage id}
  }
`

const LANGUAGES = [
  'bs', 'en', 'es', 'fr', 'hr', 'it', 'ku', 'nl', 'pl', 'ro', 'ru', 'sq', 'sr', 'tr',
]

export default ChatAutoTranslationSettingButton
