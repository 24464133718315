import {Box, Divider, Grid, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {
  FC,
  ReactElement,
  ReactNode,
  ReactNodeArray,
} from 'react'
import {Title} from 'react-admin'
import {useParams} from 'react-router-dom'

import useIsDesktop from '../../hooks/useIsDesktop'
import Theme from '../../theme'
import DisplayBox from '../DisplayBox'

const ChatPage: FC<ChatPageProps> = ({
  chatListHeader,
  children,
  startNewChatButton,
  title,
}) => {
  const {chatId} = useParams<{chatId: string}>()
  const styles = useStyles({hasChatId: !!chatId})
  const isDesktop = useIsDesktop()
  const [chatList, chat] = children
  return (
    <Grid className={styles.root}>
      <Grid className={styles.chatListContainer}>
        <Title title={title} />
        {isDesktop && (
          <Typography
            className={styles.mainTitle}
            color="textPrimary"
            variant="h2"
          >
            {title}
          </Typography>
        )}
        <div className={styles.chatListHeader}>
          {chatListHeader}
        </div>
        <Box height="100%">
          {chatList}
          {startNewChatButton}
        </Box>
      </Grid>
      <DisplayBox mobile={false}>
        <Divider flexItem orientation="vertical" />
      </DisplayBox>
      <Grid className={styles.chatContainer}>
        {chat}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles<typeof Theme, MakeStylesOptions>(theme => ({
  chatContainer: {
    [theme.breakpoints.down('md')]: {
      display: ({hasChatId}) => hasChatId ? 'block' : 'none',
      width: '100%',
    },
    backgroundPosition: 'left center',
    backgroundRepeat: 'repeat',
    borderLeft: `solid .5px ${theme.palette.disabled.main}`,
    flexGrow: 1,
    height: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    width: '62%',
  },
  chatListContainer: {
    [theme.breakpoints.down('md')]: {
      display: ({hasChatId}) => hasChatId ? 'none' : 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    // make sufficient space for all chat filter tabs and their unread indicator
    minWidth: '320px',
    position: 'relative',
    width: '38%',
  },
  chatListHeader: {
    marginBottom: theme.remSpacing(1),
  },
  mainTitle: {
    marginBottom: theme.remSpacing(3),
    marginLeft: theme.remSpacing(5),
    marginRight: theme.remSpacing(2),
    marginTop: theme.remSpacing(4),
    paddingBottom: theme.remSpacing(4),
  },
  root: {
    display: 'flex',
    overflowX: 'hidden',
  },
  startNewChatButton: {
    margin: '1rem',
    marginBottom: '2rem',
  },
}))

interface ChatPageProps {
  chatListHeader?: ReactNode
  children: ReactNodeArray
  startNewChatButton: ReactNode
  title: ReactElement | string
}

interface MakeStylesOptions {
  hasChatId: boolean
}

export default ChatPage
