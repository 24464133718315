import {
  Grid,
  Toolbar,
  Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FC, ReactNode} from 'react'
import {Link} from 'react-admin'
import {useRouteMatch} from 'react-router-dom'

import BackNavigationLink from '../BackNavigationLink'
import DisplayBox from '../DisplayBox'

const ChatHeader: FC<ChatHeaderProps> = ({children, redirectTo, subtitle, title}) => {
  const styles = useStyles()
  return (
    <Toolbar className={styles.root}>
      <DisplayBox desktop={false} mobile={true}>
        <BackNavigationLink
          className={styles.backNavigationLink}
          fallbackPath={useRouteMatch('/inbox/:channelId')?.url ?? '/team-chat'}
        />
      </DisplayBox>
      <Grid
        className={styles.titleGrid}
        // @ts-ignore: "component" props not found
        component={redirectTo ? Link : undefined}
        item
        to={redirectTo}
      >
        <Typography
          className={styles.title}
          color="textPrimary"
          variant="subtitle2"
        >
          {title}
        </Typography>
        <Typography color="textSecondary" variant="caption">{subtitle}</Typography>
      </Grid>
      {children}
    </Toolbar>
  )
}

const useStyles = makeStyles(theme => ({
  backNavigationLink: {
    zIndex: 1002,
  },
  backNavigationLinkGrid: {
    paddingLeft: 0,
  },
  root: {
    alignItems: 'center',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.disabled.main,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginLeft: 0,
    marginRight: 0,
    paddingRight: 0,
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  titleGrid: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.remSpacing(2.5),
      marginTop: theme.remSpacing(3),
      paddingLeft: theme.remSpacing(1),
    },
    flexGrow: 1,
    marginBottom: theme.remSpacing(3),
    marginTop: theme.remSpacing(3),
    minWidth: theme.typography.pxToRem(140),
    paddingLeft: 0,
  },
}))

interface ChatHeaderProps {
  children?: ReactNode
  redirectTo?: string
  subtitle?: ReactNode
  title: ReactNode
}

export default ChatHeader
