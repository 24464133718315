import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {useNotify} from 'react-admin'
import {DropzoneRootProps, useDropzone} from 'react-dropzone'

import {arrayBufferToBase64, arrayBufferToHex} from '../utils/buffers'
import {THREE_SIXTY_CLOUD_API_MAX_FILE_SIZE_BYTES} from '../utils/consts'

const useFileSelection: UseFileSelection = ({
  canDragAndDrop = true,
  encoding = 'hex',
  mimeTypes,
  multiple,
  onSelectFiles,
  shouldCheckForThreeSixtyCloudApiMaxFileSize = false,
}) => {
  const notify = useNotify()
  const [files, setFiles] = useState<FileAttachment[]>([])
  const remountFileInput = useCallback(() => {
    // We unmount then remount the input component to allow to select the same
    // file multiple times.
    setIsFileInputMounted(false)
    setTimeout(() => setIsFileInputMounted(true), 0)
  }, [])
  const selectFiles = useCallback(async files => {
    const addedFiles = await Promise.all([...files].map(async f => {
      const arrayBuffer = await f.arrayBuffer()
      return {
        encodedContent: {
          'base64': arrayBufferToBase64,
          'hex': arrayBufferToHex,
        }[encoding](arrayBuffer),
        file: f,
        filename: f.name,
        mimeType: f.type,
      }
    }))
    remountFileInput()
    setFiles(previousFiles => [...(multiple ? previousFiles : []), ...addedFiles])
  }, [encoding, multiple, remountFileInput])
  const {
    getInputProps,
    getRootProps,
    inputRef: fileInputRef,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: mimeTypes,
    maxSize: shouldCheckForThreeSixtyCloudApiMaxFileSize ?
      THREE_SIXTY_CLOUD_API_MAX_FILE_SIZE_BYTES : undefined,
    multiple,
    noClick: true,
    noKeyboard: true,
    onDrop: selectFiles,
    onDropRejected: fileRejections => {
      fileRejections[0].errors[0].code === 'file-too-large' &&
        notify(
          'validations.files.maxSize',
          'error',
          {
            fileSize: (fileRejections[0].file.size / 1_048_576).toFixed(2),
            filename: fileRejections[0].file.name,
            maxSize: THREE_SIXTY_CLOUD_API_MAX_FILE_SIZE_BYTES / 1_048_576,
          }
        )
    },
  })
  const [isFileInputMounted, setIsFileInputMounted] = useState(true)
  const input = useMemo<JSX.IntrinsicElements['input'] | null>(() => (
    isFileInputMounted ? <input {...getInputProps()} /> : null
  ), [getInputProps, isFileInputMounted])
  useEffect(() => {
    files.length && onSelectFiles?.(files)
  }, [files, onSelectFiles])
  return {
    dropZoneProps: canDragAndDrop ? getRootProps() : {},
    files,
    input,
    isDraggedFileRejected: isDragReject,
    isDragging: isDragActive,
    openFileSelectionDialog:
      useCallback(() => fileInputRef.current?.click?.(), [fileInputRef]),
    setFiles,
  }
}

interface UseFileSelection {
  (props: UseFileSelectionProps): {
    dropZoneProps: DropzoneRootProps
    files: FileAttachment[]
    input: JSX.IntrinsicElements['input'] | null
    isDraggedFileRejected: boolean
    isDragging: boolean
    openFileSelectionDialog: () => void
    setFiles: Dispatch<SetStateAction<FileAttachment[]>>
  }
}

interface UseFileSelectionProps {
  canDragAndDrop?: boolean
  encoding?: 'base64'|'hex'
  mimeTypes: string | string[]
  multiple?: boolean
  onSelectFiles?: (files: FileAttachment[]) => void
  shouldCheckForThreeSixtyCloudApiMaxFileSize?: boolean
}

export default useFileSelection
