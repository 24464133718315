import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import {FC, ReactNode, SyntheticEvent, useRef} from 'react'
import {useTranslate} from 'react-admin'

import DialogCloseButton from '../../components/DialogCloseButton'
import ExtendedDialog from '../../components/ExtendedDialog'

const ChatNameEditDialog: FC<ChatNameEditDialogProps> = ({
  children,
  initialValue,
  onClose,
  onConfirm,
  open = false,
}) => {
  const ref = useRef<HTMLInputElement>()
  return (
    <ExtendedDialog
      aria-labelledby="form-dialog-title"
      onClose={onClose}
      onMouseDown={e => e.stopPropagation()}
      open={open}
      scroll="paper"
    >
      <DialogTitle>{useTranslate()('dialogs.chatNameEdit.title')}</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        {children}
        <TextField
          autoFocus
          defaultValue={initialValue}
          inputRef={ref}
          placeholder="Chat-Name"
          variant="filled"
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={e => {
            onConfirm(ref.current?.value ?? '')
            onClose(e)
          }}
          variant="contained"
        >
          {useTranslate()('dialogs.chatNameEdit.renameAction')}
        </Button>
      </DialogActions>
    </ExtendedDialog>
  )
}

interface ChatNameEditDialogProps {
  children?: ReactNode
  initialValue?: string,
  onClose: (e: SyntheticEvent | Record<string, any>) => void
  onConfirm: (name: string) => void
  open?: boolean,
}

export default ChatNameEditDialog
