import {useQuery} from '@apollo/react-hooks'
import {
  Box, Divider, IconButton, ListItemIcon, ListItemText,
  Menu, MenuItem, Tooltip, Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import {FC, LinkHTMLAttributes, useContext, useEffect, useRef, useState} from 'react'
import {Link, useLogout, useTranslate} from 'react-admin'
import {useLocation} from 'react-router-dom'

import {SandboxContext} from '../../contexts/SandboxContext'
import useHasUnreadChatMessages from '../../hooks/chat/useHasUnreadChatMessages'
import useHasPermission from '../../hooks/useHasPermission'
import useIsDesktop from '../../hooks/useIsDesktop'
import useSessionMerchantUser from '../../hooks/useSessionMerchantUser'
import {Channels, ChatTypesEnum, QueryRoot} from '../../types/graphqlSchema'
import formatPhoneNumber from '../../utils/phoneNumber'
import AttentionIndicator from '../AttentionIndicator'
import {
  BriefcaseIcon,
  CampaignIcon,
  ChatMessageTemplateIcon,
  ContactsIcon,
  ExternalChatIcon,
  HelpIcon,
  InternalChatIcon,
  LogoutIcon,
  MoreVertIcon,
  UserIcon,
} from '../icons'
import ProfileDialog from '../settings/ProfileDialog'
import ProfileInitials from '../settings/ProfileInitials'

const SidebarMenu: FC<SidebarMenuProps> = ({onClose}) => {
  const styles = useMenuStyles()
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false)
  const {pathname} = useLocation()
  const isDesktop = useIsDesktop()
  const logout = useLogout()
  const profileLinkRef = useRef(null)
  const chatListRef = useRef<HTMLDivElement>()
  const [isProfileLinkMenuOpen, setIsProfileLinkMenuOpen] = useState(false)
  const [initialTabIndex, setInitialTabIndex] = useState(0)
  const {isSandbox, shouldOnboard} = useContext(SandboxContext)
  const openProfileLinkMenu = event => {
    event.preventDefault()
    setIsProfileLinkMenuOpen(true)
  }
  const closeProfileLinkMenu = event => {
    event.stopPropagation()
    setIsProfileLinkMenuOpen(false)
  }
  const onClickProfileLinkMenuItem = (event, index) => {
    event.stopPropagation()
    setIsProfileDialogOpen(true)
    setInitialTabIndex(index)
    setIsProfileLinkMenuOpen(false)
  }
  const {merchantUser: {
    id: merchantUserId,
    firstName,
    lastName,
    merchant: {name: merchantName} = {name: undefined},
    userColor,
  } = {}} = useSessionMerchantUser()
  const {data: {channels} = {}} = useQuery<QueryRoot['channels']>(
    CHANNELS_QUERY,
    {skip: !merchantUserId, variables: {merchantUserId}}
  )
  const checkIsActiveRoute = (path: string) => pathname.startsWith(path)
  // TODO: both internal and external subscribe to the same data source, in the
  // future we should combine the subscriptions
  const hasUnreadInternalChatMessages = useHasUnreadChatMessages({
    chatType: ChatTypesEnum.Internal,
  })
  const hasUnreadExternalChatMessages = useHasUnreadChatMessages({
    channelId: channels?.[0]?.id,
    chatType: ChatTypesEnum.External,
  })
  const canEditMerchant = useHasPermission('edit', 'merchants')
  const translate = useTranslate()
  useEffect(() => {
    if (channels && chatListRef.current) {
      const ref = chatListRef.current
      // Check if current chat list has overflow
      ref.dataset.overflow = `${ref.scrollHeight > ref.clientHeight}`
      const setScroll = () => {
        const scrollPercentage = Math.round(
          (ref.scrollTop / (ref.scrollHeight - ref.clientHeight)) * 100
        )
        ref.dataset.scroll = `${scrollPercentage}%`
      }
      // Set initial scroll position percentage
      setScroll()
      // We (re)set the scroll position percentage whenever a scroll happens or when the
      // screen is resized. During resize, the height changes, so the scroll percentage
      // changes as well (in comparison to maxHeight)
      ref.addEventListener('scroll', setScroll)
      ref.addEventListener('resize', setScroll)
      return () => {
        ref.removeEventListener('scroll', setScroll)
        ref.removeEventListener('resize', setScroll)
      }
    }
  }, [channels])
  return (
    <div className={styles.root}>
      <div className={styles.menuGroup}>
        {isProfileDialogOpen && (
          <ProfileDialog
            initialTabIndex={initialTabIndex}
            onClose={() => setIsProfileDialogOpen(false)}
            open
          />
        )}
        <Link
          className={`${styles.menuLink} ${styles.profileLink}`}
          onClick={e => {
            if (isDesktop) return
            e.preventDefault()
            setIsProfileDialogOpen(true)
          }}
          to="#"
        >
          <ProfileInitials
            backgroundColor={userColor}
            className={styles.profileInitials}
            name={`${firstName ?? ''} ${lastName ?? ''}`}
            variant="body2"
          />
          <div className={`${styles.multiLineLink} ${styles.profileNames}`}>
            <Typography variant="subtitle2">{`${firstName} ${lastName}`}</Typography>
            <Typography variant="caption">
              {isSandbox ? translate('sidebar.profile.sandboxAccount') : merchantName}
            </Typography>
          </div>
          <IconButton
            aria-controls="long-menu"
            aria-haspopup="true"
            aria-label="more"
            className={styles.profileLinkMenuIcon}
            color="inherit"
            onClick={openProfileLinkMenu}
            ref={profileLinkRef}
          >
            <MoreVertIcon />
            <Menu
              anchorEl={profileLinkRef.current}
              anchorOrigin={{horizontal: -160, vertical: 35}}
              className={styles.profileLinkMenu}
              getContentAnchorEl={null}
              keepMounted
              onClose={closeProfileLinkMenu}
              open={isProfileLinkMenuOpen}
            >
              <MenuItem onClick={event => onClickProfileLinkMenuItem(event, 0)}>
                <ListItemIcon><UserIcon color="primary"/></ListItemIcon>
                <ListItemText>
                  <Typography>
                    {translate('dialogs.profile.merchantUsersSettings.title')}
                  </Typography>
                </ListItemText>
              </MenuItem>
              {canEditMerchant && (
                <MenuItem onClick={event => onClickProfileLinkMenuItem(event, 1)}>
                  <ListItemIcon><BriefcaseIcon color="primary"/></ListItemIcon>
                  <ListItemText>
                    <Typography>
                      {translate('dialogs.profile.companySettings.title')}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              )}
              <Divider className={styles.divider} light/>
              <MenuItem onClick={() => logout()}>
                <ListItemIcon><LogoutIcon color="error"/></ListItemIcon>
                <ListItemText>
                  <Typography>{translate('sidebar.menu.logout')}</Typography>
                </ListItemText>
              </MenuItem>
            </Menu>
          </IconButton>
        </Link>
        <Link
          className={`
            ${styles.menuLink}
            ${checkIsActiveRoute('/inbox') ? styles.activeRoute : ''}
          `}
          onClick={onClose}
          to={(isSandbox && shouldOnboard) ? '/sandbox' : '/inbox'}
        >
          <ExternalChatIcon />
          <Typography variant="body1">{translate('sidebar.inbox')}</Typography>
          {(
            (channels?.length === 1) && hasUnreadExternalChatMessages
          ) && <AttentionIndicator />}
        </Link>
        {/* @ts-ignore */}
        <Box className={styles.channelsContainer} ref={chatListRef}>
          {((channels?.length ?? 0) > 1) && channels?.map(channel => (
            <ChannelLink
              channel={channel}
              key={channel.id}
              onCheckIsActiveRoute={checkIsActiveRoute}
              onClick={onClose}
            />
          ))}
        </Box>
        <Link
          className={ `
            ${styles.menuLink}
            ${checkIsActiveRoute('/merchant_customer_users') ? styles.activeRoute : ''}
          `}
          onClick={onClose}
          to="/merchant_customer_users"
        >
          <ContactsIcon />
          <Typography variant="body1">{translate('sidebar.contacts')}</Typography>
        </Link>
        <Link
          className={`
            ${styles.menuLink}
            ${checkIsActiveRoute('/chat_message_templates') ? styles.activeRoute : ''}
          `}
          onClick={onClose}
          to="/chat_message_templates"
        >
          <ChatMessageTemplateIcon />
          <Typography variant="body1">{translate('sidebar.templates')}</Typography>
        </Link>
        <Link
          className={`
            ${styles.menuLink}
            ${checkIsActiveRoute('/campaigns') ? styles.activeRoute : ''}
          `}
          onClick={onClose}
          to="/campaigns"
        >
          <CampaignIcon />
          <Typography variant="body1">{translate('sidebar.campaigns')}</Typography>
        </Link>
        <Link
          className={`
            ${styles.menuLink}
            ${checkIsActiveRoute('/team-chat') ? styles.activeRoute : ''}
          `}
          onClick={onClose}
          to={'/team-chat'}
        >
          <InternalChatIcon />
          <Typography variant="body1">{translate('sidebar.teamChat')}</Typography>
          {hasUnreadInternalChatMessages && <AttentionIndicator />}
        </Link>
      </div>
      {/* TODO: make visible once help content is available */}
      <div style={{display: 'none'}}>
        <hr />
        <Link className={`${styles.menuLink} ${styles.helpLink}`} to="#">
          <HelpIcon />
          <Typography variant="body1">{translate('sidebar.help')}</Typography>
        </Link>
      </div>
    </div>
  )
}

const ChannelLink: FC<ChannelLinkProps> = ({
  channel: {id: channelId, name, whatsappAccount: {phoneNumber} = {} as any},
  onCheckIsActiveRoute,
  ...props
}) => {
  const styles = useMenuStyles()
  const hasUnreadExternalChatMessages = useHasUnreadChatMessages({
    channelId,
    chatType: ChatTypesEnum.External,
  })
  return (
    <Link
      className={`
        ${styles.menuLink}
        ${styles.submenuLink}
        ${onCheckIsActiveRoute(`/inbox/${channelId}`) ? styles.activeRoute : ''}
      `}
      to={`/inbox/${channelId}`}
      {...props}
    >
      <span>•</span>{/* TODO: Render via CSS ::before instead */}
      <Tooltip arrow title={formatPhoneNumber(phoneNumber)}>
        <Typography variant="body1">{name}</Typography>
      </Tooltip>
      {hasUnreadExternalChatMessages && <AttentionIndicator />}
    </Link>
  )
}

const useMenuStyles = makeStyles(theme => ({
  activeRoute: {
    backgroundColor: theme.palette.secondary.dark,
  },
  channelsContainer: {
    '&[data-overflow="true"]': {
      '&:not([data-scroll="0%"])': {
        '&:not([data-scroll="100%"])': {
          boxShadow: `0px 0px 12px 3px ${theme.palette.primary.dark} inset`,
        },
        boxShadow: `0px 30px 12px -12px ${theme.palette.primary.dark} inset`,
      },
      '&:not([data-scroll="100%"])': {
        boxShadow: `0px -30px 12px -12px ${theme.palette.primary.dark} inset`,
      },
    },
    maxHeight: `calc(100vh - ${theme.remSpacing(60)})`,
    overflowY: 'auto',
    scrollbarColor: `${theme.palette.background.secondary} ${theme.palette.primary.main}`,
    scrollbarWidth: 'thin',
  },
  divider: {
    margin: `${theme.remSpacing(1)} ${theme.remSpacing(2)}`,
  },
  helpLink: {
    marginBottom: theme.typography.pxToRem(6),
    marginTop: theme.typography.pxToRem(25),
  },
  menuGroup: {
    flexGrow: 1,
  },
  menuLink: {
    [theme.breakpoints.up('lg')]: {
      // Use smaller padding as the sidebar is more narrow
      paddingLeft: theme.remSpacing(2),
    },
    '& .MuiSvgIcon-root': {
      marginRight: theme.remSpacing(1),
    },
    '& .MuiTypography-root': {
      display: 'inline-block',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    alignItems: 'center',
    display: 'flex',
    paddingBottom: theme.remSpacing(1),
    paddingLeft: theme.remSpacing(3),
    paddingRight: `${theme.remSpacing(2)} !important`, // TODO: why `!important`?
    paddingTop: theme.remSpacing(1),
  },
  merchantName: {
    whiteSpace: 'normal !important' as 'normal',
  },
  multiLineLink: {
    alignItems: 'start',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.remSpacing(0.5),
    justifyContent: 'center',
  },
  profileInitials: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.remSpacing(1.5),
    maxHeight: theme.remSpacing(6),
    maxWidth: theme.remSpacing(6),
  },
  profileLink: {
    marginBottom: theme.remSpacing(4),
    [theme.breakpoints.up('lg')]: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      cursor: 'auto',
    },
  },
  profileLinkMenu: {
    '& .MuiListItemIcon-root': {
      minWidth: theme.remSpacing(4.5),
    },
  },
  profileLinkMenuIcon: {
    [theme.breakpoints.up('lg')]: {
      '& .MuiSvgIcon-root': {
        marginRight: 0,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      display: 'block',
      marginLeft: 'auto',
      padding: theme.remSpacing(1),
    },
    display: 'none',
  },
  profileNames: {
    overflow: 'hidden',
  },
  root: {
    '& a': {
      color: theme.palette.background.paper,
    },
    // TODO: We should set border-box globally to any element, it's the only sane option
    boxSizing: 'border-box', // prevent from overflowing due to width+padding setting
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.remSpacing(3),
    paddingTop: theme.remSpacing(3),
    width: '100%',
  },
  separatorTop: {
    border: 0,
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    height: 0,
    marginBottom: theme.remSpacing(3),
  },
  submenuLink: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.remSpacing(6),
    },
    '& > span:first-child': {
      marginRight: theme.remSpacing(1),
    },
    paddingLeft: theme.remSpacing(7),
  },
}))

const CHANNELS_QUERY = gql`query($merchantUserId: uuid!){
  channels(
    order_by: {name: asc}
    where: {channelMerchantUsers: {merchantUserId: {_eq: $merchantUserId}}}
  ){id name whatsappAccount{phoneNumber}}
}`

interface SidebarMenuProps {
  onClose: () => void
}

interface ChannelLinkProps extends LinkHTMLAttributes<Partial<HTMLLinkElement>> {
  channel: Channels
  onCheckIsActiveRoute: (path: string) => boolean
}

export default SidebarMenu
export {CHANNELS_QUERY}
