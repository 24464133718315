import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core'
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import {makeStyles} from '@material-ui/core/styles'
import {Color} from '@material-ui/lab'
import gql from 'graphql-tag'
import {
  FC,
  FormEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  BooleanInput,
  DateTimeInput,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetIdentity,
  useGetOne,
  useNotify,
  useRedirect,
  useTranslate,
  useUpdate,
} from 'react-admin'
import {useFormState} from 'react-final-form'
import {QRCode} from 'react-qrcode-logo'

import AccordionWrapper from '../../components/AccordionWrapper'
import {ChatMessageTemplateField}
  from '../../components/chatMessageTemplate/ChatMessageTemplateEditor'
import DialogCloseButton from '../../components/DialogCloseButton'
import EditFormAutoSave from '../../components/EditFormAutoSave'
import ExtendedAlert from '../../components/ExtendedAlert'
import ExtendedDialog from '../../components/ExtendedDialog'
import ExtendedEdit from '../../components/ExtendedEdit'
import FileUploadButton from '../../components/FileUploadButton'
import {ArrowDownIcon, DownloadIcon, ShareIcon} from '../../components/icons'
import TabPanel from '../../components/TabPanel'
import WhatsappAccountBusinessProfileEdit
  from '../../components/WhatsappAccountBusinessProfileEdit'
import {SandboxContext} from '../../contexts/SandboxContext'
import useHasPermission from '../../hooks/useHasPermission'
import useIsDesktop from '../../hooks/useIsDesktop'
import {Edit as ChannelChatBotUserEdit} from '../../resources/channelChatBotUsersResource'
import {
  ChannelEdit,
  ChannelMerchantUsersShow,
} from '../../resources/channelsResource'
import TimeInput, {ValidationFunction} from '../../resources/components/TimeInput'
import theme from '../../theme'
import {
  ChannelChatBotUsers,
  Channels,
  ChannelWorkingHours,
  MutationRoot,
  QueryRoot,
  WhatsappAccountConnection,
  WhatsappAccounts,
} from '../../types/graphqlSchema'
import {
  isTimestampAfter,
  isTimestampInPast,
  isValidDateTimeString,
} from '../../utils/dates'
import {isNativeMobileApp} from '../../utils/platform'
import {EventCategory, trackEvent} from '../../utils/tracking'

const ChannelSettingsDialog: FC<ChannelSettingsDialogProps> = ({
  channelId,
  onClose,
  open = false,
}) => {
  const styles = useStyles()
  const translate = useTranslate()
  const {isSandbox} = useContext(SandboxContext)
  const [settingsTabIndex, setSettingsTabIndex] = useState(0)
  const onSettingsTabChange = (_, newSettingsTabIndex) => {
    setSettingsTabIndex(newSettingsTabIndex)
  }
  const settingsTabLabelAndComponents = useGetSettingsTabLabelAndComponents()
  return (
    <ExtendedDialog
      className={styles.root}
      keepMounted={false}
      maxWidth="md"
      onClose={onClose}
      open={open}
    >
      <DialogTitle>{translate('dialogs.channelSettings.title')}</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <Tabs
        TabIndicatorProps={{children: <span />}}
        indicatorColor="primary"
        onChange={onSettingsTabChange}
        textColor="primary"
        value={settingsTabIndex}
        variant="fullWidth"
      >
        {settingsTabLabelAndComponents.map(([name], i) => (
          <Tab key={i} label={name} />
        ))}
      </Tabs>
      <DialogContent className={styles.dialogContent}>
        {settingsTabLabelAndComponents.map(([, TabPanelComponent], i) => (
          <TabPanel dir={theme.direction} index={i} key={i} value={settingsTabIndex}>
            {isSandbox ? (
              <ExtendedAlert severity="warning">
                {translate('sandboxSettings.featureNotAvailable')}
              </ExtendedAlert>
            ) : (
              <TabPanelComponent
                channelId={channelId}
              />
            )}
          </TabPanel>
        ))}
      </DialogContent>
    </ExtendedDialog>
  )
}

const AutoRepliesTabPanel: FC<HasChannelId> = ({channelId}) => {
  const [alerts, setAlerts] = useState<Alert[]>([])
  const styles = useStyles()
  const translate = useTranslate()
  const {
    data: {channel_chat_bot_users: [channelChatBotUser] = []} = {},
    loading: isFetchingChannelChatbotUsers,
  } = useQuery<QueryRoot['channel_chat_bot_users']>(
    CHANNEL_CHAT_BOT_USER_QUERY, {skip: !channelId, variables: {channelId}}
  )
  const canEditChannel = useHasPermission('edit', 'channels')
  const canEditChannelChatbotUsers = useHasPermission('edit', 'channel_chat_bot_users')
  const pushAlert = (alert: Alert) => {
    setAlerts(a => a.find(({id}) => id === alert.id) ? a : [...a, alert])
  }
  useEffect(() => {
    setAlerts([])
    if (!(canEditChannel && canEditChannelChatbotUsers)) {
      pushAlert({
        id: 'warn-merchant-user-not-admin',
        severity: 'warning',
        text: translate('dialogs.channelSettings.autoReplies.permissionDenied'),
      })
    }
  }, [canEditChannel, canEditChannelChatbotUsers, translate])
  useEffect(() => {
    if (isFetchingChannelChatbotUsers) return
    if (!channelChatBotUser) {
      pushAlert({
        id: 'info-channel-missing-channel-chatbot-user',
        severity: 'info',
        text: (
          <>
            {translate('dialogs.channelSettings.autoReplies.noChatBotSetup')}
            <a href="mailto:info@flinkit.de">Flinkit</a>
          </>
        ),
      })
    }
  }, [channelChatBotUser, isFetchingChannelChatbotUsers, translate])
  return (
    <>
      {alerts.map(({severity, text}, i) => (
        <ExtendedAlert className={styles.alert} key={i} severity={severity}>
          {text}
        </ExtendedAlert>
      ))}
      <AccordionWrapper>
        <Accordion elevation={2} name="channel-absence-settings">
          <AccordionSummary expandIcon={<ArrowDownIcon />}>
            <Typography>
              {translate('dialogs.channelSettings.autoReplies.absence.title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ChannelAbsenceSettings channelId={channelId} disabled={!canEditChannel} />
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={2} name="channel-working-hours-settings">
          <AccordionSummary expandIcon={<ArrowDownIcon />}>
            <Typography>
              {translate('dialogs.channelSettings.autoReplies.outsideWorkingHours.title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ChannelWorkingHoursSettings
              channelId={channelId} disabled={!canEditChannel}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={2} name="channel-welcome-settings">
          <AccordionSummary expandIcon={<ArrowDownIcon />}>
            <Typography>
              {translate('dialogs.channelSettings.autoReplies.welcomeMessage.title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ChannelWelcomeSettings channelId={channelId} disabled={!canEditChannel} />
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={2} name="channel-birthday-message-settings">
          <AccordionSummary expandIcon={<ArrowDownIcon />}>
            <Typography>
              {translate('dialogs.channelSettings.autoReplies.birthdayMessage.title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ChannelBirthdayMessageSettings
              channelId={channelId} disabled={!canEditChannel}
            />
          </AccordionDetails>
        </Accordion>
        {channelChatBotUser && (
          <Accordion elevation={2} name="channel-chatBot-settings">
            <AccordionSummary expandIcon={<ArrowDownIcon />}>
              <Typography>
                {translate('dialogs.channelSettings.autoReplies.marketingOptIn.title')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ChannelChatBotSettings
                channelChatBotUser={channelChatBotUser}
                channelId={channelId}
                disabled={!(canEditChannel && canEditChannelChatbotUsers)}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </AccordionWrapper>
    </>
  )
}

const ChannelBirthdayMessageSettings: FC<HasChannelId & HasDisabled> = ({
  channelId, disabled,
}) => {
  const styles = useStyles()
  const translate = useTranslate()
  const validate = ({birthdayMessageTemplateId, isBirthdayMessageEnabled}) => {
    if (!isBirthdayMessageEnabled) return {}
    return {
      birthdayMessageTemplateId: birthdayMessageTemplateId ?
        undefined :
        translate(
          'dialogs.channelSettings.autoReplies.birthdayMessage.missingTemplateError'
        ),
    }
  }
  return (
    <ExtendedEdit
      basePath="/channels"
      id={channelId}
      resource="channels"
    >
      <SimpleForm redirect={false} toolbar={<></>} validate={validate}>
        <EditFormAutoSave shouldValidateOnChange />
        <Box className={styles.autoreplyToggle}>
          <BooleanInput
            disabled={disabled}
            label={translate(
              'dialogs.channelSettings.autoReplies.birthdayMessage.toggleLabel'
            )}
            onChange={() =>
              trackEvent('TOGGLE_AUTO_BIRTHDAY_MESSAGE', EventCategory.CHANNEL)
            }
            source="isBirthdayMessageEnabled"
          />
        </Box>
        <ReferenceInput
          filter={{
            'buttonUrl@_is_null': true,
            hasFreeTextPlaceholders: false,
            isDeleted: false,
            isListed: true,
            status: 'APPROVED',
            type: 'UTILITY',
          }}
          reference="chat_message_templates"
          source="birthdayMessageTemplateId"
        >
          <SelectInput
            disabled={disabled}
            optionText="name"
            optionValue="id"
          />
        </ReferenceInput>
        <ReferenceField
          addLabel={false}
          reference="chat_message_templates"
          source="birthdayMessageTemplateId"
        >
          <ChatMessageTemplateField />
        </ReferenceField>
      </SimpleForm>
    </ExtendedEdit>
  )
}

const ChannelWorkingHoursPanel: FC<HasChannelId> = ({channelId}) => {
  const styles = useStyles()
  const translate = useTranslate()
  const canEditChannelWorkingHours = useHasPermission('edit', 'channel_working_hours')
  const {
    data: {channel_working_hours: channelWorkingHours = []} = {},
    loading: isFetchingChannelWorkingHours,
  } = useQuery<QueryRoot['channel_working_hours']>(
    CHANNEL_WORKING_HOURS_QUERY, {skip: !channelId, variables: {channelId}}
  )
  if (isFetchingChannelWorkingHours) return <CircularProgress size={20} />
  return (
    <div className={styles.channelWorkingHoursPanelRoot}>
      {!canEditChannelWorkingHours && (
        <ExtendedAlert className={styles.alert} severity="warning">
          {translate('dialogs.channelSettings.workingHours.forbidden')}
        </ExtendedAlert>
      )
      }
      <Typography variant="subtitle2">
        {translate('dialogs.channelSettings.workingHours.title')}
      </Typography>
      <Typography color="textSecondary" variant="body2">
        {translate('dialogs.channelSettings.workingHours.subtitle')}
      </Typography>
      {channelWorkingHours.map(c => (
        <ExtendedEdit
          basePath="/"
          className={styles.channelWorkingHoursEdit}
          id={c.id}
          key={c.id}
          resource="channel_working_hours"
        >
          <SimpleForm redirect={false} toolbar={<></>}>
            <EditFormAutoSave shouldValidateOnChange />
            {/* @ts-ignore */}
            <WorkingHoursInputs disabled={!canEditChannelWorkingHours} />
          </SimpleForm>
        </ExtendedEdit>
      ))}
    </div>
  )
}

const WorkingHoursInputs: FC<WorkingHoursInputsProps> = ({disabled, ...props}) => {
  const styles = useStyles()
  const [isOpen, setIsOpen] = useState(props.record.isOpen)
  const translate = useTranslate()
  const isDesktop = useIsDesktop()
  const daysOfTheWeek = [
    'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday',
  ].map(d => ([
    translate(isDesktop ? `weekdays.full.${d}` : `weekdays.shortened.${d}`),
  ]))
  const validateOpeningTime: ValidationFunction = (value: string, {closingTime} = {}) => {
    if (value > closingTime) return translate('validations.time')
  }
  const validateClosingTime: ValidationFunction = (value: string, {openingTime} = {}) => {
    if (value < openingTime) return translate('validations.time')
  }
  return (
    <Grid
      alignItems="center"
      className={styles.workingHoursInputs}
      container
    >
      <Grid item xs={2}>
        <Typography variant="h5">{daysOfTheWeek[props.record.day]}</Typography>
      </Grid>
      <Grid item xs={2}>
        <BooleanInput
          {...props}
          disabled={disabled}
          label=""
          onChange={isOpen => {
            trackEvent('TOGGLE_OPENING_HOURS', EventCategory.CHANNEL)
            setIsOpen(isOpen)
          }}
          source="isOpen"
        />
      </Grid>
      <Grid item style={isOpen ? {display: 'none'} : {}} xs={8}>
        <Typography align="center" variant="body2">
          {translate('dialogs.channelSettings.workingHours.closed')}
        </Typography>
      </Grid>
      <Grid item style={isOpen ? {} : {display: 'none'}} xs={4}>
        <TimeInput
          {...props}
          InputProps={{required: true}}
          className={styles.timeInput}
          disabled={disabled}
          label={translate('dialogs.channelSettings.workingHours.from')}
          source="openingTime"
          validate={validateOpeningTime}
        />
      </Grid>
      <Grid item style={isOpen ? {} : {display: 'none'}} xs={4}>
        <TimeInput
          {...props}
          InputProps={{required: true}}
          className={styles.timeInput}
          disabled={disabled}
          label={translate('dialogs.channelSettings.workingHours.to')}
          source="closingTime"
          validate={validateClosingTime}
        />
      </Grid>
    </Grid>
  )
}

const GeneralSettingsPanel: FC<HasChannelId> = ({channelId}) => (
  <>
    <WhatsappAccountDisconnectionAlert channelId={channelId} />
    <ChannelEdit channelId={channelId} />
    {/*
     TODO: Enable on mobile once NSOSStatusErrorDomain error on downloading QR code blob
     https://github.com/ionic-team/capacitor/issues/5478
     */}
    {!isNativeMobileApp && <ChannelWhatsappQrCode channelId={channelId} />}
    <Typography variant="subtitle2">{
      useTranslate()('dialogs.channelSettings.general.membersListTitle')
    }</Typography>
    <ChannelMerchantUsersShow channelId={channelId} />
  </>
)

const ChannelWhatsappAccountBusinessProfileEditPanel: FC<HasChannelId> = ({
  channelId,
}) => {
  const {data: {whatsappAccountId} = {}} = useGetOne<Channels>('channels', channelId)
  return (
    <WhatsappAccountBusinessProfileEdit id={whatsappAccountId} />
  )
}

const WhatsappChatImportPanel: FC<HasChannelId> = ({channelId}) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const [textFileMerchantUserName, setTextFileMerchantUserName] = useState<string>()
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string>()
  const [file, setFile] = useState<FileAttachment>()
  const [
    importWhatsappChat, {loading: isLoading},
  ] = useMutation<MutationRoot['importWhatsappChat']>(
    IMPORT_WHATSAPP_CHAT_MUTATION,
    {
      onCompleted: ({importWhatsappChat: {id} = {}}) => {
        notify("Chat erfolgreich aus WhatsApp importiert", 'success')
        redirect(`/inbox/${channelId}/${id}`)
      },
      onError: e => notify(e.message, 'error'),
    }
  )
  const submit = useCallback<FormEventHandler<HTMLFormElement>>(
    e => {
      e.preventDefault()
      if (isLoading) return
      if (!(/^\+\d+$/).test(customerPhoneNumber ?? '')) {
        return notify("Ungültige Telefonnummer des Kunden", 'error')
      }
      importWhatsappChat({
        variables: {
          channelId,
          customerPhoneNumber,
          textFileContentHex: file?.encodedContent,
          textFileMerchantUserName,
        },
      })
    },
    [
      channelId,
      textFileMerchantUserName,
      customerPhoneNumber,
      file?.encodedContent,
      importWhatsappChat,
      isLoading,
      notify,
    ]
  )
  return (
    <form className={useStyles().chatImportPanel} onSubmit={submit}>
      <Typography color="textSecondary" variant="body1">
        Es werden nur WhatsApp-Chats unterstützt, die von einem deutsch-sprachigen
        iOS-Gerät exportiert wurden.
      </Typography>
      <Box>
        <FileUploadButton
          color="secondary"
          encoding="hex"
          loading={false}
          mimeTypes="text/plain"
          onSelectFiles={([f]) => setFile(f)}
          variant="outlined"
        >
          WhatsApp Export-Datei auswählen
        </FileUploadButton>
        <Typography variant="body2">{file?.filename}</Typography>
      </Box>
      <TextField
        label="WhatsApp-Telefonnummer des Kunden"
        onChange={e => setCustomerPhoneNumber(e.target.value)}
        required
        value={customerPhoneNumber}
        variant="filled"
      />
      <TextField
        label="Chat file merchant user name"
        onChange={e => setTextFileMerchantUserName(e.target.value)}
        required
        value={textFileMerchantUserName}
        variant="filled"
      />
      <Button color="primary" disabled={isLoading} type="submit" variant="contained">
        Importieren
      </Button>
    </form>
  )
}

const WhatsappAccountDisconnectionAlert: FC<HasChannelId> = ({channelId}) => {
  const translate = useTranslate()
  const {data: {whatsappAccountId} = {}} = useGetOne<Channels>('channels', channelId)
  const {
    data: {
      getWhatsappAccountConnection: {
        status, webhookHostname,
      } = {} as WhatsappAccountConnection,
    } = {},
    error: whatsappAccountConnectionError,
    loading: isLoadingWhatsappAccountConnection,
  } = useQuery<QueryRoot['getWhatsappAccountConnection']>(
    GET_WHATSAPP_ACCOUNT_CONNECTION_QUERY,
    {skip: !whatsappAccountId, variables: {id: whatsappAccountId}},
  )
  if (
    isLoadingWhatsappAccountConnection ||
    whatsappAccountConnectionError ||
    (status === 'CONNECTED')
  ) return null
  return (
    <ExtendedAlert severity="warning">
      <Typography variant="caption">
        {translate(
          'dialogs.channelSettings.general.whatsappAccountDisconnectionAlert.message'
        )}
        {(
          (window.location.hostname === 'localhost') ||
          window.location.hostname.endsWith('.review.flinkit.de')
        ) && <p>
          {translate(
            'dialogs.channelSettings.general.whatsappAccountDisconnectionAlert.' +
            'currentConnection',
            {webhookHostname}
          )}
        </p>}
      </Typography>
    </ExtendedAlert>
  )
}

const ChannelWhatsappQrCode: FC<{channelId: string}> = ({channelId}) => {
  const styles = useStyles()
  const translate = useTranslate()
  const qrCodeRef = useRef<any>(null)
  const imageLinkRef = useRef<HTMLAnchorElement>(null)
  const {
    data: {whatsapp_accounts: [whatsappAccount] = []} = {},
    loading: isLoadingWhatsappAccount,
  } = useQuery<WhatsappAccounts[]>(
    WHATSAPP_ACCOUNTS_QUERY, {skip: !channelId, variables: {channelId}}
  )
  const whatsappUrl = `https://wa.me/${encodeURIComponent(whatsappAccount?.phoneNumber)}`
  const downloadQrCodeImage = useCallback(() => {
    const canvasRef = qrCodeRef?.current?.canvas
    const canvas = canvasRef?.current
    // @ts-ignore we are sure the ref is attached to the hidden `a` element
    imageLinkRef.current.href = canvas?.toDataURL()
    // @ts-ignore ditto
    imageLinkRef.current.download = whatsappAccount?.phoneNumber
    imageLinkRef.current?.click()
  }, [whatsappAccount])
  const shareViaEmail = useCallback(() => {
    const emailBody = translate(
      'dialogs.channelSettings.general.qrCode.shareViaEmailBody',
      {whatsappUrl},
    )
    window.open(`mailto:?body=${emailBody}`)
  }, [translate, whatsappUrl])
  if (isLoadingWhatsappAccount) return <CircularProgress size={20} />
  return (
    <div className={styles.channelWhatsappQrCodeRoot}>
      <Typography variant="subtitle2">
        {translate('dialogs.channelSettings.general.qrCode.title')}
      </Typography>
      <Grid container>
        <Grid item>
          <QRCode
            bgColor={theme.palette.background.paper}
            fgColor={theme.palette.primary.main}
            logoImage="../../assets/imgs/qrCodeLogo.png"
            ref={qrCodeRef}
            removeQrCodeBehindLogo
            size={150}
            value={whatsappUrl}
          />
          <a hidden href="" ref={imageLinkRef} />
        </Grid>
        <Grid item justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">
              {translate('dialogs.channelSettings.general.qrCode.info')}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color="secondary" onClick={downloadQrCodeImage}>
              <DownloadIcon />
            </IconButton>
            <IconButton color="secondary" onClick={shareViaEmail}>
              <ShareIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const ChannelAbsenceSettings: FC<HasChannelId & HasDisabled> = ({
  channelId,
  disabled,
}) => {
  const styles = useStyles()
  const notify = useNotify()
  const translate = useTranslate()
  const {
    data: {absenceEndTimestamp, isAbsenceAutoreplyEnabled} = {},
    loading,
  } = useGetOne<Channels>('channels', channelId)
  const [mutate] = useUpdate<Channels>(
    'channels',
    channelId,
    {isAbsenceAutoreplyEnabled: false},
  )
  const validate = ({
    absenceEndTimestamp, absenceStartTimestamp, isAbsenceAutoreplyEnabled,
  }) => {
    if (!isAbsenceAutoreplyEnabled) return {}
    const isValidStartTimestamp = isValidDateTimeString(absenceStartTimestamp)
    const isValidEndTimestamp = isValidDateTimeString(absenceEndTimestamp)
    if (!(isValidStartTimestamp && isValidEndTimestamp)) return {
      absenceEndTimestamp: isValidEndTimestamp ?
        undefined :
        translate('dialogs.channelSettings.autoReplies.absence.invalidEndDate'),
      absenceStartTimestamp: isValidStartTimestamp ?
        undefined :
        translate('dialogs.channelSettings.autoReplies.absence.invalidEndDate'),
    }
    return {
      absenceEndTimestamp: isTimestampAfter(absenceEndTimestamp, absenceStartTimestamp) ?
        undefined :
        translate('dialogs.channelSettings.autoReplies.absence.invalidDatesOrder'),
    }
  }
  useEffect(() => {
    if (loading || !absenceEndTimestamp) return
    // Automatically disable absence if the absence duration is expired
    if (isTimestampInPast(absenceEndTimestamp) && isAbsenceAutoreplyEnabled) {
      notify('dialogs.channelSettings.autoReplies.absence.expired')
      mutate()
    }
  }, [absenceEndTimestamp, isAbsenceAutoreplyEnabled, loading, mutate, notify, translate])
  return (
    <ExtendedEdit
      basePath="/"
      id={channelId}
      resource="channels"
    >
      <SimpleForm
        initialValues={{
          absenceAutoreplyText:
            translate('dialogs.channelSettings.autoReplies.absence.initialValue'),
        }}
        redirect={false}
        toolbar={<></>}
        validate={validate}
      >
        <EditFormAutoSave shouldValidateOnChange />
        <Box className={styles.autoreplyToggle}>
          <BooleanInput
            disabled={disabled}
            label={translate('dialogs.channelSettings.autoReplies.absence.toggleLabel')}
            onChange={() => trackEvent('TOGGLE_ABSENCE_AUTOREPLY', EventCategory.CHANNEL)}
            source="isAbsenceAutoreplyEnabled"
          />
        </Box>
        <ChannelAbsenceDateTimeInputs disabled={disabled} />
        <TextInput
          disabled={disabled}
          label={translate('dialogs.channelSettings.autoReplies.absence.textInputLabel')}
          multiline
          rows={5}
          source="absenceAutoreplyText"
        />
      </SimpleForm>
    </ExtendedEdit>
  )
}

const ChannelAbsenceDateTimeInputs: FC<Partial<HasChannelId> & Record<string, any>> = ({
  disabled = false, ...props
}) => {
  const styles = useStyles()
  const {values: {absenceEndTimestamp, absenceStartTimestamp}} = useFormState()
  const {endDateInputRef, startDateInputRef} = useDateRangeInputValidation({
    endTimestamp: absenceEndTimestamp, startTimestamp: absenceStartTimestamp,
  })
  return (
    <Grid className={styles.channelAbsenceDateTimeInputsRoot} container>
      <Grid item>
        <DateTimeInput
          {...props}
          disabled={disabled}
          inputRef={startDateInputRef}
          label={useTranslate()('dialogs.channelSettings.autoReplies.absence.startDate')}
          source="absenceStartTimestamp"
        />
      </Grid>
      <Grid item>
        <DateTimeInput
          {...props}
          disabled={disabled}
          inputRef={endDateInputRef}
          label={useTranslate()('dialogs.channelSettings.autoReplies.absence.endDate')}
          source="absenceEndTimestamp"
        />
      </Grid>
    </Grid>
  )
}

const ChannelWelcomeSettings = ({channelId, disabled}) => {
  const styles = useStyles()
  return (
    <ExtendedEdit
      basePath="/channels"
      id={channelId}
      resource="channels"
    >
      <SimpleForm
        initialValues={{
          welcomeAutoreplyText: useTranslate()(
            'dialogs.channelSettings.autoReplies.welcomeMessage.initialValue'
          ),
        }}
        redirect={false}
        toolbar={<></>}
      >
        <EditFormAutoSave />
        <Box className={styles.autoreplyToggle}>
          <BooleanInput
            disabled={disabled}
            label={useTranslate()(
              'dialogs.channelSettings.autoReplies.welcomeMessage.toggleLabel'
            )}
            onChange={() => trackEvent('TOGGLE_WELCOME_AUTOREPLY', EventCategory.CHANNEL)}
            source="isWelcomeAutoreplyEnabled"
          />
        </Box>
        <TextInput
          disabled={disabled}
          label={useTranslate()(
            'dialogs.channelSettings.autoReplies.welcomeMessage.textInputLabel'
          )}
          multiline
          rows={5}
          source="welcomeAutoreplyText"
          validate={required()}
        />
      </SimpleForm>
    </ExtendedEdit>
  )
}

const ChannelChatBotSettings: FC<ChannelChatBotSettingsProps> = ({
  channelChatBotUser, channelId, disabled,
}) => {
  const styles = useStyles()
  return (
    <ExtendedEdit basePath="/" id={channelId} resource="channels">
      <SimpleForm redirect={false} toolbar={<></>}>
        <EditFormAutoSave />
        <Box className={styles.autoreplyToggle}>
          <BooleanInput
            disabled={disabled}
            label={useTranslate()(
              'dialogs.channelSettings.autoReplies.marketingOptIn.toggleLabel'
            )}
            onChange={() =>
              trackEvent('TOGGLE_MARKETING_OPT_IN_AUTOREPLY', EventCategory.CHANNEL)
            }
            source="isMarketingOptInAutoreplyEnabled"
          />
        </Box>
        {/* Nesting the chat bot edit component here b/c its logically
              and visually part of the outer edit form
          */}
        <ChannelChatBotUserEdit
          disabled={disabled}
          id={channelChatBotUser.id}
        />
      </SimpleForm>
    </ExtendedEdit>
  )
}

const ChannelWorkingHoursSettings: FC<HasChannelId & HasDisabled> = ({
  channelId, disabled,
}) => {
  const styles = useStyles()
  return (
    <ExtendedEdit
      basePath="/"
      id={channelId}
      resource="channels"
    >
      <SimpleForm
        initialValues={{
          outsideWorkingHoursAutoreplyText: useTranslate()(
            'dialogs.channelSettings.autoReplies.outsideWorkingHours.initialValue'
          ),
        }}
        redirect={false}
        toolbar={<></>}
      >
        <EditFormAutoSave />
        <Box className={styles.autoreplyToggle}>
          <BooleanInput
            disabled={disabled}
            label={useTranslate()(
              'dialogs.channelSettings.autoReplies.outsideWorkingHours.toggleLabel'
            )}
            onChange={() =>
              trackEvent('TOGGLE_OUTSIDE_WORKING_HOURS_AUTOREPLY', EventCategory.CHANNEL)
            }
            source="isOutsideWorkingHoursAutoreplyEnabled"
          />
        </Box>
        <TextInput
          disabled={disabled}
          label={useTranslate()(
            'dialogs.channelSettings.autoReplies.outsideWorkingHours.textInputLabel'
          )}
          multiline
          rows={5}
          source="outsideWorkingHoursAutoreplyText"
          validate={required()}
        />
      </SimpleForm>
    </ExtendedEdit>
  )
}

const useDateRangeInputValidation = ({
  endTimestamp, startTimestamp,
}: UseDateRangeInputValidationProps) => {
  const startDateInputRef = useRef<{max: string, min: string}>()
  const endDateInputRef = useRef<{max: string, min: string}>()
  useEffect(() => {
    if (!endTimestamp || !isValidDateTimeString(endTimestamp)) return
    startDateInputRef.current &&
      (startDateInputRef.current.max = new Date(endTimestamp).toISOString().slice(0, 16))
  }, [endTimestamp])
  useEffect(() => {
    if (!startTimestamp || !isValidDateTimeString(startTimestamp)) return
    endDateInputRef.current &&
      (endDateInputRef.current.min = new Date(startTimestamp).toISOString().slice(0, 16))
  }, [startTimestamp])
  return {endDateInputRef, startDateInputRef}
}

const useGetSettingsTabLabelAndComponents = () => {
  const {identity: {isImpersonatedUser} = {}} = useGetIdentity()
  const translate = useTranslate()
  return useMemo<(string | boolean | any)[][]>(() => ([
    [translate('dialogs.channelSettings.general.title'), GeneralSettingsPanel, true],
    [
      translate('dialogs.channelSettings.workingHours.title'),
      ChannelWorkingHoursPanel,
      true,
    ],
    [translate('dialogs.channelSettings.autoReplies.title'), AutoRepliesTabPanel, true],
    [
      translate('dialogs.channelSettings.whatsappAccountBusinessProfile.title'),
      ChannelWhatsappAccountBusinessProfileEditPanel,
      true,
    ],
    ["WhatsApp-Import", WhatsappChatImportPanel, isImpersonatedUser],
  ].filter(([,, isVisible]) => isVisible)), [isImpersonatedUser, translate])
}

const useStyles = makeStyles(theme => ({
  alert: {
    '& .MuiAlert-message': {
      paddingBottom: theme.typography.pxToRem(10),
      paddingTop: theme.typography.pxToRem(12),
    },
  },
  autoreplyToggle: {
    '& .Mui-error': {
      [theme.breakpoints.down('md')]: {
        top: theme.remSpacing(3.5),
      },
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      position: 'absolute',
      right: theme.remSpacing(1),
      top: theme.remSpacing(.5),
    },
    '& .MuiCardContent-root': {
      padding: 0,
    },
    '& .MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        ...theme.typography.body1,
        color: theme.palette.text.secondary,
      },
      // moves label to left
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      paddingLeft: theme.typography.pxToRem(20),
    },
    '& .MuiFormGroup-root': {
      margin: 0,
    },
    backgroundColor: 'transparent',
    boxShadow: 'none',
    paddingTop: '0 !important',
  },
  channelAbsenceDateTimeInputsRoot: {
    '& .MuiGrid-item': {
      flex: 1,
    },
    '& .MuiTypography-root': {
      paddingBottom: theme.remSpacing(1),
      paddingLeft: theme.remSpacing(3),
    },
    '& input': {
      cursor: 'pointer',
    },
    marginTop: theme.remSpacing(.5),
  },
  channelWhatsappQrCodeRoot: {
    '& .MuiGrid-justify-content-xs-space-between': {
      paddingLeft: 0,
    },
    '& .MuiIconButton-root': {
      [theme.breakpoints.down('md')]: {
        marginTop: theme.typography.pxToRem(2),
      },
      marginBottom: theme.typography.pxToRem(12),
      marginRight: theme.typography.pxToRem(12),
      marginTop: theme.typography.pxToRem(24),
    },
    '& .MuiTypography-root': {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.typography.pxToRem(13),
      },
      marginBottom: theme.typography.pxToRem(16),
      width: theme.typography.pxToRem(320),
    },
    '& canvas': {
      marginBottom: theme.typography.pxToRem(12),
    },
    marginBottom: theme.typography.pxToRem(10),
  },
  channelWorkingHoursEdit: {
    '& .MuiCardContent-root': {
      paddingBottom: 0,
      paddingTop: 0,
    },
    '& .MuiFilledInput-root': {
      paddingTop: theme.typography.pxToRem(10),
    },
    marginBottom: '0 !important',
  },
  channelWorkingHoursPanelRoot: {
    '& .MuiAlert-root': {
      marginBottom: theme.remSpacing(3),
    },
  },
  chatImportPanel: {
    '& .MuiButton-root': {
      marginLeft: 0,
      marginRight: 0,
    },
    '& .MuiTextField-root': {
      marginBottom: theme.remSpacing(1),
      marginTop: theme.remSpacing(1),
    },
  },
  dialogContent: {
    [theme.breakpoints.down('md')]: {
      '&': {
        paddingTop: theme.remSpacing(4),
      },
      '& .MuiBox-root': {
        '& .edit-page:not(:last-child)': {
          marginBottom: theme.remSpacing(5),
        },
      },
      '& .MuiCardContent-root': {
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    overflowX: 'hidden',
    paddingBottom: 0,
    paddingTop: 0,
  },
  isAbsenceHintBoxVisible: {
    display: 'block',
  },
  root: {
    [theme.breakpoints.up('lg')]: {
      '& .MuiDialog-paper': {
        position: 'fixed',
        top: theme.typography.pxToRem(5),
      },
    },
    '& .MuiDialog-paper': {
      height: '100%',
    },
  },
  timeInput: {
    '& .MuiFormHelperText-root': {
      marginTop: theme.typography.pxToRem(30),
      paddingTop: theme.typography.pxToRem(30),
      position: 'absolute',
      top: theme.remSpacing(1.25),
    },
    '&.MuiFormControl-root.MuiTextField-root': {
      width: '100%',
    },
  },
  workingHoursInputs: {
    '& .MuiFormControl-root': {
      marginTop: 0,
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.typography.pxToRem(12),
        width: theme.typography.pxToRem(80),
      },
    },
    '& .MuiFormControlLabel-root': {
      '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.caption.fontSize,
      },
      display: 'block',
      fontSize: theme.typography.caption.fontSize,
    },
    '& .MuiFormGroup-root': {
      [theme.breakpoints.down('sm')]: {
        '& .MuiTypography-root': {
          marginLeft: 0,
        },
        marginTop: theme.typography.pxToRem(33),
        width: theme.typography.pxToRem(20),
      },
      display: 'flex',
      marginBottom: 0,
      marginTop: theme.typography.pxToRem(30),
      width: theme.typography.pxToRem(75),
    },
    '& .MuiGrid-item': {
      paddingLeft: 0,
    },
    '& .MuiTypography-root': {
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
      marginRight: theme.remSpacing(1.5),
      marginTop: theme.typography.pxToRem(10),
      minWidth: theme.typography.pxToRem(75),
    },
    '& input': {
      cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: {
      height: theme.typography.pxToRem(90),
    },
    marginLeft: 0,
  },
}))

const CHANNEL_CHAT_BOT_USER_QUERY = gql`query ($channelId: uuid!) {
  channel_chat_bot_users(where: {channelId: {_eq: $channelId}}) {id}
}`

const CHANNEL_WORKING_HOURS_QUERY = gql`
  query($channelId: uuid!) {
    channel_working_hours(where: {channelId: {_eq: $channelId}}, order_by: {day: asc}) {
      closingTime
      day
      id
      isOpen
      openingTime
    }
  }
`

const GET_WHATSAPP_ACCOUNT_CONNECTION_QUERY = gql`query($id: uuid!) {
  getWhatsappAccountConnection(whatsappAccountId: $id){status webhookHostname}
}`

const IMPORT_WHATSAPP_CHAT_MUTATION = gql`
  mutation(
    $channelId: uuid!,
    $customerPhoneNumber: String!,
    $textFileContentHex: String!,
    $textFileMerchantUserName: String!,
  ){
    importWhatsappChat(
      channelId: $channelId,
      customerPhoneNumber: $customerPhoneNumber,
      textFileContentHex: $textFileContentHex,
      textFileMerchantUserName: $textFileMerchantUserName
    ){id}
  }
`

const WHATSAPP_ACCOUNTS_QUERY = gql`
  query($channelId: uuid) {
    whatsapp_accounts(where: {channel: {id: {_eq: $channelId}}}){id phoneNumber}
  }
`

interface ChannelSettingsDialogProps {
  channelId: string
  onClose: VoidFunction
  open?: boolean
}

interface HasChannelId {
  channelId: string
}

interface HasDisabled {
  disabled?: boolean
}

interface Alert {
  id: string
  severity: Color
  text: string|JSX.Element
}

interface WorkingHoursInputsProps extends HasDisabled {
  [k: string]: any
  record: ChannelWorkingHours
}

interface ChannelChatBotSettingsProps extends HasChannelId, HasDisabled {
  channelChatBotUser: ChannelChatBotUsers
}

interface UseDateRangeInputValidationProps {
  endTimestamp: string
  startTimestamp: string
}

export default ChannelSettingsDialog
