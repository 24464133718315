import {MutationFunctionOptions} from '@apollo/react-common'
import {Chip, DialogContentText, Grid, List} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import _ from 'lodash'
import {FC, useMemo, useState} from 'react'
import {useTranslate} from 'react-admin'

import {useCompileChatMessageText} from '../../../hooks/useCompileChatMessageText'
import useSimplifyAge from '../../../hooks/useSimplifyAge'
import {ChatMessages} from '../../../types/graphqlSchema'
import {EventCategory, trackEvent} from '../../../utils/tracking'
import uuidv4 from '../../../utils/uuid'
import StartChatDialog from '../StartChatDialog'
import ChatMessageListItem from './ChatMessageListItem'

const ChatMessageList: FC<ChatMessageListProps> = ({
  chatMessages = [],
  isForwardingChatMessage,
  isShowingTranslations,
  onForwardChatMessage,
}) => {
  const translate = useTranslate()
  const styles = useStyles()
  const simplifyAge = useSimplifyAge()
  const {compileChatMessageText} = useCompileChatMessageText()
  const [selectedChatMessageId, setSelectedChatMessageId] = useState<string>()
  const [isForwardingDialogOpen, setIsForwardingDialogOpen] = useState(false)
  const groupNameToChatMessages = useMemo(
    () => _.groupBy(
      [...chatMessages].reverse(),
      c => simplifyAge(c.insertionTimestamp as string, {canShowToday: true})
    ), [chatMessages, simplifyAge]
  )
  const selectedChatMessage = useMemo(
    () => chatMessages.find(({id}) => id === selectedChatMessageId),
    [chatMessages, selectedChatMessageId]
  )
  const chatMessageListItems = useMemo(() => _.sortBy(
    Object.entries(groupNameToChatMessages), ([groupName]) => !groupName
  )
    .flatMap(([groupName, chatMessages]) => [
      <Grid
        className={styles.chatMessageGroupGrid}
        container
        justifyContent="center"
        key={groupName || 'Ausstehend'}
      >
        <Grid item>
          <Chip
            className={styles.chatMessageGroupChip}
            label={groupName || translate('statuses.pending')}
          />
        </Grid>
      </Grid>,
      chatMessages.map(c => (
        <ChatMessageListItem
          isShowingTranslations={isShowingTranslations}
          key={c.id}
          onForward={() => {
            setSelectedChatMessageId(c.id)
            setIsForwardingDialogOpen(true)
          }}
          value={c}
        />
      )),
    ]), [groupNameToChatMessages, styles, isShowingTranslations, translate]
  )
  return (
    <List className={styles.root}>
      {chatMessageListItems}
      <StartChatDialog
        actionButtonText={translate('actions.send')}
        hasInternalChats
        isChatNameEditable={false}
        loading={isForwardingChatMessage}
        onClose={() => setIsForwardingDialogOpen(false)}
        onStartChat={({userIds}) => {
          const forwardingResult = onForwardChatMessage({
            variables: {
              chat: {userIds},
              forwardedChatMessageId: selectedChatMessage?.id,
              id: uuidv4(),
              type: 'FORWARD',
            },
          })
          trackEvent('FORWARD_MESSAGE', EventCategory.CHAT)
          return forwardingResult as any
        }}
        open={isForwardingDialogOpen}
        title={translate('chat.chatMessageList.index.forward')}
      >
        <Grid className={styles.innerDialogContent}>
          <DialogContentText>
            {selectedChatMessage?.authorUserDisplayName}
          </DialogContentText>
          <DialogContentText>{
            compileChatMessageText(selectedChatMessage) ??
            translate('chat.chatMessageList.index.noText')
          }</DialogContentText>
        </Grid>
      </StartChatDialog>
    </List>
  )
}

const useStyles = makeStyles(theme => ({
  chatMessageGroupChip: {
    borderRadius: '6px',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    margin: '10px 0',
    minWidth: '13ch',
  },
  chatMessageGroupGrid: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  forwardedChatMessageItem: {
    background: '#EEEEEE !important',
  },
  innerDialogContent: {
    '& .MuiDialogContentText-root': {
      fontStyle: 'italic',
      marginLeft: '10px',
    },
    '& .MuiDialogContentText-root:first-child': {
      fontWeight: '900 !important',
    },
    borderLeft: 'solid 5px #eee',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

interface ChatMessageListProps {
  chatMessages?: ChatMessages[]
  isForwardingChatMessage?: boolean
  isShowingTranslations?: boolean
  onForwardChatMessage: (
    options?: MutationFunctionOptions<ChatMessages, any>
  ) => Promise<void>
}

export default ChatMessageList
