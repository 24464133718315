import {FC, ReactElement} from 'react'
import {Edit, EditProps, useNotify} from 'react-admin'

const ExtendedEdit: FC<EditProps & {children: ReactElement}> = ({children, ...props}) => {
  const notify = useNotify()
  return (
    <Edit
      // We use pessimistic mutation mode to remove the ability to undo the changes.
      // Saving doesn't work otherwise.
      // reference: https://marmelab.com/react-admin/doc/3.17/CreateEdit.html#mutationmode
      mutationMode="pessimistic"
      onSuccess={() => {
        notify('ra.notification.updated', 'info', {smart_count: 1}, false)
      }}
      {...props}
    >
      {children}
    </Edit>
  )
}

export default ExtendedEdit
