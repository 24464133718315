import BrowserTabsLock from 'browser-tabs-lock'
import {useEffect, useState} from 'react'
import {AuthProvider} from 'react-admin'

import {
  MutationRootIssueOauthTokensArgs, MutationRootSignupArgs, OauthTokens,
} from '../types/graphqlSchema'
import fetchGraphQL from './fetchGraphQL'
import parseJwt from './jwt'

const LOGIN_PATH = '/login'

const Lock = new BrowserTabsLock()

const authProvider: AuthProvider = {
  /*
   * The following properties implement react-admin's auth provider interface:
   * https://marmelab.com/react-admin/Authentication.html#building-your-own-auth-provider
   */
  checkAuth: async () => {
    await authProvider.getToken()
  },
  checkError: async error => {
    const requireLogin = () => {
      const invalidTokenError = new Error()
      // @ts-ignore  Property 'redirectTo' does not exist on type 'Error'.
      invalidTokenError.redirectTo = LOGIN_PATH
      throw invalidTokenError
    }
    if (error.message === 'UNAUTHORIZED') {
      requireLogin()
    }
    if (error.message.includes("invalid refresh token")) {
      deleteOauthTokens()
      requireLogin()
    }
    if (error.graphQLErrors?.find?.(e => e.extensions?.code === 'invalid-jwt')) {
      // Error is part of a GraphQL API response
      requireLogin()
    }
  },
  getIdentity: async () => {
    const token = await authProvider.getToken()
    try {
      const {emailAddress, impersonatingUserId, merchantId, merchantUserId, userId} =
        parseJwt(token)['https://api-v2.flinkit.de']
      return {
        avatar: undefined,
        emailAddress,
        fullName: undefined,
        id: userId,
        isImpersonatedUser: !!impersonatingUserId,
        merchantId,
        merchantUserId,
      }
    }
    catch (e) {
      // react-admin silently swallows any exception, hence we log (and re-raise) it here
      console.error(e)
      throw e
    }
  },
  getPermissions: async () => {
    const token = await authProvider.getToken()
    try {
      const {
        allowedRoles = ['merchant'], channelAdminChannelIds = [],
      } = parseJwt(token)?.['https://api-v2.flinkit.de']
      return {allowedRoles, channelAdminChannelIds}
    }
    catch (e) {
      console.error(e)
      throw e
    }
  },
  login: async ({emailAddress, password}) => {
    const tokens = await issueOauthTokens({emailAddress, password})
    storeOauthTokens(tokens)
  },
  logout: async () => {
    deleteOauthTokens()
  },
  /*
   * The following properties are our custom extensions to the auth provider interface
   */
  /* eslint-disable sort-keys */
  /* eslint-disable sort-keys-fix/sort-keys-fix */
  getToken: async () => {
    let tokens = retrieveOauthTokens()
    if (!tokens.hasExpired) return tokens.accessToken
    try {
      // Acquire lock so that refreshing tokens is only done once.
      if (await tryAcquireLock()) {
        // Tokens might have been refreshed during another function call; retrieve tokens
        // again and check if it hasn't been replaced yet.
        tokens = retrieveOauthTokens()
        if (!tokens.hasExpired) return tokens.accessToken
        const newTokens = await refreshOauthTokens(tokens.refreshToken)
        storeOauthTokens(newTokens)
        return newTokens.accessToken
      }
      // Not sure how to handle failure when acquiring locks....throw this error for now.
      throw new Error('FAILED_TO_ACQUIRE_REFRESH_TOKEN_LOCK')
    }
    finally {
      await Lock.releaseLock(REFRESH_TOKEN_LOCK_KEY)
    }
  },
  signup: async (args: MutationRootSignupArgs) => {
    const tokens = await signup(args)
    storeOauthTokens(tokens)
  },
  /* eslint-enable */
  /* eslint-enable */
}

// Hook that determines whether there's a valid authenticated user session. Unlike
// react-admin's `useCheckAuth()` this hook can be used outside of an `<Admin />` context
// which makes it easier to render login pages without spinning up the complete
// react-admin scaffolding which can be, depending on the app setup, a potentially
// expensive operation. Some apps might even decide to only render an `<Admin />`
// component if there's a valid user session which would disallow using react-admin's
// `useCheckAuth()` hook as it needs a react-admin context.
// Note: This hook only performs the authentication check once. This works fine as the
//       authentication flow uses location redirects so that the hook will re-run each
//       time the app loads again.
const useAuthCheck = () => {
  const [isCheckingAuth, setIsCheckingAuth] = useState(true)
  const [isAuthenticated, setAuthenticated] = useState(false)
  useEffect(() => {
    const checkAuth = async () => {
      try {
        await authProvider.checkAuth({})
        setAuthenticated(true)
      }
      catch {}
      setIsCheckingAuth(false)
    }
    checkAuth()
  }, [])
  return {isAuthenticated, isCheckingAuth}
}

const issueOauthTokens = async (
  variables: MutationRootIssueOauthTokensArgs,
): Promise<OauthTokens> => {
  const {issueOauthTokens: tokens} = await fetchGraphQL<{issueOauthTokens: OauthTokens}>({
    query: `
      mutation($emailAddress: String!, $password: String!){
        issueOauthTokens(emailAddress: $emailAddress, password: $password) {
          accessToken
          expiration
          refreshToken
        }
      }
    `,
    variables,
  })
  return tokens
}

const refreshOauthTokens = async (
  refreshToken: string,
): Promise<OauthTokens> => {
  const {
    refreshAccessToken: tokens,
  } = await fetchGraphQL<{refreshAccessToken: OauthTokens}>({
    query: `
      mutation($refreshToken: String!){
        refreshAccessToken(refreshToken: $refreshToken) {
          accessToken
          refreshToken
          expiration
        }
      }
    `,
    variables: {refreshToken},
  })
  return tokens
}

const signup = async (
  variables: MutationRootSignupArgs
): Promise<OauthTokens> => {
  const {signup: tokens} = await fetchGraphQL<{signup: OauthTokens}>({
    query: `
      mutation(
        $emailAddress: String!,
        $firstName: String!,
        $lastName: String!,
        $password: String!
      ){
        signup(
          emailAddress: $emailAddress,
          firstName: $firstName,
          lastName: $lastName,
          password: $password,
        ) {
          accessToken expiration refreshToken
        }
      }
    `,
    variables,
  })
  return tokens
}

const storeOauthTokens = (tokens: OauthTokens) => {
  localStorage.setItem(FLINKIT_LOCAL_STORAGE_KEY, JSON.stringify(tokens))
}

const retrieveOauthTokens = (): Tokens => {
  const tokens = localStorage.getItem(FLINKIT_LOCAL_STORAGE_KEY)
  if (!tokens) {
    throw new Error('UNAUTHORIZED')
  }
  const {accessToken, refreshToken} = JSON.parse(tokens)
  const {exp} = parseJwt(accessToken)
  return {
    accessToken,
    hasExpired: (Date.now() > ((exp - 60) * 1000)),
    refreshToken,
  }
}

const deleteOauthTokens = () => localStorage.removeItem(FLINKIT_LOCAL_STORAGE_KEY)

const tryAcquireLock = async (current = 1, max_tries = 4) => {
  if (await Lock.acquireLock(REFRESH_TOKEN_LOCK_KEY, 10000)) {
    return true
  }
  return current <= max_tries ? await tryAcquireLock(current + 1, max_tries) : false
}

const FLINKIT_LOCAL_STORAGE_KEY = window.btoa('FLINKIT_LOCAL_STORAGE_KEY')

const REFRESH_TOKEN_LOCK_KEY = 'refresh-token-lock-key'

interface Tokens extends Omit<OauthTokens, 'expiration'> {
  hasExpired: boolean,
}

export default authProvider
export {useAuthCheck}
