import {merge} from 'lodash'
import englishMessages from 'ra-language-english'

export default {
  ...merge(englishMessages, {
    ra: {
      action: {
        close: "Close",
        move_down: "nach unten bewegen",
        move_up: "Nach oben bewegen",
        select_all: "Wählen Sie Alle",
        select_row: "Wählen Sie diese Zeile aus",
      },
      input: {
        select: {
          emptyText: 'None',
        },
      },
      message: {
        error: "An unexpected error has occurred",
        invalid_form: "Input error, please check your entries",
      },
      notification: {
        created: "Item deleted successfully",
        item_doesnt_exist: "An unexpected error has occurred",
        updated: "Changes successfully saved",
      },
      page: {
        loading: "Loading Flinkit...",
      },
    },
  }),
  actions: {
    cancel: "Cancel",
    confirm: "Confirm",
    continue: "Continue",
    delete: "Delete",
    discard: "Discard",
    download: "Download",
    logout: "Logout",
    moreInfo: "More info",
    open: "Open",
    preview: "Preview",
    retry: "Retry",
    send: "Send",
    showInactiveFilters: "Filter",
  },
  ages: {
    today: "Today",
    yesterday: "Yesterday",
  },
  browserNotificationsProvider: {
    enableBrowserNotifications: "Enable browser notifications from Flinkit",
    enableBrowserNotificationsButtonText: "Simply click here",
  },
  chat: {
    autoTranslationSettingButton: {
      autoDetection: "Automatic detection and translation of incoming messages.",
      doNotTranslate: "(Do not translate)",
      moreSettings: "Additional settings",
      subtitle: "Select a language into which all outgoing messages should be " +
        "automatically translated for your customers. This setting only applies to the " +
        "current chat.",
      title: "Translation",
    },
    chatAssignment: {
      unassigned: "Unassigned",
    },
    chatList: {
      withoutText: "(Without text)",
    },
    chatMessageBubble: {
      chatMessageFileAttachmentList: {
        chatMessageFileAttachment: {
          downloadAudioMessage: "<a>Download the audio file</a> to play it",
          locationChatMessageFileAttachment: {
            cannotShowGpsPosition: "We're sorry, we can't show the GPS position right " +
              "now",
          },
          transcribedTextWarning: "This message was automatically transcribed:",
        },
      },
      forwardedBy: "Forwarded by",
      receivingErrorNotice: {
        generalMessage: "An unsupported whatsapp message as been sent to you",
      },
      repliedChatMessageBanner: {
        repliedMessageAuthoredByTheMerchantUserReading: "You",
      },
      translationBanner: {
        translatedToDetectedLanguage: "%{language} automatically translated",
        translatedToTranslatedLanguages: "Automatically translated to %{languages}",
      },
      unknownWhatsappMessageTypeText: "The user may have attempted to send a document " +
        "and text in the same message. The document and the text must be sent in two " +
        "separate messages.",
      whatsappMessageStatus: {
        error: {
          couldNotReceiveMessage: "Message could not be received " +
            "(error code: %{errorCode})",
          failedToSend: "Failed to send message (Error code: %{errorCode})",
          failedToSendWithoutErrorCode: "Failed to send message",
          messageCouldNotBeCanceledOrSent: "Message could not be canceled or sent",
        },
      },
    },
    chatMessageList: {
      index: {
        forward: "Forward to team chat",
        noText: "(Without text)",
      },
    },
    external: {
      channelSettingsButtonLabel: "Settings",
      contextMenu: {
        addContact: "Add Contact",
        addFlag: "Mark Chat",
        archive: "Archive Chat",
        editContact: "Edit Contact",
        removeFlag: "Remove Mark",
        spam: {
          confirm: "Are you sure you want to block this number? You can no longer " +
            "receive messages from this number",
          success: "The number has been successfully blocked",
          title: "Block number",
        },
        unarchive: "Reopen Chat",
        unblock: "Unblock number",
      },
      emptyChatNotification: {
        magicTemplate: "When you start a conversation, your message is automatically " +
          "sent as a template. This can take up to 30 minutes.",
        normalTemplate: "To start the conversation, you can send a prepared " +
          "<template>template message</template>.",
      },
      fileAttachment: "File attachment",
      filters: {
        all: "All",
        archived: "Completed",
        mine: "Mine",
        unassigned: "Unassigned",
      },
      header: {
        merchantCustomerUserSubtitle: {
          customerCodePrefix: "Customer No.",
        },
        saveContact: "Save contact",
      },
      templateMessage: "Template message",
      title: "Inbox",
      toolbar: {
        expiredReplyWindowNotification: {
          message: {
            magicTemplate: "You are outside the 24-hour period, so your message " +
              "will be sent automatically as a template. This can take up to 30 minutes.",
            normalTemplate: "The 24-hour reply window has expired. You now have the " +
              "option to send a prepared <template>Template message</template> or one " +
              "<file>File attachment</file> " +
              "message.",
          },
        },
      },
    },
    internal: {
      contextMenu: {
        archive: "Archive Chat",
        renameChat: "Change Chat Name",
        unarchive: "Reopen Chat",
      },
      filters: {
        all: "All",
        archived: "Archived",
      },
      productNewsChatTitle: "Flinkit News 🗞",
      title: "Team chat",
    },
    toolbar: {
      chatMessageTextFieldPlaceholder: "Your Message",
      emojiPickerButton: {
        recent: "Current",
        search: "Search",
        searchResults: "Search results",
        title: "Emojis",
      },
      fileAttachment: {
        draggedFileRejected: "Cannot attach file!",
      },
      translationPopper: {
        translatedInto: "Translated into %{language}%{loadingDots}",
      },
    },
  },
  chat_message_template_placeholder_types: {
    FREE_TEXT: "Free Text",
    MERCHANT_CUSTOMER_USER_COMPANY_NAME: "Company",
    MERCHANT_CUSTOMER_USER_FIRST_NAME: "First Name",
    MERCHANT_CUSTOMER_USER_LAST_NAME: "Last Name",
    MERCHANT_CUSTOMER_USER_RESPONSIBLE_MERCHANT_USER_NAME: "Contact Person",
  },
  chat_messages: {
    receivingErrorCode: {
      UNSUPPORTED_FILE_ATTACHMENT_MIME_TYPE: "This message contains an unsupported " +
        "attached file",
      WHATSAPP_MEDIA_UNDOWNLOADABLE: "This message contains an attached file that " +
        "couldn't be downloaded",
    },
  },
  chatMessageTemplateBuilder: {
    templatePlaceholder: {
      errors: {
        labelRequired: "%{formattedLabel} must be filled out",
        maxLabelLength: "%{formattedLabel} cannot exceed %{maxLength} characters",
      },
    },
  },
  chatMessageTemplateEditor: {
    freeText: "Free Text",
    insertBlockButton: "Insert Block",
    paperPreview: {
      button: "Button",
      links: "Links",
    },
    templateInput: {
      addButtonUrlSwitchLabel: "Add Button",
      addHeadLineSwitchLabel: "Add Headline",
      addHeaderMediaFileSwitchLabel: "Add Image",
      buttonLinkInputPlaceholder: "Insert Link",
      buttonTextInputPlaceholder: "Button Title",
      headerMediaFilePickerDefaultText: "Select Image",
      headlinePlaceholder: "Insert headline here",
      textInputPlaceholder: "Enter text here",
    },
  },
  dialogs: {
    appUpdateAvailable: {
      button: "Install app update now",
      message: "A newer app version is available. Please install the update first to " +
        "be able to use Flinkit without restrictions.",
    },
    channelSettings: {
      autoReplies: {
        absence: {
          endDate: "End date",
          expired: "Absence expired",
          initialValue: "Hello, I have received your message. I am currently absent " +
            "but will respond as soon as I return.",
          invalidDatesOrder: "End date cannot be earlier than start date",
          invalidEndDate: "End date required",
          invalidStartDate: "Start date required",
          startDate: "Start date",
          textInputLabel: "Your text",
          title: "Absent",
          toggleLabel: "Send an automatic reply if your customer contacts you while " +
            "you are unavailable.",
        },
        birthdayMessage: {
          missingTemplateError: "Please select a template",
          title: "Automated Birthday Message",
          toggleLabel: "This message will be sent to every contact who has a birthday " +
            "entered.",
        },
        marketingOptIn: {
          title: "Marketing Opt-in Chatbot",
          toggleLabel: "With this chatbot, you can obtain consent from your customers " +
            "to receive campaign messages.",
        },
        noChatBotSetup: "No chatbot has been set up for this channel yet. To start the " +
          "setup just send an email to ",
        outsideWorkingHours: {
          initialValue: "Hello and welcome. You've reached us outside of our business " +
            "hours. We'll get back to you as soon as possible!",
          textInputLabel: "Your Text",
          title: "Outside Opening Hours",
          toggleLabel: "Send an automatic response to your customer when they try to " +
            "reach you outside of opening hours.",
        },
        permissionDenied: "To make changes to the automatic replies, please contact " +
          "your admin.",
        title: "Auto Replies",
        welcomeMessage: {
          initialValue: "Good day, your request has been received, and we will get " +
            "back to you as soon as possible. Best regards.",
          textInputLabel: "Your Text",
          title: "Welcome Message",
          toggleLabel: "Send an automatic welcome message to your customer when they " +
            "reach out to you for the first time and you have not yet sent them a " +
            "message.",
        },
      },
      general: {
        membersListTitle: "Members",
        qrCode: {
          info: "When your customers scan this QR code, they go directly to this " +
          "WhatsApp channel. Feel free to share it with them.",
          shareViaEmailBody: "If you click on this link, you will land directly in " +
            "this WhatsApp channel%0D%0A" +
            "- share it with your customers%0D%0A" +
            "%{whatsappUrl}%0D%0A",
          title: "QR Code",
        },
        title: "General",
        whatsappAccountDisconnectionAlert: {
          currentConnection: "Currently connected to: %{webhookHostname}",
          message: "Your WhatsApp account is currently not connected to Flinkit and " +
            "therefore cannot send or receive messages",
        },
      },
      title: "Settings",
      whatsappAccountBusinessProfile: {
        title: "WhatsApp Profile",
      },
      workingHours: {
        closed: "Closed",
        forbidden: "To make changes to the opening hours, please contact your admin.",
        from: "From",
        subtitle: "Enter your opening hours so that your customers know when you can " +
          "be reached.",
        title: "Opening Hours",
        to: "to",
      },
    },
    chatNameEdit: {
      renameAction: "Rename",
      title: "Edit chat name",
    },
    profile: {
      companySettings: {
        addMerchantUser: {
          title: "Add User",
        },
        channelsAndUsers: {
          addAdmins: "Add Admins",
          addUsers: "Add Users",
          admins: "Admins",
          apply: "Apply",
          removeAll: "Remove All",
          title: "Channel & User Management",
          users: "Users",
        },
        createNumber: {
          emptyList: "No WhatsApp Accounts",
          numbersAlreadyCreated: "Numbers Already Created:",
          title: "Create Number",
          whatsappConnectionButton: "Connect new WhatsApp numbers via %{provider}",
        },
        faq: {
          linkText: "Find here",
          text: "Help and answers to frequently asked questions about setting up " +
            "Flinkit.",
        },
        location: {
          subtitle: "Each location has its own user and contact database",
          title: "Create Another Location",
        },
        title: "Company Settings",
      },
      merchantUsersSettings: {
        accountDeletionButton: "Delete Account",
        accountDeletionButtonError: "Error: %{message}",
        accountDeletionConfirm: "Do you really want to delete your user account?",
        thirdPartyConnections: {
          buttons: {
            hubspot: {
              synchronize: "Synchronize with HubSpot",
            },
            microsoftDynamics: {
              synchronize: "Synchronize with Microsoft Dynamics",
            },
            salesforce: {
              synchronize: "Synchronize with Salesforce",
              synchronized: "Synchronized with Salesforce",
            },
            sap: {
              synchronize: "Synchronize with SAP",
            },
          },
          title: "Connections",
        },
        title: "Profile Details",
        updateEmailAddress: {
          title: "Update email address",
        },
      },
      sandbox: {
        title: "Sandbox",
      },
      title: "Profile",
    },
    startChat: {
      external: {
        inputPlaceholder: "Enter Name or Mobile Number",
        subtitle: "Select Contact",
        title: "Open Chat",
      },
      internal: {
        actionButtonText: "Open Chat",
        form: {
          chatName: {
            label: "Chat Name",
          },
          teamMembers: {
            label: "Select Team Members",
            placeholder: "Colleague",
          },
        },
        title: "Open Team Chat",
      },
    },
    templateMessageComposer: {
      subtitle: "Templates are pre-prepared messages that can be sent at any time.",
      templateBuilder: {
        chooseTemplate: "Choose Template",
        fillPlaceholder: "Fill Placeholder",
      },
      title: "Template Messages",
    },
    thirdPartyConnection: {
      failed: "Connection Failed",
      loading: "Closing connection...",
      success: "Connection successful",
    },
  },
  errors: {
    codes: {
      EMAIL_ADDRESS_NOT_FOUND: "The email address was not found",
    },
    unknown: "An unknown error occurred.",
  },
  extendedList: {
    selectAllPages: "Select all pages",
  },
  hooks: {
    useBrowserNotifications: {
      notificationTitle: "New notification",
    },
    useTakePicture: {
      promptLabelCancel: "Cancel",
      promptLabelHeader: "Picture",
      promptLabelPhoto: "From Photos",
      promptLabelPicture: "From Camera",
    },
    useUserDisplayName: {
      channelChatBotUser: "🤖 Automatic reply",
    },
  },
  languages: {
    // Extracted from https://de.wikipedia.org/w/index.php?title=Liste_der_ISO-639-1-Codes
    'aa': "Afar",
    'ab': "Abkhazian",
    'ae': "Avestan",
    'af': "Afrikaans",
    'ak': "Akan",
    'am': "Amharic",
    'an': "Aragonese",
    'ar': "Arabic",
    'as': "Assamese",
    'av': "Avaric",
    'ay': "Aymara",
    'az': "Azerbaijani",
    'ba': "Bashkir",
    'be': "Belarusian",
    'bg': "Bulgarian",
    'bh': "Bihari",
    'bi': "Bislama",
    'bm': "Bambara",
    'bn': "Bengali",
    'bo': "Tibetan",
    'br': "Breton",
    'bs': "Bosnian",
    'ca': "Catalan",
    'ce': "Chechen",
    'ch': "Chamorro",
    'co': "Corsican",
    'cr': "Cree",
    'cs': "Czech",
    'cu': "Church Slavic",
    'cv': "Chuvash",
    'cy': "Welsh",
    'da': "Danish",
    'de': "German",
    'dv': "Divehi",
    'dz': "Dzongkha",
    'ee': "Ewe",
    'el': "Greek",
    'en': "English",
    'eo': "Esperanto",
    'es': "Spanish",
    'et': "Estonian",
    'eu': "Basque",
    'fa': "Persian",
    'ff': "Fulah",
    'fi': "Finnish",
    'fj': "Fijian",
    'fo': "Faroese",
    'fr': "French",
    'fy': "Western Frisian",
    'ga': "Irish",
    'gd': "Scottish Gaelic",
    'gl': "Galician",
    'gn': "Guaraní",
    'gu': "Gujarati",
    'gv': "Manx",
    'ha': "Hausa",
    'he': "Hebrew",
    'hi': "Hindi",
    'ho': "Hiri Motu",
    'hr': "Croatian",
    'ht': "Haitian",
    'hu': "Hungarian",
    'hy': "Armenian",
    'hz': "Herero",
    'ia': "Interlingua",
    'id': "Indonesian",
    'ie': "Interlingue",
    'ig': "Igbo",
    'ii': "Sichuan Yi",
    'ik': "Inupiaq",
    'io': "Ido",
    'is': "Icelandic",
    'it': "Italian",
    'iu': "Inuktitut",
    'ja': "Japanese",
    'jv': "Javanese",
    'ka': "Georgian",
    'kg': "Kongo",
    'ki': "Kikuyu",
    'kj': "Kwanyama",
    'kk': "Kazakh",
    'kl': "Kalaallisut",
    'km': "Khmer",
    'kn': "Kannada",
    'ko': "Korean",
    'kr': "Kanuri",
    'ks': "Kashmiri",
    'ku': "Kurdish",
    'kv': "Komi",
    'kw': "Cornish",
    'ky': "Kyrgyz",
    'la': "Latin",
    'lb': "Luxembourgish",
    'lg': "Ganda",
    'li': "Limburgish",
    'ln': "Lingala",
    'lo': "Lao",
    'lt': "Lithuanian",
    'lu': "Luba-Katanga",
    'lv': "Latvian",
    'mg': "Malagasy",
    'mh': "Marshallese",
    'mi': "Māori",
    'mk': "Macedonian",
    'ml': "Malayalam",
    'mn': "Mongolian",
    'mr': "Marathi",
    'ms': "Malay",
    'mt': "Maltese",
    'my': "Burmese",
    'na': "Nauru",
    'nb': "Norwegian Bokmål",
    'nd': "Northern Ndebele",
    'ne': "Nepali",
    'ng': "Ndonga",
    'nl': "Dutch",
    'nn': "Norwegian Nynorsk",
    'no': "Norwegian",
    'nr': "Southern Ndebele",
    'nv': "Navajo",
    'ny': "Chichewa",
    'oc': "Occitan",
    'oj': "Ojibwa",
    'om': "Oromo",
    'or': "Oriya",
    'os': "Ossetian",
    'pa': "Punjabi",
    'pi': "Pāli",
    'pl': "Polish",
    'ps': "Pashto",
    'pt': "Portuguese",
    'qu': "Quechua",
    'rm': "Romansh",
    'rn': "Kirundi",
    'ro': "Romanian",
    'ru': "Russian",
    'rw': "Kinyarwanda",
    'sa': "Sanskrit",
    'sc': "Sardinian",
    'sd': "Sindhi",
    'se': "Northern Sami",
    'sg': "Sango",
    'sh': "Serbo-Croatian",
    'si': "Sinhala",
    'sk': "Slovak",
    'sl': "Slovenian",
    'sm': "Samoan",
    'sn': "Shona",
    'so': "Somali",
    'sq': "Albanian",
    'sr': "Serbian",
    'ss': "Swati",
    'st': "Southern Sotho",
    'su': "Sundanese",
    'sv': "Swedish",
    'sw': "Swahili",
    'ta': "Tamil",
    'te': "Telugu",
    'tg': "Tajik",
    'th': "Thai",
    'ti': "Tigrinya",
    'tk': "Turkmen",
    'tl': "Tagalog",
    'tn': "Tswana",
    'to': "Tongan",
    'tr': "Turkish",
    'ts': "Tsonga",
    'tt': "Tatar",
    'tw': "Twi",
    'ty': "Tahitian",
    'ug': "Uighur",
    'uk': "Ukrainian",
    'ur': "Urdu",
    'uz': "Uzbek",
    've': "Tshivenda",
    'vi': "Vietnamese",
    'vo': "Volapük",
    'wa': "Walloon",
    'wo': "Wolof",
    'xh': "Xhosa",
    'yi': "Yiddish",
    'yo': "Yoruba",
    'za': "Zhuang",
    'zh': "Chinese",
    'zu': "Zulu",
  },
  pages: {
    companyDetails: {
      button: "Get Started",
      inputs: {
        companyName: {
          label: "Company name",
          placeholder: "Enter your company name",
        },
        companySize: {
          label: "Company size",
          options: {
            '1-10': "1 to 10 employees",
            '11-50': "11 to 50 employees",
            '51-200': "51 to 200 employees",
            '201-500': "201 to 500 employees",
            '500+': "More than 500 employees",
          },
          placeholder: "Select your company size",
        },
        industry: {
          label: "Industry",
          options: {
            AUTOMOTIVE: "Automotive",
            CONSTRUCTION: "Construction",
            DIGITAL_AGENCY: "Digital agency",
            EDUCATION_SERVICES: "Education services",
            GOVERNMENT: "Government & Public sector",
            HEALTHCARE: "Healthcare",
            HOTELS: "Hotel, restaurants & leisure",
            HOUSEHOLD: "Household goods & services",
            INSURANCE: "Insurance",
            ONLINE_MARKETPLACES: "Online marketplaces & SaaS platforms",
            OTHER: "Other",
            PROFESSIONAL_SERVICES: "Professional services",
          },
          placeholder: "Select your industry",
        },
      },
      subtitle: "Complete your details to get started.",
      title: "🎉 Account created successfully",
      trustedCompaniesTitle: "Trusted by big brands and many more",
    },
    login: {
      button: "Log In",
      errors: {
        UNAUTHORIZED_USER: "Invalid email address and/or password",
        UNEXPECTED_AUTH_ERROR: "Unexpected auth error",
      },
      inputs: {
        emailAddress: {
          label: "Email",
          placeholder: "johndoe@doe.de",
        },
        password: {
          label: "Password",
          placeholder: "Enter your password",
        },
      },
      links: {
        forgotPassword: "Forgot password? <forgot>Send a reset link</forgot>",
        signup: "Don't have an account yet? <signup>Register</signup>",
      },
      title: "👋 Welcome back!",
    },
    notFound: {
      message: "Sorry, we can’t find the page you are looking for!",
      title: "Page not found",
    },
    requestPasswordReset: {
      inputs: {
        emailAddress: {
          label: "Email",
          placeholder: "johndoe@doe.de",
        },
      },
      login: "Go back to <login>Log In</login>",
      register: "Don't have an account yet? <register>Register</register>",
      resetRequestSent: {
        login: "Back to Login",
        resend: "Not there? <link>Send again</link>",
        subtitle: "An email with instructions has been sent to your inbox.",
        title: "📬 Check your email",
        warn: "If you don't get an email soon, please check your spam folder.",
      },
      submit: "Reset",
      title: "Reset Password",
    },
    resetPassword: {
      errors: {
        INVALID_PASSWORD_RESET_CODE: "The password reset request has expired, please " +
          "request a new password reset",
      },
      fields: {
        newPassword: {
          label: "New password",
          placeholder: "Enter at least 8 characters",
        },
        repeatNewPassword: {
          label: "Repeat new password",
          placeholder: "Enter at least 8 characters",
        },
      },
      login: "Remember your password? <login>Log In</login>",
      passwordRequirements: {
        digit: "At least one number",
        length: "A minimum of 8 characters",
        lowercase: "At least one lowercase letter",
        uppercase: "At least one uppercase letter",
      },
      passwordResetSuccess: {
        login: "Back to Log In",
        nextStep: "Use your new password to log in.",
        subtitle: "Your password has been changed successfully.",
        title: "🎉 Password changed Successfully",
      },
      register: "Don’t have an account yet? <register>Register</register>",
      title: "Enter a new Password",
      validations: {
        newPasswordRepeat: "Password and password confirmation don't match",
      },
    },
    salesforceAuthentication: {
      connectionError: "Your Salesforce account could not be connected to Flinkit yet",
      connectionSuccess: "Your Salesforce account is now connected to Flinkit",
    },
    sandbox: {
      subtitle: "How would you like to get started?",
      title: "👋 Welcome %{firstName}",
    },
    signup: {
      button: "Create free account",
      errors: {
        MERCHANT_USER_EXISTS: "Another user with this email address already exists",
      },
      inputs: {
        emailAddress: {
          label: "Work email",
          placeholder: "johndoe@doe.de",
        },
        fullName: {
          label: "Full name",
          placeholder: "e.g. John Doe",
        },
        password: {
          label: "Password",
          placeholder: "Enter at least 8 characters",
        },
      },
      links: {
        forgotPassword: "Forgot password? <forgot>Send a reset link</forgot>",
        login: "Already have an account? <login>Log In</login>",
      },
      title: "Create a free account",
    },
    startExternalChat: {
      embeddedExternalChatPage: {
        openInFlinkit: "Open in Flinkit",
      },
      selectChannel: "Please select a channel:",
    },
    whatsappChannelsConnection: {
      connectionError: "Your WhatsApp account could not be connected yet",
      connectionSuccess: "Your WhatsApp account is now connected to Flinkit",
      errorCodes: {
        CHANNEL_PERMISSION_NOT_GRANTED: "Missing permission. Please grant Flinkit the " +
          "permission for managing the following channels: " +
          "<strong>%{channelIdentifiers}</strong>",
        CONNECTING_ON_PREMISE_NUMBER_UNSUPPORTED: "The following channels are still" +
          "hosted on-premise which is currently unsupported. Please migrate to " +
          "cloud-api hosting: <strong>%{channelIdentifiers}</strong>",
        THREE_SIXTY_CHANNEL_METADATA_ERROR: "Cannot connect whatsapp account because " +
          "relevant channel metadata could not be retrieved from 360dialog for " +
          "channel_identifiers=<strong>%{channelIdentifiers}</strong>",
        THREE_SIXTY_CHANNEL_NOT_FOUND: 'Could not find the following channel(s) on ' +
          '360dialog: <strong>%{channelIdentifiers}</strong>',
        UNEXPECTED: "Failed to connect channels for an unexpected reason. Please " +
          "retry: <strong>%{channelIdentifiers}</strong>",
      },
    },
  },
  resources: {
    aggregated_merchant_customer_user_tags: {
      fields: {
        tagName: "Tag",
      },
    },
    campaigns: {
      action: {
        create: "New Campaign",
      },
      audienceTypeChoices: {
        ALL: "All Contacts",
        ALL_WITH_MARKETING_OPT_IN: "All Contacts with Opt-in",
        SELECTED_CUSTOMER_USERS: "Individual Selection",
      },
      create: {
        createAction: "Continue",
        error: "Error creating campaign",
        inputs: {
          audienceType: {
            subtitle: "To which audience should this campaign be sent?",
          },
          channelId: {
            label: "Channel",
            subtitle: "From which channel should this campaign be sent",
          },
          chatMessageTemplateId: {
            label: "Select a Template",
            subtitle: "To create a campaign, you need an approved campaign template",
          },
          name: {
            subtitle: "This name is for internal use only and will not be displayed to " +
              "the customer.",
          },
          scheduledTimestamp: {
            label: "Date and Time",
            subtitle: "Set the date and time you want the campaign to go out",
          },
        },
        success: "Campaign created successfully",
        toolbar: {
          confirmDialog: {
            emptyContacts: "No contact selected yet",
            instantCampaignMessage: "Your campaign named “<strong>%{name}</strong>” " +
              "will be sent “<strong>immediately</strong>” to these contacts.",
            scheduledCampaignMessage: "Your campaign named “<strong>%{name}</strong>“ " +
              "will be sent to these contacts on " +
              "“<strong>%{scheduledDatetime}</strong>“.",
            termsAndConditions: "By sending, you confirm that you have read and " +
              "accepted the terms and conditions for WhatsApp newsletter delivery.",
            title: "Send Campaign",
          },
        },
      },
      createFormTitle: "Create Campaigns",
      createTemplate: "Create New Template",
      createTemplateDialog: {
        confirmationText: "Proceed to Templates",
        content: "You will be redirected to template creation. Your current campaign " +
          "will not be saved.",
      },
      empty: "You haven't created any campaigns yet",
      fields: {
        audienceType: "Customer Audience",
        channelId: "Campaign Channel",
        chatMessageTemplateId: "Campaign Template",
        deliveredMessageCount: "Delivered to",
        insertionTimestamp: "Creation at",
        insertionUserId: "Sent by",
        name: "Campaign Name",
        readMessageCount: "Read by",
        scheduledTimestamp: "Schedule",
        sentTimestamp: "Sent",
        totalMessageCount: "Sent to",
      },
      invite: "Start a new campaign now!",
      list: {
        description: "Through a campaign, you have the opportunity to send a message " +
          "to multiple contacts simultaneously, for example, to send newsletters and " +
          "information about new products & offers.",
        description2: "To start a campaign, you need an approved campaign template.",
      },
      name: "Campaigns",
      show: {
        campaignPerformanceSubtitle: "The percentage shows the ratio from stage to " +
          "stage.",
        campaignPerformanceTitle: "Campaign Performance",
        confirmDelete: "Are you sure you want to delete this campaign?",
        exportMessageMetadata: {
          errors: {
            ONGOING_CAMPAIGN: "Campaign message information cannot be exported because " +
              "this campaign is still ongoing. Please, wait for it to be finished.",
            PENDING_CAMPAIGN: "Campaign message information cannot be exported " +
              "because this campaign hasn't yet started.",
          },
        },
        fields: {
          audienceType: "Sent to:",
          scheduledFor: "Scheduled for",
          sentOn: "Sent on",
        },
        templatePreview: {
          label: "Used Template",
        },
      },
    },
    channel_chat_bot_users: {
      fields: {
        optInTriggerAutoresponse: "Opt-In Message",
        optOutTrigger: "Opt-Out Trigger",
        optOutTriggerAutoresponse: "Opt-Out Confirmation Response",
      },
      title: "Opt-In Chatbot Settings",
    },
    channels: {
      edit: {
        inputs: {
          name: {
            label: "Channel (%{phoneNumber})",
            subtitle: "Name of this channel",
          },
        },
      },
      fields: {
        birthdayMessageTemplateId: "Template",
      },
    },
    chat_message_templates: {
      action: {
        create: "New Template",
        delete: "Delete Template",
      },
      confirmDeletion: "Do you really want to delete this template?",
      create: {
        createButton: "Submit Template",
        error: "Error creating template",
        imageRequiredForHeaderMediaSwitch: "In the case the \"Add Image\" switch is " +
          "enabled, an image must be defined",
        inputs: {
          name: {
            label: "Template Name",
          },
          type: {
            label: "Select",
          },
        },
        nameSubtitle: "This name is for internal use only and will not be displayed to " +
          "the customer.",
        success: "Template created successfully",
        title: "Create Template",
        typeSubtitle: "Campaign templates are exclusively intended for marketing " +
          "activities.",
      },
      createFormTitle: "Create Template",
      description: "Individual templates allow you to communicate flexibly and " +
        "conveniently with your customers.",
      empty: "No templates available yet",
      errorMessages: {
        INVALID_PLACEHOLDER_NAME: "At least one placeholder has an invalid name",
        TEMPLATE_ALREADY_EXISTS: "Another template already exists with this name",
      },
      fields: {
        approvalStatus: "Approval",
        category: "Type",
        language: "Language",
        name: "Name",
        status: "Status",
        text: "Message",
        type: "Type",
      },
      invite: "Create a new one",
      name: "Templates",
      startCampaign: "Start New",
      types: {
        MARKETING: "Campaigns",
        UTILITY: "Standard",
      },
    },
    chats: {
      empty: "Your inbox is empty",
      invite: "Start a chat with your customers now!",
    },
    customer_users: {
      fields: {
        whatsappDisplayName: "WhatsApp name",
        whatsappPhoneNumber: "Phone number",
      },
    },
    merchant_customer_users: {
      action: {
        create: "Create New Contact",
        seeChat: "To chat",
      },
      create: {
        company: "Company",
        error: "Error creating contact",
        inputs: {
          whatsappPhoneNumber: {
            helperText: "Format: +493028874362",
          },
        },
        internal: "Internal",
        personalData: "Personal Data",
        success: "New contact created successfully",
      },
      createFormTitle: "Create Contact",
      edit: {
        addTag: "Add Tag",
        channelTags: "Channel Tags",
        company: "Company",
        confirmDeletion: "Do you really want to delete this contact?",
        createTagPopupButton: "Create New Tag",
        emptyChannelTags: "No channel tags yet",
        internal: "Internal",
        personalData: "Personal Data",
        title: "Contact details",
      },
      empty: "No contacts found",
      errorMessages: {
        chatLink: "The chat could not be created",
      },
      fields: {
        birthdayDate: "Birth Date",
        companyCity: "City",
        companyHouseNumber: "House Number",
        companyName: "Company",
        companyStreet: "Street",
        companyZipCode: "Zip Code",
        customerCode: "Customer Number",
        emailAddress: "Email Address",
        firstName: "First Name",
        isMarketingConsentGranted: "Marketing Opt-In",
        lastName: "Last Name",
        name: "Name",
        optInOptions: {
          open: "Open",
        },
        phoneNumber: "Phone Number (Other)",
        primaryResponsibleMerchantUserId: "Primary Contact",
        role: "Position in Company",
        trade: "Industry",
        whatsappPhoneNumber: "Phone Number",
      },
      import: {
        button: "Import",
        confirm: "Do you want to import all contacts from this file \"%{filename}\"?",
        error: "Unfortunately, the contact file could not be imported. Please contact " +
          "our customer service at support@flinkit.de. Error message: %{message}",
        progressDialog: {
          progressMessage: "Your contact file is being imported. Please give us a " +
            "moment...",
          title: "Import Contacts",
        },
        status: {
          ignored: "%{invalidRowsCount} Some rows were ignored due to the following " +
            "reasons:",
        },
        success: "%{importedMerchantCustomerUsersCount} contacts have been " +
          "successfully imported or updated.",
      },
      importRowFailureReasons: {
        EMAIL_ADDRESS_INVALID: "The email address is not valid",
        FIRST_NAME_MISSING: "Row is missing a first name",
        PHONE_NUMBER_DUPLICATED: "Row has a duplicated phone number",
        PHONE_NUMBER_INVALID: "Row has an invalid phone number",
      },
      inputs: {
        isMarketingConsentGranted: {
          falseLabel: "Without Opt-in",
          nullLabel: "Either",
          trueLabel: "With Opt-in",
        },
      },
      lastSalesforceSync: "Last synchronized: %{age}",
      lastSalesforceSyncPreparing: "preparing",
      list: {
        chooseChannel: "Choose a channel:",
      },
      name: "Contacts",
      successMessages: {
        tagAdded: "Tag added",
        tagRemoved: "Tag removed",
      },
    },
    merchant_users: {
      actions: {
        create: "Add",
      },
      chooseLanguage: "Choose Language",
      customFields: {
        myFlinkitNumbers: {
          label: "My Flinkit numbers",
        },
      },
      edit: {
        title: "Profile",
      },
      emailNotification: {
        choices: {
          instant: {
            description: "As soon as a new message arrives in your chats, you will " +
              "receive an email.",
            label: "Instant real-time notifications",
          },
          none: {
            description: "You will not receive notifications via email.",
            label: "Turn off all notifications",
          },
          reminders: {
            description: "You will receive a summary email a maximum of 3 times a day. " +
              "As well as an extra email if you have been assigned a customer chat.",
            label: "3 reminders per day",
          },
        },
        title: "Email notifications",
      },
      errorMessages: {
        MERCHANT_USER_EMAIL_ADDRESS_ALREADY_EXISTS:
          'Another user with this email address already exists',
        invalidEmailAddress: "Invalid email address",
      },
      fields: {
        emailAddress: "Email Address",
        firstName: "First Name",
        language: "Language",
        lastName: "Last Name",
      },
      name: "Users",
      successMessages: {
        created: "User successfully added",
      },
    },
    merchants: {
      actions: {
        create: "Create Location",
      },
      errorMessages: {
        create: "An error occurred while creating the location",
      },
      fields: {
        companyName: "Company Name",
        ownerEmailAddress: "Email",
        ownerFirstName: "First Name",
        ownerLastName: "Last Name",
        whatsappBusinessAbout: "Company About",
        whatsappBusinessAddress: "Address",
        whatsappBusinessDescription: "Company Description",
        whatsappBusinessEmail: "Email Address",
        whatsappBusinessVertical: "Business Vertical",
        whatsappBusinessWebsite: "Website",
      },
      location: "Location",
      owner: "Owner",
      successMessages: {
        created: "A new location has been successfully created",
      },
    },
    organization_merchant_users: {
      customFields: {
        name: {
          label: "Name",
        },
      },
      fields: {
        emailAddress: "Email Address",
        merchantId: "Location",
      },
    },
    whatsapp_accounts: {
      fields: {
        businessAbout: "Company About",
        businessAddress: "Address",
        businessDescription: "Company Description",
        businessEmail: "Email Address",
        businessWebsite: "Website",
        whatsappBusinessVertical: "Business Vertical",
      },
    },
  },
  sandboxSettings: {
    /* eslint-disable max-len */
    contactUs: {
      button: "Request help",
      subtitle: "Do you have questions or need help?",
      title: "Contact us",
    },
    errorMessages: {
      'CHAT_ALREADY_OWNED_BY_ANOTHER_SANDBOX_USER': "This number has already been used/tested by another user, please use a different number",
    },
    featureNotAvailable: "This feature is only available on a paid plan",
    requestConversion: {
      button: "Start setup",
      caption: "Approx 15 minutes",
      response: "Your request has been received. We'll get in touch with you soon",
      subtitle: "Connect your number and set up Whatsapp Business API",
      title: "WhatsApp Business API",
    },
    testSandbox: {
      button: "Try now",
      subtitle: "Test our inbox and send messages via Whatsapp",
      title: "Test (Sandbox)",
    },
    testSandboxDialog: {
      button: "Send",
      fields: {
        phoneNumber: "WhatsApp number",
      },
      onIncomingChatMessageNotification: "Received a new message, navigating to chat",
      onSentChatMessage: "Please continue on your mobile phone.",
      sandboxCodeMessage: "Hi, please connect me to the Flinkit Sandbox: %{code}",
      subtitle1: "Just scan the code",
      subtitle2: "Or enter your mobile phone number",
      title: "Test inbox and whatsapp sending",
    },
    /* eslint-enable max-len */
  },
  sidebar: {
    campaigns: "Campaigns",
    contacts: "Contacts",
    help: "Help",
    inbox: "Inbox",
    menu: {
      logout: "Logout",
    },
    profile: {
      sandboxAccount: "Test account",
    },
    teamChat: "Team chat",
    templates: "Templates",
  },
  statuses: {
    pending: "Pending",
  },
  unreadChatMessagesBrowserNotificationPresenter: {
    newMessageNotification: "New message from %{authorUserDisplayName}",
    newReactionMessageNotification: "%{authorUserDisplayName} reacted to a message",
  },
  validations: {
    companyName: "Company Name is required",
    companySize: "Company Size is required",
    date: "Invalid date",
    emailAddress: "Invalid email address",
    files: {
      maxSize: "The selected file size %{filename} (%{fileSize}MiB) exceeds our " +
        "%{maxSize}MiB file size limit",
    },
    fullName: "Full name is required",
    industry: "Industry is required",
    password: {
      digit: "At least one number",
      doNotMatch: "Password and password confirmation don't match",
      length: "A minimum of 8 characters",
      lowercase: "At least one lowercase letter",
      missingSomeChars: "Make sure your password includes the following",
      required: "Password is required",
      uppercase: "At least one uppercase letter",
    },
    phoneNumber: "Invalid phone number",
    required: "Required",
    time: "Invalid time",
  },
  weekdays: {
    full: {
      friday: "Friday",
      monday: "Monday",
      saturday: "Saturday",
      sunday: "Sunday",
      thursday: "Thursday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
    },
    shortened: {
      friday: "Fri",
      monday: "Mon",
      saturday: "Sat",
      sunday: "Sun",
      thursday: "Thu",
      tuesday: "Tue",
      wednesday: "Wed",
    },
  },
  whatsapp_business_verticals: {
    APPAREL: "Clothing and Apparel",
    AUTO: "Automotive",
    BEAUTY: "Beauty, Spa, and Salon",
    EDU: "Education",
    ENTERTAIN: "Entertainment",
    EVENT_PLAN: "Event Planning and Service",
    FINANCE: "Finance and Banking",
    GOVT: "Public Service",
    GROCERY: "Food and Grocery",
    HEALTH: "Medical and Health",
    HOTEL: "Hotel and Lodging",
    NONPROFIT: "Non-profit",
    NOT_A_BIZ: "Not a business",
    OTHER: 'Other',
    PROF_SERVICES: "Professional Services",
    RESTAURANT: "Restaurant",
    RETAIL: "Shopping and Retail",
    TRAVEL: "Travel and Transportation",
    UNDEFINED: "Undefined",
  },
  whatsapp_message_templates: {
    statusDescriptions: {
      APPROVED: "This template is now approved and can be used in chats.",
      CREATED: "WhatsApp needs to approve this template before you can use it.",
      DELETED: "This template is no longer available.",
      PENDING: "WhatsApp needs to approve this template before you can use it.",
      REJECTED: "WhatsApp has rejected this template.",
    },
    statusLabels: {
      APPROVED: "Approved",
      CREATED: "Waiting for Approval",
      DELETED: "Deleted",
      PENDING: "Waiting for Approval",
      REJECTED: "Rejected",
    },
  },
  whatsapp_messages: {
    lastSendAttemptErrorCode: {
      2: "Whatsapp Service unavailable temporarily due to downtime or due to being " +
        "overloaded.",
      368: "Temporarily blocked for policies violations",
      410: "The message expiration date was reached before the message could be sent.",
      429: "You have sent too many messages in a very short time.",
      471: "Too many of your messages have been reported as spam.",
      480: "This WhatsApp number likely has a new owner.",
      1001: "The message is longer than the allowed 4096 characters.",
      1009: "The message contains invalid attributes.",
      1013: "This phone number has not set up WhatsApp yet.",
      1026: "The recipient cannot receive this message.",
      1028: "This WhatsApp number likely has a new owner.",
      2003: "The recipient could not receive the message template.",
      2008: "The format of the file attachment is not supported by WhatsApp.",
      80007: "The WhatsApp Business Account has reached its rate limit.",
      131026: "Unable to deliver message. The recipient phone number is not a WhatsApp " +
        "phone number or has not accepted our new Terms of Service or is using an old " +
        "WhatsApp version.",
      131031: "Account has been locked",
      131048: "Spam rate limit hit",
      131053: "Unable to upload the media used in the message.",
      131056: "(Business Account, Consumer Account) pair rate limit hit. " +
        "Wait and retry to send the message to this recipient.",
      TEMPLATE_REJECTED: "The template has been rejected.",
    },
    lastSendAttemptErrorCodeToCustomDetails: {
      TEMPLATE_REJECTED: "Your message could not be sent, try again with different " +
        "content, or send a template",
    },
    receivingErrorCode: {
      501: "Unsupported message type.",
    },
  },
  whatsappAccountBusinessProfileEdit: {
    profilePhotoConstraints: "Recommended file size, 5 KB to 5 MB. PNG, JPG",
    successfulProfilePhotoUpload: "Profile photo updated successfully",
    validations: {
      emailAddress: "Invalid email address",
      profilePhotoSize: "Please use the recommended format or size!",
      websiteUrl: "The website URL must start with either http:// or https://",
    },
  },
}
