import {useMutation} from '@apollo/react-hooks'
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@material-ui/core'
import gql from 'graphql-tag'
import {FC, useState} from 'react'
import {useTranslate} from 'react-admin'

import {
  ArchiveIcon,
  EditIcon,
  MoreVertIcon,
  UnarchiveIcon,
} from '../../components/icons'
import {Chats, MutationRoot} from '../../types/graphqlSchema'
import ChatNameEditDialog from './ChatNameEditDialog'

const ChatContextMenuButton: FC<ChatContextMenuButtonProps> = ({
  chat: {id, isArchived, name},
  ...rest
}) => {
  const [archive] = useMutation<MutationRoot['update_chats_by_pk']>(
    UPDATE_CHAT_MUTATION, {variables: {id, isArchived: !isArchived, name}}
  )
  const [updateChatName] = useMutation<MutationRoot['update_chats_by_pk']>(
    UPDATE_CHAT_MUTATION, {variables: {id, isArchived}}
  )
  const [anchorElement, setAnchorElement] = useState<HTMLElement>()
  const [isChatNameEditDialogOpen, setIsChatNameEditDialogOpen] = useState(false)
  const closeMenu = event => {
    event.stopPropagation()
    setAnchorElement(undefined)
  }
  const translate = useTranslate()
  return (
    <>
      <IconButton
        aria-controls="long-menu"
        aria-haspopup="true"
        aria-label="more"
        onClick={e => {
          e.preventDefault()
          setAnchorElement(e.currentTarget)
        }}
        {...rest}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorElement}
        keepMounted
        onClose={closeMenu}
        open={!!anchorElement}
      >
        <MenuItem
          onClick={e => {
            closeMenu(e)
            archive()
          }}
        >
          <ListItemIcon>
            {isArchived ?
              <UnarchiveIcon color="primary" /> :
              <ArchiveIcon color="primary" />
            }
          </ListItemIcon>
          <ListItemText>
            {isArchived ?
              translate('chat.internal.contextMenu.unarchive') :
              translate('chat.internal.contextMenu.archive')
            }
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={e => {
            closeMenu(e)
            setIsChatNameEditDialogOpen(true)
          }}
        >
          <ListItemIcon><EditIcon color="primary" /></ListItemIcon>
          <ListItemText>{translate('chat.internal.contextMenu.renameChat')}</ListItemText>
        </MenuItem>
      </Menu>
      <ChatNameEditDialog
        initialValue={name}
        onClose={() => setIsChatNameEditDialogOpen(false)}
        onConfirm={name => updateChatName({variables: {name}})}
        open={isChatNameEditDialogOpen}
      />
    </>
  )
}

const UPDATE_CHAT_MUTATION = gql`
  mutation($id: uuid!, $isArchived: Boolean!, $name: String) {
    update_chats_by_pk(
      pk_columns: {id: $id}, _set: {isArchived: $isArchived, name: $name}
    ){id isArchived name}
  }
`

interface ChatContextMenuButtonProps {
  chat: Chats
  className?: string
}

export default ChatContextMenuButton
