import {Box, Grid} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FC, useContext} from 'react'
import {
  CreateButton,
  Empty as DefaultEmpty,
  EmptyProps as DefaultEmptyProps,
  useListContext,
  useTranslate,
} from 'react-admin'

import CampaignsEmptyStateIcon from '../assets/imgs/campaignsEmptyStateIcon.svg'
import ChatMessageTemplatesEmptyStateIcon
  from '../assets/imgs/chatMessageTemplatesEmptyStateIcon.svg'
import ChatsEmptyStateIcon from '../assets/imgs/chatsEmptyStateIcon.svg'
import DefaultEmptyStateIcon from '../assets/imgs/defaultEmptyStateIcon.svg'
import MerchantCustomerUsersEmptyStateIcon
  from '../assets/imgs/merchantCustomerUsersEmptyStateIcon.svg'
import {SandboxContext} from '../contexts/SandboxContext'
import useIsDesktop from '../hooks/useIsDesktop'
import {snakeToCamel} from '../utils/strings'
import ExtendedAlert from './ExtendedAlert'

const Empty: FC<EmptyProps> = ({
  className,
  ...props
}) => {
  const listControllerProps = useListContext(props)
  const {isSandbox} = useContext(SandboxContext)
  const {basePath, hasCreate, resource} = listControllerProps
  const shouldShowCreate = hasCreate && !isSandbox
  const styles = useStyles()
  const isDesktop = useIsDesktop()
  const translate = useTranslate()
  const EmptyStateIcon = (
    RESOURCE_TO_EMPTY_STATE_IMAGE[snakeToCamel(resource)] || DefaultEmptyStateIcon
  )
  return (
    <Grid
      className={[styles.root, className].join(' ')}
      container
      justifyContent="center"
    >
      {(isSandbox && basePath) && (
        <Box className={styles.sandboxFeatureMessage}>
          <ExtendedAlert severity="warning">
            {translate('sandboxSettings.featureNotAvailable')}
          </ExtendedAlert>
        </Box>
      )}
      <EmptyStateIcon className={styles.emptyStateIcon}/>
      <Grid item><DefaultEmpty {...listControllerProps} classes={styles} /></Grid>
      {(shouldShowCreate && isDesktop) && (
        <Grid
          className={styles.createButtonContainer}
          container
          item
          justifyContent="center"
        >
          <CreateButton
            basePath={basePath}
            label={translate(`resources.${resource}.action.create`)}
            variant="outlined"
          />
        </Grid>
      )}
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  createButtonContainer: {
    '& .MuiButton-root': {
      borderStyle: 'none',
      cursor: 'pointer',
      fontSize: theme.typography.body1.fontSize,
      fontWeight: 'bold',
      justifySelf: 'top',
      textDecoration: 'underline',
    },
    '& .MuiSvgIcon-root': {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
  },
  emptyStateIcon: {
    alignItems: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    fill: theme.palette.text.secondary,
    height: theme.typography.pxToRem(208),
    width: '100%',
  },
  icon: {
    display: 'none',
  },
  message: {
    '& .MuiTypography-h4': {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  root: {
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.remSpacing(10),
    },
    flexDirection: 'column',
    width: '100%',
  },
  sandboxFeatureMessage: {
    marginLeft: theme.remSpacing(20),
    marginRight: theme.remSpacing(20),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.remSpacing(2),
      marginRight: theme.remSpacing(2),
    },
  },
  toolbar: {
    '& .MuiButtonBase-root': {
      display: 'none',
    },
  },
}))

const RESOURCE_TO_EMPTY_STATE_IMAGE = {
  'campaigns': CampaignsEmptyStateIcon,
  'chatMessageTemplates': ChatMessageTemplatesEmptyStateIcon,
  'chats': ChatsEmptyStateIcon,
  'merchantCustomerUsers': MerchantCustomerUsersEmptyStateIcon,
}

interface EmptyProps extends DefaultEmptyProps {
    className?: string;
}

export default Empty
