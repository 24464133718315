import {useGetIdentity} from 'react-admin'

// TODO: Rename this hook to a more generic name.
const useIsVobankOrSchulteMerchantUser = (): boolean => {
  const {identity: {emailAddress} = {}} = useGetIdentity()
  if (!emailAddress) return false
  return (
    emailAddress.includes('@volksbank-trier.de') ||
    (
      emailAddress.includes('@volksbank-eifel.de') &&
      !(
        emailAddress.startsWith('firmenkunden') ||
        emailAddress.startsWith('servicecenter')
      )
    ) || (
      emailAddress.includes('@schulte-lagertechnik.de') &&
      !(
        emailAddress.startsWith('c.braun') ||
        emailAddress.startsWith('c.janhsen') ||
        emailAddress.startsWith('m.vahlke')
      )
    ) || (
      emailAddress.includes('@kao.com') &&
      !(
        emailAddress.startsWith('sahin.er') ||
        emailAddress.startsWith('angelika.ciupa') ||
        emailAddress.startsWith('marco.felice') ||
        emailAddress.startsWith('brigitte.segel') ||
        emailAddress.startsWith('christian.seemann') ||
        emailAddress.startsWith('giulia.koller')
      )
    ) || (
      emailAddress.includes('@ruetue.de') &&
      !(
        emailAddress.startsWith('edv') ||
        emailAddress.startsWith('svenpuening') ||
        emailAddress.startsWith('corinnajordan') ||
        emailAddress.startsWith('felixschulte')
      )
    )
  )
}

export default useIsVobankOrSchulteMerchantUser
