import {Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {useGetIdentity, useLocale, useTranslate} from 'react-admin'
import {Redirect, useLocation} from 'react-router-dom'

import {SandboxContext} from '../../../contexts/SandboxContext'
import useLongPressEvent from '../../../hooks/useLongPressEvent'
import hexToRgb from '../../../theme/hexToRgb'
import {ChatMessages, WhatsappMessageTypesEnum} from '../../../types/graphqlSchema'
import displayChatMessageStatus from '../../../utils/displayChatMessageStatus'
import ChatMessageBubble from './ChatMessageBubble'
import ChatMessageForwardButton from './ChatMessageForwardButton'

const ChatMessageListItem: FC<ChatMessageListItemProps> = (
  {isShowingTranslations, onForward, value}
) => {
  const {isSandbox} = useContext(SandboxContext)
  const translate = useTranslate()
  const locale = useLocale()
  const {identity: {id: userId} = {}} = useGetIdentity()
  const {onTouchEnd, onTouchStart} = useLongPressEvent({onEnter: onForward})
  const {
    authorUser,
    chat: {channelId, isProductNews},
    chatMessageTranslations,
    chatMessageWhatsappMessages,
    text,
  } = value
  const isRight = channelId ? !authorUser.customerUser : (authorUser.id === userId)
  const displayText = useMemo(() => {
    if (
      chatMessageWhatsappMessages?.[0]?.whatsappMessage.type ===
        WhatsappMessageTypesEnum.Unknown
    ) {
      return translate('chat.chatMessageBubble.unknownWhatsappMessageTypeText')
    }
    if (isProductNews && !isRight) {
      return chatMessageTranslations.find(t => t.language === locale)?.text ?? text
    }
    return text
  }, [
    chatMessageTranslations,
    chatMessageWhatsappMessages,
    isProductNews,
    isRight,
    locale,
    text,
    translate,
  ])
  const hashValue = useLocation().hash.replace('#', '')
  const [isHighlighted, setIsHighlighted] = useState(false)
  useEffect(() => {
    if (hashValue !== value.id) return
    setIsHighlighted(true)
    const cancelIsBlinkingTimeoutId = setTimeout(
      () => {
        setIsHighlighted(false)
        // Clear the hash so that it's possible for the chat item to blink
        // again and again if its anchor is clicked successively.
        window.location.hash = ''
      },
      2500,
    )
    return () => clearTimeout(cancelIsBlinkingTimeoutId)
  }, [hashValue, value])
  const styles = useStyles({isHighlighted})
  // TODO: temporary fix to redirect user to 404 page incase a chat_message does not
  //       contain a chat due to permission check failure
  if (!value?.chat) return <Redirect to="/*" />
  return (
    <li
      className={`${styles.root} ${isRight ? styles.right : styles.left}`}
      id={value.id}
      onTouchEnd={e => {
        // Enable context menu when the user releases the touch
        window.oncontextmenu = () => true
        onTouchEnd(e)
      }}
      onTouchStart={e => {
        // Disable context menu for touch screen events
        window.oncontextmenu = event => (event as PointerEvent).pointerType !== 'touch'
        onTouchStart(e)
      }}
    >
      <ChatMessageBubble
        displayText={displayText}
        isRight={isRight}
        isShowingTranslations={isShowingTranslations || isRight}
        status={displayChatMessageStatus(value)}
        value={value}
      />
      {(channelId && !isSandbox) && (
        <ChatMessageForwardButton className={styles.forwardButton} onClick={onForward} />
      )}
    </li>
  )
}

const useStyles = makeStyles<Theme, MakeStyleProps>(theme => ({
  forwardButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    marginBottom: theme.remSpacing(3),
    marginLeft: theme.remSpacing(1),
    marginRight: theme.remSpacing(1),
    zIndex: 1,
  },
  left: getDirectionStyle('left', theme),
  right: getDirectionStyle('right', theme),
  root: {
    '& + &': {
      marginTop: theme.remSpacing(1),
    },
    '&:not(:hover) > $forwardButton': {
      display: 'none',
    },
    alignItems: 'center',
    backgroundColor: ({isHighlighted}) => (
      isHighlighted ?
        `rgba(${hexToRgb(theme.palette.chatBubble.main)}, 0.5)` : 'unset'
    ),
    color: '#525f7f',
    display: 'flex',
    paddingBottom: '.25rem',
    paddingTop: '.25rem',
    // Make anchor link go some pixels above where it's linked to.
    // Tells the browser to scroll a bit up by some pixels above the chat message bubble
    // when the user clicks on the chat message replied bubble anchor.
    scrollMarginTop: theme.remSpacing(6),
    transition: 'background-color 1s ease-out',
    width: '100%',
  },
}))

const getDirectionStyle = (
  direction: string, theme: Theme
): Record<string, any> => ({
  [`& + $${direction}`]: {
    marginTop: theme.remSpacing(3),
  },
  flexDirection: `row${direction === 'right' ? '-reverse' : ''}`,
  justifyContent: `${direction}`,
})

interface ChatMessageListItemProps {
  isShowingTranslations?: boolean
  onForward: () => void
  value: ChatMessages
}

interface MakeStyleProps {
  isHighlighted?: boolean
}

export default ChatMessageListItem
