import {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'

import useGetUnreadMessageCount from '../hooks/chat/useGetUnreadMessageCount'
import {isNativeMobileApp} from '../utils/platform'

const AppFavicon = () => {
  const unreadMessageCount = useGetUnreadMessageCount({disabled: isNativeMobileApp})
  const [
    shouldRenderUnreadMessagesFavicon, setShouldRenderUnreadMessagesFavicon,
  ] = useState(false)
  useEffect(() => {
    if (isNativeMobileApp) return
    if (unreadMessageCount === 0) {
      setShouldRenderUnreadMessagesFavicon(false)
      return
    }
    const faviconUpdateInterval = setInterval(() => {
      setShouldRenderUnreadMessagesFavicon(s => !s)
    }, 500)
    return () => clearInterval(faviconUpdateInterval)
  }, [unreadMessageCount])
  return (
    // defer={false} is needed to allow updates to happen when tab is out of focus
    // See: https://github.com/nfl/react-helmet#reference-guide
    <Helmet defer={false}>
      <link
        href={`/${shouldRenderUnreadMessagesFavicon ?
          'unreadMessagesFavicon.ico' : 'favicon.ico'
        }`}
        rel="default icon"
        type="image/x-icon"
      />
      <title>{unreadMessageCount > 0 ? `(${unreadMessageCount}) ` : ''}Flinkit</title>
    </Helmet>
  )
}

export default AppFavicon
