import {Dialog, DialogProps, makeStyles} from '@material-ui/core'
import {FC} from 'react'

import useIsDesktop from '../hooks/useIsDesktop'
import {isNativeMobileApp} from '../utils/platform'

const ExtendedDialog: FC<DialogProps> = ({
  children,
  className,
  fullScreen,
  ...props
}) => {
  const styles = useStyles()
  const isDesktop = useIsDesktop()
  return (
    <Dialog
      PaperProps={{elevation: (fullScreen || isDesktop) ? 1 : 0}}
      className={[styles.root, className].join(' ')}
      // On mobile layout show dialog in fullscreen mode by default
      fullScreen={fullScreen ?? (isDesktop ? undefined : true)}
      hideBackdrop={fullScreen ?? (isDesktop ? undefined : true)}
      {...props}
    >
      {children}
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: isNativeMobileApp ? theme.palette.background.paper : 'unset',
    paddingTop: 'var(--safe-area-top-inset)',
    ...(isNativeMobileApp && {width: '100vw'}),
  },
}))

export default ExtendedDialog
