import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Paper,
  Popper,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import _ from 'lodash'
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state'
import {FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  Datagrid,
  DateInput,
  defaultExporter,
  EditButton,
  ExportButton,
  FilterLiveSearch,
  FunctionField,
  Loading,
  MutationMode,
  Pagination,
  PaginationPayload,
  Record,
  ReferenceField,
  ReferenceInput,
  regex,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useCreate,
  useGetIdentity,
  useGetList,
  useListContext,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin'

import BulkDeleteButton from '../components/BulkDeleteButton'
import EditFormAutoSave from '../components/EditFormAutoSave'
import ExtendedEdit from '../components/ExtendedEdit'
import ExtendedSimpleForm from '../components/ExtendedSimpleForm'
import FileUploadButton from '../components/FileUploadButton'
import {
  AddFilledIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CloseFilledIcon,
  DownloadIcon,
  SaveIcon,
  SearchIcon,
  SyncIcon,
  WarningIcon,
} from '../components/icons'
import ProgressDialog from '../components/ProgressDialog'
import useHasPermission from '../hooks/useHasPermission'
import useIsDesktop from '../hooks/useIsDesktop'
import useIsOwner from '../hooks/useIsOwner'
import useIsVobankOrSchulteMerchantUser from '../hooks/useIsVobankOrSchulteMerchantUser'
import useIsWegoMerchantUser from '../hooks/useIsWegoMerchantUser'
import useQueryParameters from '../hooks/useQueryParameters'
import useSessionMerchantUser from '../hooks/useSessionMerchantUser'
import useSimplifyAge from '../hooks/useSimplifyAge'
import {
  Channels,
  MerchantCustomerUsers,
  MerchantCustomerUserTags,
  Merchants,
  MerchantUsers,
  MutationRoot,
  QueryRoot,
  Tags,
} from '../types/graphqlSchema'
import {EMAIL_ADDRESS_REGEX} from '../utils/consts'
import {EventCategory, trackEvent} from '../utils/tracking'
import DeleteWithConfirmButton from './components/DeleteWithConfirmButton'
import ExtendedList from './components/ExtendedList'
import {CreateHeader, ShowHeader} from './components/ResourceHeader'

const MerchantCustomerUserCreate = props => {
  const translate = useTranslate()
  const styles = useStyles()
  const redirect = useRedirect()
  const notify = useNotify()
  const {identity: {merchantUserId} = {}} = useGetIdentity()
  const isVobankOrSchulteMerchantUser = useIsVobankOrSchulteMerchantUser()
  const [
    upsertMerchantCustomerUser,
  ] = useMutation<MutationRoot['insert_merchant_customer_users_one']>(
    MERCHANT_CUSTOMER_USER_UPSERT_MUTATION
  )
  const save = useCallback(async ({
    birthdayDate,
    ...formInputValues
  }) => {
    try {
      const {data: {insert_merchant_customer_users_one: merchantCustomerUser} = {}} =
        await upsertMerchantCustomerUser({variables: {
          birthdayDate: birthdayDate || null,
          ...formInputValues,
        }})
      notify('resources.merchant_customer_users.create.success')
      redirect('edit', `/merchant_customer_users`, merchantCustomerUser?.id)
    }
    catch (e) {
      console.error(e)
      notify('resources.merchant_customer_users.create.error', 'error')
    }
  }, [notify, redirect, upsertMerchantCustomerUser])
  const {firstName, whatsappPhoneNumber} = useQueryParameters()
  return (
    <Create {...props} actions={<CreateHeader />}>
      <SimpleForm
        className={styles.form}
        initialValues={{birthdayDate: '', firstName, whatsappPhoneNumber}}
        save={save}
        toolbar={<CreateToolbar />}
      >
        <Typography gutterBottom variant="subtitle2">
          {translate('resources.merchant_customer_users.create.personalData')}
        </Typography>
        <TextInput
          source="firstName"
          validate={required()}
        />
        <TextInput source="lastName" />
        <TextInput
          helperText={translate(
            'resources.merchant_customer_users.create.inputs.whatsappPhoneNumber.' +
            'helperText'
          )}
          source="whatsappPhoneNumber"
          validate={[
            regex(/^[+0-9()\s]{8,}$/, translate('validations.phoneNumber')),
            required(),
          ]}
        />
        <TextInput source="phoneNumber" />
        <TextInput
          parse={v => v?.toLowerCase() ?? ""}
          source="emailAddress"
          type="email"
          validate={[
            regex(EMAIL_ADDRESS_REGEX, translate('validations.emailAddress')),
          ]}
        />
        <DateInput source="birthdayDate" />
        <Typography gutterBottom variant="subtitle2">
          {translate('resources.merchant_customer_users.create.company')}
        </Typography>
        <TextInput source="companyName" />
        <TextInput source="role" />
        <TextInput source="trade" />
        <TextInput source="customerCode" />
        <TextInput source="companyStreet" />
        <TextInput source="companyHouseNumber" />
        <TextInput source="companyZipCode" />
        <TextInput source="companyCity" />
        <Typography gutterBottom variant="subtitle2">
          {translate('resources.merchant_customer_users.create.internal')}
        </Typography>
        <ReferenceInput
          allowEmpty
          perPage={100}
          reference="merchant_users"
          sort={{field: 'firstName', order: 'ASC'}}
          source="primaryResponsibleMerchantUserId"
          {...(
            isVobankOrSchulteMerchantUser && {
              disabled: true, initialValue: merchantUserId,
            })
          }
        >
          <SelectInput
            className={styles.optInNullableInput}
            optionText={({firstName, lastName}) => `${firstName} ${lastName}`}
          />
        </ReferenceInput>
        <BooleanInput
          className={styles.optInInput}
          source="isMarketingConsentGranted"
        />
      </SimpleForm>
    </Create>
  )
}

const CreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      color="primary"
      icon={<SaveIcon />}
      onClick={() => trackEvent('SAVE_CONTACT', EventCategory.CONTACT)}
      variant="contained"
    />
  </Toolbar>
)

const MerchantCustomerUsersEdit = props => {
  const styles = useStyles()
  const isDesktop = useIsDesktop()
  const isVobankOrSchulteMerchantUser = useIsVobankOrSchulteMerchantUser()
  const isWegoMerchantUser = useIsWegoMerchantUser()
  const isOwner = useIsOwner()
  const canEdit = useHasPermission('edit', 'merchant_customer_users')
  return (
    <ExtendedEdit
      actions={
        <ShowHeader
          title={useTranslate()('resources.merchant_customer_users.edit.title')}
        />
      }
      aside={isDesktop && <MerchantCustomerUserTagsList />}
      className={styles.merchantCustomerUserEdit}
      {...props}
    >
      {/* TODO: Extract simple form into component as it is too similar w/ create */}
      <ExtendedSimpleForm
        className={styles.form}
        disabled={!canEdit}
        initialValues={{birthdayDate: ''}}
        redirect={false}
        // @ts-ignore - ignoring missing props which are inferred
        toolbar={<EditToolbar />}
      >
        <EditFormAutoSave />
        <Typography gutterBottom variant="subtitle2">
          {useTranslate()('resources.merchant_customer_users.edit.personalData')}
        </Typography>
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput disabled source="whatsappPhoneNumber" />
        <TextInput source="phoneNumber" />
        <TextInput
          parse={v => v?.toLowerCase() ?? ""}
          source="emailAddress"
          type="email"
          validate={[
            regex(EMAIL_ADDRESS_REGEX, useTranslate()('validations.emailAddress')),
          ]}
        />
        <DateInput source="birthdayDate" />
        <Typography gutterBottom variant="subtitle2">
          {useTranslate()('resources.merchant_customer_users.edit.company')}
        </Typography>
        <TextInput source="companyName" />
        <TextInput source="role" />
        <TextInput source="trade" />
        <TextInput source="customerCode" />
        <TextInput source="companyStreet" />
        <TextInput source="companyHouseNumber" />
        <TextInput source="companyZipCode" />
        <TextInput source="companyCity" />
        <Typography gutterBottom variant="subtitle2">
          {useTranslate()('resources.merchant_customer_users.edit.internal')}
        </Typography>
        <ReferenceInput
          allowEmpty
          disabled={!canEdit || isVobankOrSchulteMerchantUser}
          emptyText="ra.input.select.emptyText"
          emptyValue={null}
          perPage={100}
          reference="merchant_users"
          sort={{field: 'firstName', order: 'ASC'}}
          source="primaryResponsibleMerchantUserId"
        >
          <SelectInput optionText={u => `${u.firstName} ${u.lastName}`} />
        </ReferenceInput>
        {((isWegoMerchantUser && isOwner) || !isWegoMerchantUser) && (
          <BooleanInput
            className={styles.optInInput}
            source="isMarketingConsentGranted"
          />
        )}
        {!isDesktop && <MerchantCustomerUserTagsList />}
      </ExtendedSimpleForm>
    </ExtendedEdit>
  )
}

const MerchantCustomerUserTagsList: FC<{record?: MerchantCustomerUsers}> = ({record}) => {
  const canEdit = useHasPermission('edit', 'merchant_customer_users')
  const styles = useStyles()
  const notify = useNotify()
  const translate = useTranslate()
  const {
    data: tags, ids: tagIds, refetch: refetchTags,
  } = useGetList<Tags>(
    'tags',
    {} as PaginationPayload,
    {field: 'name', order: 'ASC'},
    {},
    {enabled: !!record},
  )
  const {
    ids: merchantCustomerUserTagIds,
    refetch: refetchMerchantCustomerUserTags,
  } = useGetList<MerchantCustomerUserTags & {id: string}>(
    'merchant_customer_user_tags',
    {} as PaginationPayload,
    {field: 'tagId', order: 'ASC'},
    {merchantCustomerUserId: record?.id},
    {enabled: !!record},
  )
  const [filter, setFilter] = useState("")
  const filteredTags = useMemo(
    () => tagIds.filter(
      id => (
        tags[id].name.toLowerCase().includes(filter.toLowerCase()) &&
        !merchantCustomerUserTagIds.includes(id)
      )
    ).map(tagId => tags[tagId]),
    [filter, merchantCustomerUserTagIds, tagIds, tags],
  )
  const [
    createMerchantCustomerUserTag,
    {loading: isCreatingCustomerUserTag},
  ] = useCreate<MerchantCustomerUserTags & {id: string}>()
  const onCreateMerchantCustomerUserTag = async tagId => {
    await createMerchantCustomerUserTag(
      'merchant_customer_user_tags',
      {merchantCustomerUserId: record?.id, tagId},
      {returnPromise: true}
    )
    notify('resources.merchant_customer_users.successMessages.tagAdded')
    refetchMerchantCustomerUserTags()
  }
  const [createTag, {loading: isCreatingTag}] = useCreate<Tags>()
  const onCreateTag = async () => {
    const {data: {id: tagId}} = await createTag(
      'tags', {name: filter}, {returnPromise: true},
    )
    refetchTags()
    setFilter('')
    await onCreateMerchantCustomerUserTag(tagId)
  }
  const [deleteMerchantCustomerUserTag] = useMutation(
    MERCHANT_CUSTOMER_USER_TAGS_DELETE_MUTATION,
    {
      onCompleted: () => {
        refetchMerchantCustomerUserTags()
        notify('resources.merchant_customer_users.successMessages.tagRemoved')
      },
    },
  )
  const [isPopperOpen, setIsPopperOpen] = useState(false)
  const anchorElementRef = useRef<HTMLButtonElement>(null)
  return (
    <div className={styles.merchantCustomerUserTagsRoot}>
      <Typography variant="subtitle2">Tags</Typography>
      <Box className={styles.merchantCustomerUserTagsList}>
        {merchantCustomerUserTagIds.map(id => (
          tags[id] && <Chip
            className={styles.merchantCustomerUserCustomTag}
            deleteIcon={<CloseFilledIcon color="info"/>}
            key={id}
            label={tags[id].name}
            onDelete={canEdit ?
              () => deleteMerchantCustomerUserTag({variables: {
                merchantCustomerUserId: record?.id,
                tagId: id,
              }}) :
              undefined
            }
            variant="outlined"
          />
        ))}
        {canEdit && (
          <Button
            className={styles.merchantCustomerUserTagsDropdownButton}
            endIcon={<ChevronDownIcon />}
            onClick={() => setIsPopperOpen(true)}
            ref={anchorElementRef}
            size="small"
            variant="contained"
          >
            {translate('resources.merchant_customer_users.edit.addTag')}
          </Button>
        )}
        <Popper
          anchorEl={anchorElementRef.current}
          open={isPopperOpen}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={() => setIsPopperOpen(false)}>
            <Paper
              className={styles.merchantCustomerUserTagsPopperPapper}
              variant="outlined"
            >
              <MuiTextField
                disabled={isCreatingCustomerUserTag || isCreatingTag}
                onChange={e => setFilter(e.target.value)}
                onKeyPress={e => {
                  if (
                    (e.key === 'Enter') &&
                    !!filter &&
                    !tagIds.map(id => tags[id].name).includes(filter)
                  ) {
                    e.preventDefault()
                    onCreateTag()
                  }
                }}
                value={filter}
                variant="outlined"
              />
              <Box className={styles.merchantCustomerUserTagsSuggestions}>
                <Chip
                  className={styles.merchantCustomerUserCreateCustomTag}
                  disabled={(
                    !filter ||
                    isCreatingTag ||
                    isCreatingCustomerUserTag ||
                    tagIds.map(id => tags[id].name).includes(filter)
                  )}
                  label={translate(
                    'resources.merchant_customer_users.edit.createTagPopupButton'
                  )}
                  onClick={onCreateTag}
                  variant="outlined"
                />
                {filteredTags.map(tag => (
                  <Chip
                    className={styles.merchantCustomerUserCustomTag}
                    deleteIcon={<AddFilledIcon color="info"/>}
                    key={tag.id}
                    label={tag.name}
                    onDelete={() => onCreateMerchantCustomerUserTag(tag.id)}
                    variant="outlined"
                  />
                ))}
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>
    </div>
  )
}

const EditToolbar: FC<EditToolbarProps> = ({
  basePath, mutationMode, record, resource, undoable,
}) => {
  const translate = useTranslate()
  return (
    useHasPermission('delete', 'merchant_customer_users') ? (
      <Toolbar>
        <DeleteWithConfirmButton
          confirmContent={
            translate('resources.merchant_customer_users.edit.confirmDeletion')
          }
          confirmTitle={record => [record.firstName, record.lastName].join(" ")}
          {...{basePath, mutationMode, record, resource, undoable}}
        />
      </Toolbar>
    ) : null
  )
}

const MerchantCustomerUsersList = props => {
  const canCreateMerchantCustomerUsers = useHasPermission(
    'create', 'merchant_customer_users'
  )
  const canDelete = useHasPermission('delete', 'merchant_customer_users')
  const isDesktop = useIsDesktop()
  const styles = useStyles()
  const translate = useTranslate()
  const {
    identity: {merchantUserId} = {},
    loading: isLoadingSessionMerchantUser,
  } = useGetIdentity()
  const isVobankOrSchulteMerchantUser = useIsVobankOrSchulteMerchantUser()
  const {
    data: {channels: inviteChannels} = {},
    loading: isLoadingChannels,
  } = useQuery<QueryRoot['channels']>(
    CHANNELS_QUERY, {skip: !merchantUserId, variables: {merchantUserId}}
  )
  const {
    data: {aggregated_merchant_customer_user_tags: tags = []} = {},
  } = useQuery<QueryRoot['aggregated_merchant_customer_user_tags']>(
    AGGREGATED_MERCHANT_CUSTOMER_USERS_TAGS_QUERY
  )
  if (isLoadingSessionMerchantUser || isLoadingChannels) {
    return <Loading />
  }
  return (
    <ExtendedList
      {...props}
      actionButtons={<ListActionButtons />}
      bulkActionButtons={canDelete && <BulkDeleteButton />}
      filter={{
        ...(isVobankOrSchulteMerchantUser && {
          primaryResponsibleMerchantUserId: merchantUserId,
        }),
      }}
      filters={[
        <FilterLiveSearch
          // @ts-ignore: 'InputProps' does not exist
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="secondary" />
              </InputAdornment>
            ),
            onKeyDown: e => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
              }
            },
          }}
          alwaysOn
          key="fullText"
          source={[
            "fullText",
            "role@_ilike",
            "whatsappPhoneNumber@_ilike",
          ].join(',')}
        />,
        <AutocompleteInput
          choices={tags}
          className={styles.optInNullableInput}
          key="aggregatedMerchantCustomerUserTags#tagName"
          label={translate(
            'resources.aggregated_merchant_customer_user_tags.fields.tagName'
          )}
          optionText="tagName"
          optionValue = "tagName"
          source="aggregatedMerchantCustomerUserTags#tagName"
        />,
        <SelectInput
          choices={[
            {name: translate('ra.boolean.true'), value: 'true'},
            {name: translate('ra.boolean.false'), value: 'false'},
          ]}
          key="isMarketingConsentGranted"
          label={translate(
            'resources.merchant_customer_users.fields.isMarketingConsentGranted'
          )}
          optionValue="value"
          source="isMarketingConsentGranted"
        />,
        ...(!isVobankOrSchulteMerchantUser ? [
          <ReferenceInput
            filterToQuery={q => ({'firstName@_ilike,lastName@_ilike': q})}
            key="primaryResponsibleMerchantUserId"
            label={translate(
              'resources.merchant_customer_users.fields.primaryResponsibleMerchantUserId'
            )}
            perPage={100}
            reference="merchant_users"
            sort={{field: 'firstName', order: 'ASC'}}
            source="primaryResponsibleMerchantUserId"
          >
            <AutocompleteInput
              className={styles.optInNullableInput}
              optionText={({firstName, lastName}) => `${firstName} ${lastName}`}
            />
          </ReferenceInput>,
        ] : []),
      ]}
      hasCreate={canCreateMerchantCustomerUsers}
      pagination={<Pagination rowsPerPageOptions={[]} />}
      perPage={50}
    >
      {isDesktop ?
        <DesktopDatagrid inviteChannels={inviteChannels} /> :
        <MobileDatagrid inviteChannels={inviteChannels} />
      }
    </ExtendedList>
  )
}

const MobileDatagrid: FC<MobileDatagridProps> = ({inviteChannels}) => {
  const styles = useStyles()
  return (
    <Datagrid classes={{table: styles.table}}>
      <FunctionField<MerchantCustomerUsers>
        label="Name" render={u => [u?.firstName, u?.lastName].join(' ')}
      />
      <FunctionField<MerchantCustomerUsers>
        render={
          ({whatsappPhoneNumber} = {} as MerchantCustomerUsers) => (
            <ChatLinkButton
              customerUserWhatsappPhoneNumber={whatsappPhoneNumber}
              inviteChannels={inviteChannels}
            />
          )
        }
      />
      <EditButton icon={<ChevronRightIcon color="info" />} label="" />
    </Datagrid>
  )
}

const DesktopDatagrid: FC<DesktopDatagridProps> = ({inviteChannels}) => {
  const styles = useStyles()
  const canDelete = useHasPermission('delete', 'merchant_customer_users')
  return (
    <Datagrid classes={{table: styles.table}} hasBulkActions={canDelete} optimized>
      <FunctionField<MerchantCustomerUsers>
        label={useTranslate()('resources.merchant_customer_users.fields.name')}
        render={u => [u?.firstName, u?.lastName].join(' ')}
        source="firstName"
      />
      <TextField source="whatsappPhoneNumber" />
      <TextField source="companyName" />
      <ReferenceField
        // @ts-ignore
        alwaysOn
        link={false}
        reference="merchant_users"
        sortBy="primaryResponsibleMerchantUser.lastName"
        source="primaryResponsibleMerchantUserId"
      >
        <FunctionField<MerchantUsers> render={u => `${u?.lastName}, ${u?.firstName}`} />
      </ReferenceField>
      <FunctionField<MerchantCustomerUsers>
        cellClassName={styles.columnToChatCell}
        render={
          u => (
            <ChatLinkButton
              customerUserWhatsappPhoneNumber={u?.whatsappPhoneNumber}
              inviteChannels={inviteChannels}
            />
          )
        }
      />
      <EditButton
        icon={<ChevronRightIcon color="info" />}
        label=""
        // @ts-ignore
        source="editIconButton"
      />
    </Datagrid>
  )
}

const ChatLinkButton: FC<ChatLinkButtonProps> = ({
  customerUserWhatsappPhoneNumber,
  inviteChannels = [],
}) => {
  const notify = useNotify()
  const translate = useTranslate()
  const redirect = useRedirect()
  const [startChatAndRedirect] = useMutation(
    START_CHAT_MUTATION,
    {
      onCompleted: ({startChat: {chat} = {}}) => {
        redirect(`/inbox/${chat?.channelId}/${chat?.id}`)
      },
      onError: e => {
        console.error(e)
        notify(
          'resources.merchant_customer_users.errorMessages.chatLink',
          'error'
        )
      },
    }
  )
  return (
    <PopupState variant="popper">
      {popupState => (
        <>
          {inviteChannels.length > 1 && popupState.isOpen &&
            <ChatStartTracker />
          }
          <Link
            {...(
              inviteChannels.length > 1 ?
                bindTrigger(popupState) :
                {onClick: () => {
                  trackEvent('START_CHAT', EventCategory.CONTACT, 'CONTACTS_PAGE')
                  inviteChannels.length && startChatAndRedirect({variables: {
                    chat: {
                      channelId: inviteChannels[0].id,
                      customerUserWhatsappPhoneNumber,
                    },
                  }})
                }}
            )}
          >
            <Typography variant="body2">{
              translate('resources.merchant_customer_users.action.seeChat')
            }</Typography>
          </Link>
          {(inviteChannels.length > 1) &&
            <Menu {...bindMenu(popupState)}>
              <MenuItem disabled>
                {translate('resources.merchant_customer_users.list.chooseChannel')}
              </MenuItem>
              <Divider />
              {inviteChannels.map(c =>
                <MenuItem
                  key={c.id}
                  onClick={() => {
                    popupState.close()
                    startChatAndRedirect({variables: {
                      chat: {channelId: c.id, customerUserWhatsappPhoneNumber},
                    }})
                  }}
                >
                  {c.name}
                </MenuItem>
              )}
            </Menu>
          }
        </>
      )}
    </PopupState>
  )
}

const ChatStartTracker = () => {
  // We need to track the start chat event only ONCE when the
  // component is rendered. For that we need to register the following effect.
  useEffect(() => {
    trackEvent('START_CHAT', EventCategory.CONTACT, 'CONTACTS_PAGE')
  }, [])
  return null
}

const ListActionButtons = () => {
  const translate = useTranslate()
  const isDesktop = useIsDesktop()
  const simplifyAge = useSimplifyAge()
  const isListEmpty = !useListContext().total
  const notify = useNotify()
  const {data: {merchants: [{
    lastSalesforceSyncTimestamp, salesforceConnectionStatus,
  } = {} as Merchants] = []} = {}} = useQuery<QueryRoot['merchants']>(MERCHANT_QUERY)
  const hasSyncLabel = (salesforceConnectionStatus === 'CONNECTED') && isDesktop
  const canImportMerchantCustomerUsers = useHasPermission(
    'import', 'merchant_customer_users'
  )
  const canExportMerchantCustomerUsers = useHasPermission(
    'export', 'merchant_customer_users'
  )
  const exporter = useCallback(
    async (data, fetchRelatedRecords, dataProvider, resource) => {
      try {
        const [merchantUsers, merchantCustomerUserTags] = await Promise.all([
          fetchRelatedRecords(
            data, 'primaryResponsibleMerchantUserId', 'merchant_users'
          ),
          fetchRelatedRecords(
            data, 'id', 'merchant_customer_user_tags'
          ),
        ])
        const customerUserIdToTags = Object
          .values<Record>(merchantCustomerUserTags)
          .reduce(
            (result, {merchantCustomerUserId, tag}) => {
              result[merchantCustomerUserId] = [
                ...(result[merchantCustomerUserId] ?? []),
                tag.name,
              ]
              return result
            },
            {}
          )
        const merchantCustomerUsers = data.map(m => ({
          ..._.pick(m, [
            'whatsappPhoneNumber',
            'firstName',
            'lastName',
            'emailAddress',
            'responsibleMerchantUserEmailAddress',
            'isMarketingConsentGranted',
            'customerCode',
            'tags',
            'role',
            'trade',
            'birthdayDate',
            'companyCity',
            'companyName',
            'companyStreet',
            'companyZipCode',
            'companyHouseNumber',
          ]),
          responsibleMerchantUserEmailAddress:
            merchantUsers[m.primaryResponsibleMerchantUserId]?.emailAddress,
          tags: customerUserIdToTags[m.id]?.join(', '),
        }))
        defaultExporter(
          merchantCustomerUsers,
          fetchRelatedRecords,
          dataProvider,
          resource
        )
      }
      catch (e) {
        notify('ra.message.error', 'error')
        console.error(e)
      }
    },
    [notify],
  )
  return (
    <Box display="flex">
      {(canExportMerchantCustomerUsers && isDesktop) && (
        <ExportButton
          color="primary"
          disabled={isListEmpty}
          exporter={exporter}
          icon={<DownloadIcon/>}
          maxResults={false as any}
          size="medium"
          variant="outlined"
        />
      )}
      {(canImportMerchantCustomerUsers && isDesktop) && (
        <MerchantCustomerUserImportButton />
      )}
      {hasSyncLabel && (
        <Typography color="textSecondary" variant="body2">
          <SyncIcon color="textSecondary"/>
          {translate(
            'resources.merchant_customer_users.lastSalesforceSync',
            {
              age: lastSalesforceSyncTimestamp ?
                simplifyAge(lastSalesforceSyncTimestamp, {canShowToday: true}) :
                translate(
                  'resources.merchant_customer_users.lastSalesforceSyncPreparing'
                ),
            }
          )}
        </Typography>
      )}
    </Box>
  )
}

const MerchantCustomerUserImportButton = () => {
  const refresh = useRefresh()
  const isDesktop = useIsDesktop()
  const styles = useStyles()
  const translate = useTranslate()
  const {merchantUser = {} as MerchantUsers} = useSessionMerchantUser()
  const [isProgressDialogOpen, setIsProgressDialogOpen] = useState(false)
  const [
    importStatusMessage, setImportStatusMessage,
  ] = useState<ReactNode | undefined>(undefined)
  const closeProgressDialog = useCallback(() => {
    setImportStatusMessage(undefined)
    setIsProgressDialogOpen(false)
    refresh()
  }, [refresh])
  const [
    importMerchantCustomerUsers,
    {loading: isImportingMerchantCustomerUsers},
  ] = useMutation<MutationRoot['importMerchantCustomerUsers']>(
    MERCHANT_CUSTOMER_USERS_IMPORT_MUTATION,
    {
      onCompleted: ({
        importMerchantCustomerUsers: {
          importedMerchantCustomerUsersCount,
          invalidRowsCount,
          rowFailureReasons,
        } = {},
      }) => {
        setImportStatusMessage(!!invalidRowsCount ? (
          <div className={styles.merchantCustomerUsersImportStatusMessage}>
            <Typography>
              {translate(
                'resources.merchant_customer_users.import.status.ignored',
                {invalidRowsCount}
              )}
            </Typography>
            {rowFailureReasons?.map(r => (
              <Typography key={r} variant="body2">
                <WarningIcon color="warning" size="inherit"/>
                {translate(
                  `resources.merchant_customer_users.importRowFailureReasons.${r}`
                )}
              </Typography>
            ))}
          </div>
        ) : (
          <Typography>
            {translate(
              'resources.merchant_customer_users.import.success',
              {importedMerchantCustomerUsersCount}
            )}
          </Typography>
        ))
      },
      onError: e => {
        setImportStatusMessage(
          <Typography>
            <WarningIcon color="error" size="inherit"/>
            {translate(
              'resources.merchant_customer_users.import.error',
              {message: e.message}
            )}
          </Typography>
        )
      },
    }
  )
  const uploadMerchantCustomerUsersCsv = useCallback(([file]) => {
    if (window.confirm(
      translate(
        'resources.merchant_customer_users.import.confirm',
        {filename: file.filename},
      )
    )) {
      setIsProgressDialogOpen(true)
      importMerchantCustomerUsers({
        variables: {
          csvBase64Content: file.encodedContent,
          merchantId: merchantUser?.merchant?.id,
          mimeType: file.mimeType,
        },
      })
    }
  }, [merchantUser, importMerchantCustomerUsers, translate])
  return (
    <>
      <FileUploadButton
        className={styles.merchantCustomerUserImportButton}
        loading={isImportingMerchantCustomerUsers}
        mimeTypes={[
          'application/vnd.oasis.opendocument.spreadsheet',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/csv',
        ]}
        onSelectFiles={uploadMerchantCustomerUsersCsv}
      >
        {isDesktop && translate('resources.merchant_customer_users.import.button')}
      </FileUploadButton>
      <ProgressDialog
        isProgressing={isImportingMerchantCustomerUsers}
        onClose={closeProgressDialog}
        open={isProgressDialogOpen}
        title={translate('resources.merchant_customer_users.import.progressDialog.title')}
      >
        {isImportingMerchantCustomerUsers ? (
          <Typography>
            {translate(
              'resources.merchant_customer_users.import.progressDialog.progressMessage'
            )}
          </Typography>
        ) : importStatusMessage}
      </ProgressDialog>
    </>
  )
}

const AGGREGATED_MERCHANT_CUSTOMER_USERS_TAGS_QUERY = gql`query{
  aggregated_merchant_customer_user_tags(distinct_on: tagName){tagName}
}`

const MERCHANT_QUERY = gql`query{
  merchants{id lastSalesforceSyncTimestamp salesforceConnectionStatus}
}`

const START_CHAT_MUTATION = gql`
  mutation($chat: Chat!){startChat(chat: $chat){chat{channelId id}}}
`

const CHANNELS_QUERY = gql`
  query($merchantUserId: uuid!){
    channels(where: {
      channelMerchantUsers: {merchantUserId: {_eq: $merchantUserId}}
    }){
      id name
    }
  }
`

const MERCHANT_CUSTOMER_USERS_IMPORT_MUTATION = gql`
  mutation (
    $csvBase64Content: String!
    $merchantId: uuid!
    $mimeType: String!
  ){
    importMerchantCustomerUsers(
      csvBase64Content: $csvBase64Content
      merchantId: $merchantId
      mimeType: $mimeType
    ){
      invalidRowsCount
      rowFailureReasons
      importedMerchantCustomerUsersCount
    }
  }
`

const MERCHANT_CUSTOMER_USER_TAGS_DELETE_MUTATION = gql`
  mutation($merchantCustomerUserId: uuid!, $tagId: uuid!){
    delete_merchant_customer_user_tags(where: {
      merchantCustomerUserId: {_eq: $merchantCustomerUserId},
      tagId: {_eq: $tagId}
    }){
      returning{tagId merchantCustomerUserId}
    }
  }
`

const MERCHANT_CUSTOMER_USER_UPSERT_MUTATION = gql`
  mutation (
    $birthdayDate: date
    $companyCity: String
    $companyHouseNumber: String
    $companyName: String
    $companyStreet: String
    $companyZipCode: String
    $customerCode: String
    $emailAddress: String
    $firstName: String
    $lastName: String
    $isMarketingConsentGranted: Boolean
    $phoneNumber: String
    $primaryResponsibleMerchantUserId: uuid
    $role: String
    $trade: String
    $whatsappPhoneNumber: String
  ) {
    insert_merchant_customer_users_one(
      object: {
        birthdayDate: $birthdayDate
        companyCity: $companyCity
        companyHouseNumber: $companyHouseNumber
        companyName: $companyName
        companyStreet: $companyStreet
        companyZipCode: $companyZipCode
        customerCode: $customerCode
        emailAddress: $emailAddress
        firstName: $firstName
        isMarketingConsentGranted: $isMarketingConsentGranted
        lastName: $lastName
        phoneNumber: $phoneNumber
        primaryResponsibleMerchantUserId: $primaryResponsibleMerchantUserId
        role: $role
        trade: $trade
        whatsappPhoneNumber: $whatsappPhoneNumber
      },
      on_conflict: {
        constraint: merchant_customer_users_customer_user_id_merchant_id_key
        update_columns: [
          birthdayDate
          companyCity
          companyHouseNumber
          companyName
          companyStreet
          companyZipCode
          customerCode
          emailAddress
          firstName
          lastName
          phoneNumber
          primaryResponsibleMerchantUserId
          role
          trade
        ]
      }
    ) {id}
  }
`

const useStyles = makeStyles(theme => ({
  bulkDeleteButton: {
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.error.main,
    },
    color: theme.palette.error.main,
  },
  columnToChatCell: {
    textAlign: 'center !important' as 'center',
  },
  form: {
    '& .MuiFormHelperText-root': {
      color: theme.palette.text.secondary,
      ...theme.typography.caption,
    },
  },
  inviteExplainText: {marginBottom: '1rem'},
  loadingState: {
    height: '30%',
    marginTop: '15px',
  },
  merchantCustomerUserCreateCustomTag: {
    '&.MuiChip-root': {
      '&.Mui-disabled': {
        borderColor: theme.palette.disabled.main,
        color: theme.palette.primary.main,
      },
      borderColor: theme.palette.info.main,
      color: theme.palette.info.main,
    },
  },
  merchantCustomerUserCustomTag: {
    '& .MuiChip-deleteIcon:hover': {
      color: theme.palette.info.dark,
    },
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
  },
  merchantCustomerUserEdit: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
    },
  },
  merchantCustomerUserImportButton: {
    '& .MuiButton-label > .MuiSvgIcon-root:not(:last-child)': {
      marginRight: 0,
    },
  },
  merchantCustomerUserTagsDropdownButton: {
    borderRadius: '16px',
    color: theme.palette.info.contrastText,
    display: 'flex',
    height: theme.remSpacing(4),
  },
  merchantCustomerUserTagsList: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.remSpacing(1),
    marginBottom: theme.remSpacing(4),
    marginTop: theme.remSpacing(2),
  },
  merchantCustomerUserTagsPopperPapper: {
    '& .MuiOutlinedInput-root, .MuiOutlinedInput-input': {
      borderRadius: theme.remSpacing(1),
      height: theme.remSpacing(4),
      paddingRight: theme.remSpacing(1.5),
      textAlign: 'center',
      ...theme.typography.body2,
    },
    borderColor: theme.palette.background.paper,
    borderRadius: theme.remSpacing(1.5),
    boxShadow: `0px 1px 3px ${theme.palette.disabled.main}`,
    boxSizing: 'border-box',
    marginTop: theme.remSpacing(1),
    padding: theme.remSpacing(2),
  },
  merchantCustomerUserTagsRoot: {
    boxSizing: 'border-box',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.remSpacing(10),
      paddingRight: theme.remSpacing(10),
      width: '50%',
    },
  },
  merchantCustomerUserTagsSuggestions: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.remSpacing(1),
    marginTop: theme.remSpacing(1),
  },
  merchantCustomerUsersImportStatusMessage: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.remSpacing(1),
  },
  optInInput: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.remSpacing(2),
    },
  },
  optInNullableInput: {
    width: '100% !important',
  },
  table: {
    [theme.breakpoints.up('md')]: {
      '& .MuiTableCell-head': {
        paddingRight: 0,
      },
      '& .column-editIconButton': {
        paddingLeft: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      '& td:nth-of-type(2)': {
        textAlign: 'end',
      },
      '& th:nth-of-type(1)': {
        width: '50%',
      },
      '& th:nth-of-type(2)': {
        width: theme.remSpacing(5),
      },
      '& th:nth-of-type(3)': {
        width: theme.remSpacing(5),
      },
    },
  },
  templateMessageIcon: {
    backgroundColor: '#ffffff !important',
    margin: '0 0 0 10px !important',
  },
}))

interface EditToolbarProps {
  basePath: string
  mutationMode: MutationMode
  record: Record
  resource: string
  undoable: boolean
}

interface MobileDatagridProps {
  inviteChannels: Channels[] | undefined
}

type DesktopDatagridProps = MobileDatagridProps

interface ChatLinkButtonProps {
  customerUserWhatsappPhoneNumber: string | undefined,
  inviteChannels?: Channels[]
}

export {
  MerchantCustomerUserCreate,
  MerchantCustomerUsersEdit,
  MerchantCustomerUsersList,
}
