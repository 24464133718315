import {useSubscription} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {MouseEvent, useEffect, useRef} from 'react'
import {useGetIdentity, useTranslate} from 'react-admin'
import {useHistory} from 'react-router-dom'

import {commonUnreadChatMessageFilters} from '../../hooks/chat/useHasUnreadChatMessages'
import useBrowserNotifications from '../../hooks/useBrowserNotifications'
import {useCompileChatMessageText} from '../../hooks/useCompileChatMessageText'
import useSessionMerchantUser from '../../hooks/useSessionMerchantUser'
import {ChatTypesEnum, SubscriptionRoot} from '../../types/graphqlSchema'

const UnreadChatMessagesBrowserNotificationPresenter = () => {
  const firstSubscriptionEventRef = useRef(true)
  const {showNotification} = useBrowserNotifications()
  const {identity: {id: userId} = {}} = useGetIdentity()
  const history = useHistory()
  const {compileChatMessageText} = useCompileChatMessageText()
  const {merchantUser: {id: sessionMerchantUserId} = {}} = useSessionMerchantUser()
  const {data: {chats} = {}} = useSubscription<SubscriptionRoot['chats']>(
    CHATS_SUBSCRIPTION,
    {skip: !sessionMerchantUserId, variables: {sessionMerchantUserId}}
  )
  const translate = useTranslate()
  useEffect(() => {
    if (!chats) return // Ignore the first event before we actually get a query result
    if (firstSubscriptionEventRef.current) {
      // In order to not show browser notifications once the app has just loaded we simply
      // ignore the first query result and only show notifications on subsequent events
      firstSubscriptionEventRef.current = false
      return
    }
    const [mostRecentlyActiveChat] = chats
    const [chatMessage] = mostRecentlyActiveChat.chatMessages
    if (!chatMessage || (chatMessage.authorUser.id === userId)) return
    // Don't show notification when the customer user removes its reaction
    if (chatMessage.type === 'REACTION' && !chatMessage.reactionEmoji) return
    if (
      chatMessage.authorUser.channelChatBotUserId ||
      chatMessage.authorUser.merchantApiUserId
    ) return
    const pathname = mostRecentlyActiveChat.type === ChatTypesEnum.External ?
      'inbox' :
      'team-chat'
    showNotification({
      body: compileChatMessageText(chatMessage),
      onclick: (e: NotificationEvent) => {
        e.target.close()
        window.focus()
        history.push(
          `/${pathname}/${[
            mostRecentlyActiveChat?.channelId,
            mostRecentlyActiveChat?.id,
          ].filter(Boolean).join('/')}`
        )
      },
      title: chatMessage?.type === 'REACTION' ?
        translate(
          'unreadChatMessagesBrowserNotificationPresenter.newReactionMessageNotification',
          {authorUserDisplayName: chatMessage?.authorUserDisplayName}
        ) :
        translate(
          'unreadChatMessagesBrowserNotificationPresenter.newMessageNotification',
          {authorUserDisplayName: chatMessage?.authorUserDisplayName}
        )
      ,
    })
  }, [
    chats,
    compileChatMessageText,
    history,
    showNotification,
    translate,
    userId,
  ])
  return null
}

const CHATS_SUBSCRIPTION = gql`subscription($sessionMerchantUserId: uuid!){
  chats(
    limit: 1
    order_by: {lastActivityTimestamp: desc}
    where: {${commonUnreadChatMessageFilters()}}
  ){
    channelId
    chatMessages(limit: 1, order_by: {insertionTimestamp: desc}){
      authorUser{id channelChatBotUserId merchantApiUserId}
      authorUserDisplayName
      id
      reactionEmoji
      text
      type
    }
    id
    type
  }
}`

interface NotificationEvent extends MouseEvent {
  target: Notification
}

export default UnreadChatMessagesBrowserNotificationPresenter
