// Fastest implementation of converting an ArrayBuffer to its hex represention, from:
// https://blog.xaymar.com/2020/12/08/fastest-uint8array-to-hex-string-conversion-in-javascript/
/* eslint-disable */
const LUT_HEX_4b = [
  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'
]
const LUT_HEX_8b = new Array(0x100)
for (let n = 0; n < 0x100; n++) {
  LUT_HEX_8b[n] = `${LUT_HEX_4b[(n >>> 4) & 0xF]}${LUT_HEX_4b[n & 0xF]}`
}
function arrayBufferToHex(arrayBuffer) {
  const bytes = new Uint8Array(arrayBuffer)
  let out = ''
  for (let idx = 0, edx = bytes.length; idx < edx; idx++) {
    out += LUT_HEX_8b[bytes[idx]]
  }
  return out
}

// https://stackoverflow.com/a/9458996/543875
function arrayBufferToBase64(arrayBuffer) {
  let binary = ''
  const bytes = new Uint8Array(arrayBuffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}
/* eslint-enable */

export {arrayBufferToBase64, arrayBufferToHex}
