import {
  format,
  isAfter,
  isBefore,
  isFuture,
  isPast,
  parseISO,
} from 'date-fns'

const timeStringFromTimestamp = timestamp => format(parseISO(timestamp), 'H:mm')

const dateFromTimestamp = timestamp =>
  typeof timestamp === 'string' ? parseISO(timestamp) : timestamp

const isTimestampAfter = (timestamp, relativeTimestamp) => isAfter(
  dateFromTimestamp(timestamp),
  dateFromTimestamp(relativeTimestamp)
)

const isTimestampBefore = (timestamp, relativeTimestamp) => isBefore(
  dateFromTimestamp(timestamp),
  dateFromTimestamp(relativeTimestamp)
)

const isTimestampInFuture = timestamp => isFuture(dateFromTimestamp(timestamp))

const isTimestampInPast = timestamp => isPast(dateFromTimestamp(timestamp))

const isValidDateTimeString = value => !isNaN(Date.parse(value))

export {
  isTimestampAfter,
  isTimestampBefore,
  isTimestampInFuture,
  isTimestampInPast,
  isValidDateTimeString,
  timeStringFromTimestamp,
}
